import client from "../ApiService";
import HttpClient from "../http-client";
import { QaQcInfo } from "../types/Customer";

const qaqcUri: string = "/qa-qc";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _QaQcApi extends _BasicApi {
  getQaQcList(
    placeName: string | null,
    documentIds: string | null,
    startDate: string,
    endDate: string | undefined,
    page: number,
    size: number
  ) {
    return this.client.get(
      `${qaqcUri}?placeName=${placeName}&documentIds=${documentIds}&startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}&sort=isChecked,asc&sort=createdAt,desc`
    );
  }
  updateQaQc(qaqcId: number, data: QaQcInfo) {
    return this.client.put(`${qaqcUri}/${qaqcId}`, data);
  }
  createQaQc(data: QaQcInfo) {
    return this.client.post(`${qaqcUri}`, data);
  }
  deleteQaQc(id: number) {
    return this.client.delete(`${qaqcUri}/${id}`);
  }
  checkQaQc(qaqcId: number) {
    return this.client.post(`${qaqcUri}/${qaqcId}/check`);
  }
}

export const QaQcApi = new _QaQcApi(client);
