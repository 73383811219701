import { Box, Grid, Modal } from "@mui/material";
import {
  DropDownListComponentBox,
  DropDownListComponentGridBox,
  InputBox,
  InputDisabledBox,
  ModalStyle,
  PointerItem,
  SmallBoxStyle,
} from "../../../styles/theme";
import { useRecoilState } from "recoil";
import { MyInfoState } from "../../../system/atoms";
import { CustomText } from "../../../styles/CustomText";
import { MyInfo, PasswordInfo } from "../../../system/types/Employee";
import AlertModal from "../../../components/Common/AlertModal";
import { ErrorHandler } from "../../../system/ApiService";
import { EmployeeApi } from "../../../system/api/EmployeeApi";
import { GroupInfo, JobInfo } from "../../../system/types/Group";
import uuid from "react-uuid";
import { useEffect, useState } from "react";
import { GRAYPALETITLE, GREENTITLE, REDTITLE } from "../../../styles/Color";
import { CheckCircle, X } from "@phosphor-icons/react";
import { JobApi } from "../../../system/api/JobApi";
import {
  CustomButtonDisabled,
  CustomButtonGreen,
  CustomButtonYellow,
} from "../../../styles/ButtonStyle";
import { InitMyInfo, InitPasswordInfo } from "../../../system/types/initObject";
import { MessageComponent } from "@syncfusion/ej2-react-notifications";

function validation(data: MyInfo) {
  if (data.name === "") {
    AlertModal("check", "이름을 작성해 주세요.");
    return false;
  } else if (data.phone === "") {
    AlertModal("check", "연락처를 작성해 주세요.");
    return false;
  }
  return true;
}

function validationWrite(data: MyInfo) {
  if (data.phone === "") {
    return false;
  } else if (!data.jobPosition?.id) {
    return false;
  } else if (!data.jobTitle?.id) {
    return false;
  }
  return true;
}

function phoneValiation(data: string) {
  // eslint-disable-next-line
  const exptext = /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}$/;
  if (exptext.test(data) === false) {
    return false;
  }
  return true;
}

function passwordValidation(data: PasswordInfo, checkPassword: string) {
  if (
    checkPassword.length < 8 ||
    data.newPassword.length < 8 ||
    checkPassword.length > 30 ||
    data.newPassword.length > 30
  ) {
    return false;
  } else if (checkPassword !== data.newPassword) {
    return false;
  }
  return true;
}

function MyInfoPageBox() {
  const [myData, setMyData] = useRecoilState(MyInfoState);
  const [data, setData] = useState<MyInfo>(InitMyInfo);
  const [jobTitleData, setJobTitleData] = useState<JobInfo[]>([]);
  const [jobPositionData, setJobPositionData] = useState<JobInfo[]>([]);

  useEffect(() => {
    setData(myData);
    JobApi.searchJobPosition()
      .then((res) => {
        setJobPositionData(res.data.filter((item: JobInfo) => item.enabled));
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
    JobApi.searchJobTitle()
      .then((res) => {
        setJobTitleData(res.data.filter((item: JobInfo) => item.enabled));
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [myData]);

  const onChange = (args: any, type: string) => {
    if (type === "input") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else if (type === "dropdown") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.itemData,
      });
    }
  };

  const onChangePhone = (args: any) => {
    const targetValue = args.target.value
      // eslint-disable-next-line
      .replace(/[^0-9]/g, "")
      // eslint-disable-next-line
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      // eslint-disable-next-line
      .replace(/(\-{1,2})$/g, "");
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: targetValue,
    });
  };

  var submitcheck = false;
  function onSave() {
    if (validation(data)) {
      submitcheck = true;
      if (submitcheck === true) {
        EmployeeApi.updateMyInfo(data)
          .then(() => {
            AlertModal("success", "내 정보가 저장되었습니다.");
            setMyData(data);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      }
    }
  }
  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onSave();
    }
  };

  const titleFields = { text: "name", value: "id" };
  const positionFields = { text: "name", value: "id" };

  // 비밀번호 변경 모달
  const [passwordData, setPasswordData] =
    useState<PasswordInfo>(InitPasswordInfo);
  const [passwordCheck, setPasswordCheck] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setPasswordData(InitPasswordInfo);
  };
  const openModal = () => {
    setOpen(true);
  };

  const onChangePassword = (args: any) => {
    setPasswordData({
      ...passwordData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChangePasswordCheck = (args: any) => {
    setPasswordCheck(args.target.value);
  };

  const onChangePasswordState = () => {
    submitcheck = true;
    if (submitcheck) {
      EmployeeApi.updatePassword({ ...passwordData })
        .then(() => {
          AlertModal("successMessage", "비밀번호가 변경되었습니다.");
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
          submitcheck = false;
        });
    }
  };
  const handleOnKeyDownPassword = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onChangePasswordState();
    }
  };
  return (
    <SmallBoxStyle>
      {!validationWrite(myData) && (
        <MessageComponent
          severity="Warning"
          variant="Outlined"
          content="내 정보를 모두 작성 후 저장해주세요."
          style={{ marginBottom: "5px" }}
        />
      )}

      {data ? (
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Grid container item xs={12} alignItems="center">
              <Grid mr="5px">이름</Grid>
              {data.name === "" ? (
                <CustomText type="small" color={REDTITLE}>
                  * 이름을 입력해 주세요.
                </CustomText>
              ) : (
                <CheckCircle size={20} color={GREENTITLE} weight="bold" />
              )}
            </Grid>
            <InputBox
              type="text"
              name="name"
              onChange={(e: any) => {
                onChange(e, "input");
              }}
              value={data.name || ""}
              placeholder="이름"
              onKeyDown={handleOnKeyDown}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container item xs={12} alignItems="center">
              <Grid mr="5px">연락처</Grid>
              {data.phone === "" ? (
                <CustomText type="small" color={REDTITLE}>
                  * 연락처를 입력해 주세요.
                </CustomText>
              ) : (
                <>
                  {phoneValiation(data.phone) ? (
                    <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                  ) : (
                    <CustomText type="small" color={REDTITLE}>
                      * 올바른 연락처 형식이 아닙니다. ex. 010-0000-0000
                    </CustomText>
                  )}
                </>
              )}
            </Grid>
            <InputBox
              type="text"
              name="phone"
              value={data.phone || ""}
              onChange={(e: any) => {
                onChangePhone(e);
              }}
              placeholder="ex) 010-0000-0000"
              onKeyDown={handleOnKeyDown}
              autoComplete="new-password"
            />
          </Grid>
          <Grid container item xs={12} alignItems="center">
            <Grid mr="5px">이메일</Grid>
          </Grid>
          <InputDisabledBox container alignItems="center">
            {data.email}
          </InputDisabledBox>
          <Grid container item xs={12} alignItems="center">
            <Grid mr="5px">소속 그룹</Grid>
          </Grid>
          <InputDisabledBox container alignItems="center">
            {data.groups.map((item: GroupInfo, idx: number) => (
              <Grid key={uuid()}>
                {idx !== 0 && ", "}
                {item.name}
              </Grid>
            ))}
            {data.groups.length === 0 && <Grid>-</Grid>}
          </InputDisabledBox>

          <Grid item xs={12}>
            <Grid container item xs={12} alignItems="center">
              <Grid mr="5px">직책</Grid>
              {data.jobTitle?.id ? (
                <CheckCircle size={20} color={GREENTITLE} weight="bold" />
              ) : (
                <CustomText type="small" color={REDTITLE} ml="5px">
                  * 직책을 선택해주세요.
                </CustomText>
              )}
            </Grid>
            <DropDownListComponentGridBox>
              <DropDownListComponentBox
                colorvalue={data.jobTitle?.id ? "#000000" : "#B2B4B8"}
                dataSource={jobTitleData}
                fields={titleFields}
                name="jobTitle"
                value={data.jobTitle?.id || ""}
                onChange={(e: any) => {
                  onChange(e, "dropdown");
                }}
                placeholder={data.jobTitle?.id ? data.jobTitle.name : "직책"}
                onKeyDown={handleOnKeyDown}
                autoComplete="new-password"
              />
            </DropDownListComponentGridBox>
          </Grid>
          <Grid item xs={12}>
            <Grid container item xs={12} alignItems="center">
              <Grid mr="5px">직위</Grid>
              {data.jobPosition?.id ? (
                <CheckCircle size={20} color={GREENTITLE} weight="bold" />
              ) : (
                <CustomText type="small" color={REDTITLE}>
                  * 직위를 선택해주세요.
                </CustomText>
              )}
            </Grid>

            <DropDownListComponentGridBox>
              <DropDownListComponentBox
                colorvalue={data.jobPosition?.id ? "#000000" : "#B2B4B8"}
                dataSource={jobPositionData}
                fields={positionFields}
                name="jobPosition"
                value={data.jobPosition?.id || ""}
                onChange={(e: any) => {
                  onChange(e, "dropdown");
                }}
                placeholder={
                  data.jobPosition?.id ? data.jobPosition.name : "직위"
                }
                onKeyDown={handleOnKeyDown}
                autoComplete="new-password"
              />
            </DropDownListComponentGridBox>
          </Grid>
          <Grid container item xs={12} justifyContent="right" mt="20px">
            <CustomButtonYellow
              onClick={openModal}
              style={{ marginRight: "5PX" }}
            >
              비밀번호 변경
            </CustomButtonYellow>
            <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
          </Grid>
        </Grid>
      ) : (
        <Grid justifyContent="center" mt="30px">
          <CustomText type="subtitle" color="#3D4861">
            해당 정보가 없습니다.
          </CustomText>
        </Grid>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle("27.5rem")}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              <CustomText type="title"> 비밀번호 변경</CustomText>

              <PointerItem onClick={handleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
          </Grid>
          <Grid container mt="20px" mb="20px">
            <Grid container>
              <Grid item xs={12}>
                <Grid container item xs={12} alignItems="center">
                  <CustomText type="subtitle2" bold500 color={GRAYPALETITLE}>
                    기존 비밀번호
                  </CustomText>
                  {passwordData.oldPassword === "" ? (
                    <CustomText type="small" color={REDTITLE} ml="5px">
                      * 기존 비밀번호를 입력해 주세요.
                    </CustomText>
                  ) : (
                    <>
                      {passwordData.oldPassword.length > 30 ? (
                        <CustomText type="small" color={REDTITLE} ml="5px">
                          * 비밀번호는 30자 이내로 입력해 주세요.
                        </CustomText>
                      ) : (
                        <CheckCircle
                          size={20}
                          color={GREENTITLE}
                          weight="bold"
                        />
                      )}
                    </>
                  )}
                </Grid>

                <InputBox
                  type="password"
                  onChange={onChangePassword}
                  name="oldPassword"
                  placeholder="기존 비밀번호를 입력하세요."
                  onKeyDown={handleOnKeyDownPassword}
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Grid container item xs={12} alignItems="center">
                  <CustomText type="subtitle2" bold500 color={GRAYPALETITLE}>
                    새 비밀번호
                  </CustomText>
                  {passwordData.newPassword === "" ? (
                    <CustomText type="small" color={REDTITLE} ml="5px">
                      * 새 비밀번호를 입력해 주세요.
                    </CustomText>
                  ) : (
                    <>
                      {passwordData.newPassword.length > 30 ||
                      passwordData.newPassword.length < 8 ? (
                        <CustomText type="small" color={REDTITLE} ml="5px">
                          * 비밀번호는 8자 이상 30자 이내로 입력해 주세요.
                        </CustomText>
                      ) : (
                        <CheckCircle
                          size={20}
                          color={GREENTITLE}
                          weight="bold"
                        />
                      )}
                    </>
                  )}
                </Grid>

                <InputBox
                  type="password"
                  onChange={onChangePassword}
                  name="newPassword"
                  placeholder="새 비밀번호 (8자 이상 30자 이하)"
                  onKeyDown={handleOnKeyDownPassword}
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Grid container item xs={12} alignItems="center">
                  <CustomText type="subtitle2" bold500 color={GRAYPALETITLE}>
                    새 비밀번호 확인
                  </CustomText>
                  {passwordCheck === "" ? (
                    <CustomText type="small" color={REDTITLE} ml="5px">
                      * 새 비밀번호를 한 번 더 입력해 주세요.
                    </CustomText>
                  ) : (
                    <>
                      {passwordCheck.length > 30 || passwordCheck.length < 8 ? (
                        <CustomText type="small" color={REDTITLE} ml="5px">
                          * 비밀번호는 8자 이상 30자 이내로 입력해 주세요.
                        </CustomText>
                      ) : (
                        <>
                          {passwordValidation(passwordData, passwordCheck) ? (
                            <CheckCircle
                              size={20}
                              color={GREENTITLE}
                              weight="bold"
                            />
                          ) : (
                            <CustomText type="small" color={REDTITLE} ml="5px">
                              * 입력해주신 비밀번호가 동일하지 않습니다.
                            </CustomText>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Grid>
                <InputBox
                  type="password"
                  onChange={onChangePasswordCheck}
                  placeholder="새 비밀번호 확인 (8자 이상 30자 이하)"
                  onKeyDown={handleOnKeyDownPassword}
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} justifyContent="right" mt="20px">
            {passwordValidation(passwordData, passwordCheck) &&
            passwordCheck.length > 0 &&
            passwordCheck.length < 31 ? (
              <CustomButtonGreen onClick={onChangePasswordState}>
                저장
              </CustomButtonGreen>
            ) : (
              <CustomButtonDisabled
                container
                justifyContent="center"
                alignItems="center"
              >
                저장
              </CustomButtonDisabled>
            )}
          </Grid>
        </Box>
      </Modal>
    </SmallBoxStyle>
  );
}

export default MyInfoPageBox;
