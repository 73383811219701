import { Grid, IconButton, Tooltip } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import EditIcon from "@mui/icons-material/Edit";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { CommentInfo } from "../../../../system/types/MeetingNote";
import CommentInputBox from "./CommentInputBox";
import { InitCommentInfo } from "../../../../system/types/initObject";
import { isoDateFormatter } from "../../../../components/Common/IsoDateFormatter";

interface CommentListProps {
  commentData: CommentInfo;
  replyData: CommentInfo;
  replyContent: string;
  setReplyContent: (replyContent: string) => void;
  onChangeComment: (dt: CommentInfo, type: string) => void;
  openReply: boolean;
  openEditReply: boolean;
  onSaveReply: () => void;
  commentType: string;
}

function CommentMobileList({
  commentData,
  onChangeComment,
  replyContent,
  replyData,
  setReplyContent,
  openReply,
  onSaveReply,
  openEditReply,
  commentType,
}: CommentListProps) {
  return (
    <Grid container>
      <Grid
        container
        justifyContent="space-between"
        padding="5px"
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          margin: "5px 0px",
        }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item paddingLeft={`${commentData.depth * 5}px`}>
              <SubdirectoryArrowRightIcon fontSize="small" />
            </Grid>

            <Grid item margin="5px" style={{ whiteSpace: "pre-line" }}>
              {commentData.content}
            </Grid>
          </Grid>

          <Grid container justifyContent="right">
            작성자 : {commentData.createBy?.name}
          </Grid>
          {commentData.createdAt && (
            <Grid container justifyContent="right">
              작성일 : {isoDateFormatter(new Date(commentData.createdAt))}
            </Grid>
          )}
        </Grid>
        <Grid container justifyContent="center" item xs={12}>
          <Grid item>
            <Tooltip title="답변">
              <IconButton
                onClick={() =>
                  onChangeComment(
                    {
                      ...InitCommentInfo,
                      articleId: commentData.articleId,
                      depth: commentData.depth + 1,
                      parentId: commentData.id ? commentData.id : null,
                    },
                    "add"
                  )
                }
              >
                <ChatBubbleOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            {" "}
            <Tooltip title="수정">
              <IconButton onClick={() => onChangeComment(commentData, "edit")}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        {openReply && commentData.id === replyData.parentId && (
          <>
            <CommentInputBox
              onSaveReply={onSaveReply}
              replyContent={replyContent}
              setReplyContent={setReplyContent}
            />
          </>
        )}
        {openEditReply && commentData.id === replyData.id && (
          <>
            <CommentInputBox
              onSaveReply={onSaveReply}
              replyContent={replyContent}
              setReplyContent={setReplyContent}
            />
          </>
        )}
      </Grid>
      {commentData.children &&
        commentData.children.length > 0 &&
        commentData.children.map((childrenDt: CommentInfo, index: number) => (
          <CommentMobileList
            key={"commentData" + index}
            commentData={childrenDt}
            replyData={replyData}
            replyContent={replyContent}
            openReply={openReply}
            openEditReply={openEditReply}
            onChangeComment={onChangeComment}
            onSaveReply={onSaveReply}
            setReplyContent={setReplyContent}
            commentType="meetingNote"
          />
        ))}
    </Grid>
  );
}

export default CommentMobileList;
