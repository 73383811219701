import client from "../ApiService";
import HttpClient from "../http-client";
import { MeetingNoteInfo } from "../types/MeetingNote";

const listUri: string = "/meeting-note";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _MeetingNoteApi extends _BasicApi {
  getMeetingNote(customerId: number) {
    return this.client.get(`${listUri}`, {
      params: { customerId: customerId },
    });
  }
  updateMeetingNote(data: MeetingNoteInfo) {
    return this.client.put(`${listUri}`, data);
  }
  createMeetingNote(data: MeetingNoteInfo) {
    return this.client.post(`${listUri}`, data);
  }
  deleteMeetingNote(id: number) {
    return this.client.delete(`${listUri}`, { params: { id: id } });
  }
  getNewMeetingNote() {
    return this.client.get(`${listUri}/new`);
  }
}

export const MeetingNoteApi = new _MeetingNoteApi(client);
