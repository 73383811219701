import { Grid } from "@mui/material";
import { ByCaseContentEmptyBox } from "../../../../styles/BycaseStyle";
import {
  TableBorderBox,
  TableContentNoPaddingBigBox,
  TableTitleBox,
  TextareaBox,
} from "../../../../styles/theme";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useState } from "react";
import { MeetingNoteInfo } from "../../../../system/types/MeetingNote";
import { InitMeetingNoteInfo } from "../../../../system/types/initObject";
import { isoDateFormatter } from "../../../../components/Common/IsoDateFormatter";

import { MeetingNoteApi } from "../../../../system/api/MeetingNoteApi";
import AlertModal from "../../../../components/Common/AlertModal";
import { ErrorHandler } from "../../../../system/ApiService";
interface ByCaseMeetingNoteProps {
  detailId: number;
  getMeetingNoteList: () => void;
}

function ByCaseMeetingNote({
  detailId,

  getMeetingNoteList,
}: ByCaseMeetingNoteProps) {
  const [meetingNoteData, setMeetingNoteData] =
    useState<MeetingNoteInfo>(InitMeetingNoteInfo);

  const onChange = (args: any, type: string) => {
    if (type === "input") {
      setMeetingNoteData({
        ...meetingNoteData,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else if (type === "dropdown") {
      setMeetingNoteData({
        ...meetingNoteData,
        [(args.target as HTMLInputElement).name]: args.target.itemData,
      });
    } else if (type === "date") {
      setMeetingNoteData({
        ...meetingNoteData,
        [(args.target as HTMLInputElement).name]: isoDateFormatter(
          new Date(args.target.value)
        ),
      });
    }
  };

  var submitcheck = false;
  const onSave = () => {
    if (detailId) {
      submitcheck = true;
      if (submitcheck === true) {
        MeetingNoteApi.createMeetingNote({
          ...meetingNoteData,
          customerId: detailId,
        })
          .then(() => {
            AlertModal("success", "미팅노트가 작성되었습니다.");
            setMeetingNoteData(InitMeetingNoteInfo);
            getMeetingNoteList();
            submitcheck = false;
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      }
    }
  };

  return (
    <>
      <TableBorderBox container>
        <Grid item xs={4} sm={2} md={1}>
          <TableTitleBox item sm={12}>
            날짜
          </TableTitleBox>
          <TableContentNoPaddingBigBox
            container
            alignItems="center"
            item
            sm={12}
          >
            <DatePickerComponent
              name="meetingDate"
              format="yyyy-MM-dd"
              locale="ko"
              value={new Date(meetingNoteData.meetingDate)}
              onChange={(e: any) => {
                onChange(e, "date");
              }}
              placeholder="날짜"
            />
          </TableContentNoPaddingBigBox>
        </Grid>

        <Grid item xs={8} sm={10} md={5.5}>
          <TableTitleBox item sm={12}>
            내용
          </TableTitleBox>
          <TableContentNoPaddingBigBox item sm={12}>
            <TextareaBox
              name="content"
              onChange={(e: any) => {
                onChange(e, "input");
              }}
              value={meetingNoteData.content || ""}
              placeholder="내용을 작성해 주세요."
            />
          </TableContentNoPaddingBigBox>
        </Grid>
        <Grid item xs={6} sm={8} md={3.5}>
          <TableTitleBox item sm={12}>
            NextAction
          </TableTitleBox>
          <TableContentNoPaddingBigBox item sm={12}>
            <TextareaBox
              name="nextAction"
              onChange={(e: any) => {
                onChange(e, "input");
              }}
              value={meetingNoteData.nextAction || ""}
              placeholder="NextAction을 작성해 주세요."
            />
          </TableContentNoPaddingBigBox>
        </Grid>
        <Grid item xs={4} sm={2} md={1}>
          <TableTitleBox item sm={12} overflow="auto">
            F/U 일자
          </TableTitleBox>
          <TableContentNoPaddingBigBox
            container
            alignItems="center"
            item
            sm={12}
          >
            <DatePickerComponent
              name="followUpDate"
              format="yyyy-MM-dd"
              locale="ko"
              value={
                meetingNoteData.followUpDate
                  ? new Date(meetingNoteData.followUpDate)
                  : undefined
              }
              onChange={(e: any) => {
                onChange(e, "date");
              }}
              placeholder="F/U 일자"
            />
          </TableContentNoPaddingBigBox>
        </Grid>
        <ByCaseContentEmptyBox
          container
          item
          xs={2}
          sm={2}
          md={1}
          justifyContent="center"
          alignItems="center"
          onClick={onSave}
        >
          등록
        </ByCaseContentEmptyBox>
      </TableBorderBox>
    </>
  );
}

export default ByCaseMeetingNote;
