import { Grid, Modal, styled, Box } from "@mui/material";
import {
  LeadCustomerInfo,
  LeadOrdersInfo,
  QaQcDetailInfo,
  QaQcInfo,
} from "../../../../system/types/Customer";
import {
  AttachmentFileBox,
  ByCaseMainTitleBox,
} from "../../../../styles/BycaseStyle";
import {
  InputBox,
  ModalStyle,
  PointerItem,
  TableBorderBox,
  TableContentBox,
  TableContentDetailBox,
  TableContentMoneyBox,
  TableContentNoPaddingBox,
  TableTitleBox,
} from "../../../../styles/theme";
import DownloadIcon from "@mui/icons-material/Download";
import { AttachmentFilesInfo } from "../../../../system/types/MeetingNote";
import { useEffect, useState } from "react";
import { TokenApi } from "../../../../system/api/TokenApi";
import { ErrorHandler } from "../../../../system/ApiService";
import AlertModal from "../../../../components/Common/AlertModal";
import { GRAYPALEBORDER, REDTITLE } from "../../../../styles/Color";
import { CustomText } from "../../../../styles/CustomText";
import { X } from "@phosphor-icons/react";
import {
  InitLeadOrdersInfo,
  InitQaQcInfo,
} from "../../../../system/types/initObject";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { CustomButtonGreen } from "../../../../styles/ButtonStyle";
import EditNoteIcon from "@mui/icons-material/EditNote";
import CheckIcon from "@mui/icons-material/Check";
import { QaQcApi } from "../../../../system/api/QaQcApi";
import { isoDateFormatter } from "../../../../components/Common/IsoDateFormatter";

interface LeadDetailInfoProps {
  data: LeadCustomerInfo;
  refreshData: () => void;
}

function validation(data: QaQcInfo) {
  if (data.documentId === "") {
    AlertModal("check", "문서번호를 작성해 주세요.");
    return false;
  } else if (data.deliveryDate === "") {
    AlertModal("check", "납품일을 선택해 주세요.");
    return false;
  }
  return true;
}

function LeadDetailInfo({ data, refreshData }: LeadDetailInfoProps) {
  function addProductPrice(dt: LeadOrdersInfo) {
    const addResult = dt.quantity * dt.price * (100 - dt.discountRate) * 0.01;
    return addResult;
  }

  function addAllProductPrice(dtList: LeadOrdersInfo[]) {
    return dtList.reduce((total, item) => total + addProductPrice(item), 0);
  }
  const [sasToken, setSasToken] = useState("");
  useEffect(() => {
    TokenApi.getSasToken()
      .then((res) => {
        const sasUrlText = res.data.sasUrl;
        const questionMarkIndex = sasUrlText.indexOf("?");
        if (questionMarkIndex !== -1) {
          setSasToken(sasUrlText.slice(questionMarkIndex));
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  function handleDownloadFromStorage(filename: string) {
    // 로컬 스토리지에서 Blob URL 가져오기
    const blobURL = localStorage.getItem("downloadedFile");
    if (!blobURL) {
      console.error("No file downloaded yet.");
      return;
    }
    // 새로운 Blob 생성
    fetch(blobURL)
      .then((response) => response.blob())
      .then((blob) => {
        const newBlob = new Blob([blob], { type: blob.type });
        // Blob을 다운로드
        const url = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        // 다운로드가 완료되면 URL 객체를 해제
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error reading file from local storage:", error);
      });
  }

  function handleDownload(url: string, filename: string) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Blob을 로컬 스토리지에 저장
        const blobURL = URL.createObjectURL(blob);
        localStorage.setItem("downloadedFile", blobURL);
        // 다운로드 실행
        handleDownloadFromStorage(filename);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }

  const [open, setOpen] = useState(false);
  const [qaqcData, setQaQcData] = useState<QaQcInfo>(InitQaQcInfo);
  const handleClose = () => {
    setOpen(false);
  };
  const [selectedData, setSelectedData] =
    useState<LeadOrdersInfo>(InitLeadOrdersInfo);

  const convertListToString = (list: string[]) => {
    return list.join(", ");
  };

  const handleOpen = (item: LeadOrdersInfo) => {
    setOpen(true);
    setSelectedData(item);
    if (item.qaQc === null) {
      setQaQcData({ ...InitQaQcInfo, orderId: item.id ? item.id : null });
      setInputValue("");
    } else {
      setQaQcData({
        ...InitQaQcInfo,
        id: item.qaQc.id,
        orderId: item.id ? item.id : null,
        documentId: item.qaQc.documentId,
        deliveryDate: item.qaQc.deliveryDate,
      });
      setInputValue(convertListToString(item.qaQc.serialNumbers));
    }
  };

  var submitcheck = false;

  const onChange = (args: any) => {
    setQaQcData({
      ...qaqcData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChangeDate = (args: any) => {
    setQaQcData({
      ...qaqcData,
      [(args.target as HTMLInputElement).name]: isoDateFormatter(
        new Date(args.target.value)
      ),
    });
  };
  const onSave = () => {
    if (validation(qaqcData) && submitcheck === false) {
      submitcheck = true;
      if (qaqcData?.id) {
        QaQcApi.updateQaQc(qaqcData?.id, {
          ...qaqcData,
          serialNumbers: inputValue.split(/,\s*|\s+/).filter((item) => item),
        })
          .then(() => {
            handleClose();
            AlertModal("success", "조달검사검수 요청이 수정되었습니다.");
            refreshData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            submitcheck = true;
          });
      } else {
        QaQcApi.createQaQc({
          ...qaqcData,
          serialNumbers: inputValue.split(/,\s*|\s+/).filter((item) => item),
        })
          .then(() => {
            handleClose();
            AlertModal("success", "조달검사검수 요청이 완료되었습니다.");
            refreshData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            submitcheck = true;
          });
      }
    }
  };

  const [inputValue, setInputValue] = useState("");
  const handleChange = (e: any) => {
    setInputValue(e.target.value);
  };
  const handlePaste = (e: any) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text");
    const formattedData = pasteData.split(/[\t\n]+/).join(", ");
    if (formattedData.endsWith(", ")) {
      const formattedEditData = formattedData.slice(0, -2);
      setInputValue(formattedEditData);
    } else {
      setInputValue(formattedData);
    }
  };
  return (
    <Grid container mt="10px">
      <Grid container item xs={12} md={6} mb={6}>
        <ByCaseMainTitleBox container justifyContent="center" item xs={4}>
          고객 정보
        </ByCaseMainTitleBox>

        <TableBorderBox container item xs={11.8}>
          <Grid container>
            <TableTitleBox item xs={4}>
              상호명
            </TableTitleBox>
            <TableContentNoPaddingBox
              container
              item
              xs={8}
              justifyContent="center"
              alignItems="center"
            >
              {data?.address.placeName}
            </TableContentNoPaddingBox>
          </Grid>
          <Grid container>
            <TableTitleBox item xs={4}>
              고객 담당자
            </TableTitleBox>
            <TableContentBox
              container
              item
              xs={8}
              justifyContent="center"
              alignItems="center"
            >
              {data?.name}
            </TableContentBox>
          </Grid>
          <Grid container>
            <TableTitleBox item xs={4}>
              고객 연락처(핸드폰)
            </TableTitleBox>
            <TableContentBox
              item
              xs={8}
              container
              justifyContent="center"
              alignItems="center"
            >
              {data?.phone}
            </TableContentBox>
          </Grid>
          <Grid container>
            <TableTitleBox item xs={4}>
              고객 연락처(내선)
            </TableTitleBox>
            <TableContentBox
              item
              xs={8}
              container
              justifyContent="center"
              alignItems="center"
            >
              {data?.officeNumber}
            </TableContentBox>
          </Grid>

          <Grid container>
            <TableTitleBox item xs={4}>
              고객 이메일
            </TableTitleBox>
            <TableContentBox
              item
              xs={8}
              container
              justifyContent="center"
              alignItems="center"
            >
              {data?.email}
            </TableContentBox>
          </Grid>
          <Grid container>
            <TableTitleBox item xs={4} container justifyContent="center">
              고객 주소
            </TableTitleBox>
            <TableContentDetailBox
              item
              xs={8}
              container
              justifyContent="center"
            >
              {data?.address?.road}
            </TableContentDetailBox>
          </Grid>
          <Grid container>
            <TableTitleBox
              item
              xs={4}
              style={{ borderBottom: "1px solid #B3BBC5" }}
            >
              상세 주소
            </TableTitleBox>
            <TableContentDetailBox
              style={{ borderBottom: "1px solid #B3BBC5" }}
              container
              justifyContent="center"
              item
              xs={8}
            >
              {data?.address?.detail}
            </TableContentDetailBox>
          </Grid>
          {data.customerType === "일반" && (
            <>
              <Grid container>
                <TableTitleBox item xs={4}>
                  업종
                </TableTitleBox>
                <TableContentDetailBox
                  container
                  justifyContent="center"
                  item
                  xs={8}
                >
                  {data?.businessType?.name}
                </TableContentDetailBox>
              </Grid>
              <Grid container>
                <TableTitleBox
                  item
                  xs={4}
                  style={{ borderBottom: "1px solid #B3BBC5" }}
                >
                  과/팀/센터
                </TableTitleBox>
                <TableContentDetailBox
                  style={{ borderBottom: "1px solid #B3BBC5" }}
                  container
                  justifyContent="center"
                  item
                  xs={8}
                >
                  {data?.department?.name}
                </TableContentDetailBox>
              </Grid>

              <Grid container>
                <TableTitleBox item xs={4}>
                  중개업체명
                </TableTitleBox>
                <TableContentDetailBox
                  container
                  justifyContent="center"
                  item
                  xs={8}
                >
                  {data?.intermediaryCompany?.address?.placeName}
                </TableContentDetailBox>
              </Grid>

              <Grid container>
                <TableTitleBox item xs={4}>
                  판매방식
                </TableTitleBox>
                <TableContentDetailBox
                  container
                  justifyContent="center"
                  item
                  xs={8}
                >
                  {data?.saleType?.name}
                </TableContentDetailBox>
              </Grid>
            </>
          )}

          <Grid container>
            <TableTitleBox item xs={4}>
              영업방식
            </TableTitleBox>

            <TableContentDetailBox
              container
              justifyContent="center"
              item
              xs={8}
            >
              [{data?.contactType?.major}] {data?.contactType?.sub}
            </TableContentDetailBox>
          </Grid>
          {data.customerType === "일반" && (
            <>
              <Grid container>
                <TableTitleBox item xs={4}>
                  수주유형
                </TableTitleBox>
                <TableContentDetailBox
                  container
                  justifyContent="center"
                  item
                  xs={8}
                >
                  {data?.contractType?.name}
                </TableContentDetailBox>
              </Grid>

              <Grid container>
                <TableTitleBox item xs={4}>
                  공급유형
                </TableTitleBox>
                <TableContentDetailBox
                  container
                  justifyContent="center"
                  item
                  xs={8}
                >
                  {data?.supplyType?.name}
                </TableContentDetailBox>
              </Grid>

              <Grid container>
                <TableTitleBox item xs={4}>
                  영업단계
                </TableTitleBox>

                <TableContentDetailBox
                  item
                  xs={8}
                  container
                  justifyContent="center"
                >
                  {data?.step?.name}
                </TableContentDetailBox>
              </Grid>
              <Grid container>
                <TableTitleBox item xs={4}>
                  구매 예상 시기
                </TableTitleBox>
                <TableContentDetailBox
                  item
                  xs={8}
                  container
                  justifyContent="center"
                >
                  {data?.estimatedDate}
                </TableContentDetailBox>
              </Grid>
            </>
          )}

          <Grid container>
            <TableTitleBox item xs={4}>
              태그
            </TableTitleBox>
            <TableContentDetailBox
              item
              xs={8}
              container
              justifyContent="center"
            >
              {" "}
              {data.tags.map((tag) => tag?.name).join(", ")}
            </TableContentDetailBox>
          </Grid>
          <Grid container>
            <TableTitleBox item xs={4}>
              비고
            </TableTitleBox>

            <TableContentDetailBox
              item
              xs={8}
              container
              style={{
                overflow: "auto",
                overflowWrap: "anywhere",
                whiteSpace: "pre-line",
                padding: "5px",
              }}
            >
              {data?.description}
            </TableContentDetailBox>
          </Grid>
        </TableBorderBox>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container item xs={12}>
          <Grid item xs={0} md={0.2}></Grid>
          <ByCaseMainTitleBox container justifyContent="center" item xs={4}>
            지사 정보
          </ByCaseMainTitleBox>

          <Grid container justifyContent="right">
            <TableBorderBox container item xs={11.8}>
              <TableTitleBox item xs={4}>
                지사
              </TableTitleBox>
              <TableContentDetailBox
                item
                xs={8}
                container
                justifyContent="center"
              >
                {data?.group?.name}
              </TableContentDetailBox>

              <TableTitleBox item xs={4}>
                영업 담당자
              </TableTitleBox>

              <TableContentDetailBox
                container
                justifyContent="center"
                item
                xs={8}
              >
                {data?.seller?.name}
              </TableContentDetailBox>
            </TableBorderBox>
          </Grid>
        </Grid>
        <Grid container item xs={12} mt="35px" mb="25px">
          <Grid item xs={0} md={0.2}></Grid>
          <ByCaseMainTitleBox container justifyContent="center" item xs={4}>
            제품 정보
          </ByCaseMainTitleBox>
          <Grid container justifyContent="right">
            <TableBorderBox container item xs={11.8}>
              <TableTitleBox item xs={2.5}>
                제품군
              </TableTitleBox>
              <TableTitleBox item xs={2.5}>
                제품명
              </TableTitleBox>
              <TableTitleBox item xs={1}>
                수량
              </TableTitleBox>
              {data?.contractType?.name === "조달" ? (
                <>
                  <TableTitleBox item xs={2}>
                    단가(VAT포함)
                  </TableTitleBox>

                  <TableTitleBox item xs={2}>
                    합계(VAT포함)
                  </TableTitleBox>
                  <TableTitleBox item xs={2}>
                    조달검사검수
                  </TableTitleBox>
                </>
              ) : (
                <>
                  <TableTitleBox item xs={3}>
                    단가(VAT포함)
                  </TableTitleBox>

                  <TableTitleBox item xs={3}>
                    합계(VAT포함)
                  </TableTitleBox>
                </>
              )}

              {data.orders.map((child: LeadOrdersInfo, index: number) => (
                <Grid container key={index}>
                  <Grid container>
                    <TableContentDetailBox
                      item
                      xs={2.5}
                      container
                      justifyContent="center"
                    >
                      {child?.product?.family}
                    </TableContentDetailBox>
                    <TableContentDetailBox
                      item
                      xs={2.5}
                      container
                      justifyContent="center"
                    >
                      {child?.product?.name}
                    </TableContentDetailBox>
                    <TableContentBox
                      item
                      xs={1}
                      container
                      justifyContent="center"
                      alignItems="center"
                    >
                      {child?.quantity}
                    </TableContentBox>
                    {data?.contractType?.name === "조달" ? (
                      <>
                        <TableContentMoneyBox
                          item
                          xs={2}
                          container
                          justifyContent="right"
                        >
                          {child?.price?.toLocaleString()} 원
                        </TableContentMoneyBox>

                        <TableContentMoneyBox
                          item
                          xs={2}
                          container
                          justifyContent="right"
                        >
                          {addProductPrice(child)?.toLocaleString()} 원
                        </TableContentMoneyBox>
                        {child.qaQc === null ? (
                          <TableContentRequestBox
                            item
                            xs={2}
                            container
                            justifyContent="center"
                            alignItems="center"
                            onClick={() => handleOpen(child)}
                          >
                            <EditNoteIcon />
                            작성하기
                          </TableContentRequestBox>
                        ) : (
                          <>
                            {child?.qaQc &&
                            (child?.qaQc as QaQcDetailInfo).isChecked ? (
                              <TableContentRequestCompleteBox
                                item
                                xs={2}
                                container
                                justifyContent="center"
                                alignItems="center"
                              >
                                <CheckIcon />
                                확인완료
                              </TableContentRequestCompleteBox>
                            ) : (
                              <TableContentEditBox
                                item
                                xs={2}
                                container
                                justifyContent="center"
                                alignItems="center"
                                onClick={() => handleOpen(child)}
                              >
                                <CheckIcon />
                                수정하기
                              </TableContentEditBox>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <TableContentMoneyBox
                          item
                          xs={3}
                          container
                          justifyContent="right"
                        >
                          {child?.price?.toLocaleString()} 원
                        </TableContentMoneyBox>

                        <TableContentMoneyBox
                          item
                          xs={3}
                          container
                          justifyContent="right"
                        >
                          {addProductPrice(child)?.toLocaleString()} 원
                        </TableContentMoneyBox>
                      </>
                    )}
                  </Grid>
                </Grid>
              ))}
              {data.orders.length === 0 && (
                <TableContentBox
                  item
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  등록된 제품이 없습니다.
                </TableContentBox>
              )}
              <Grid container justifyContent="right">
                <TableTitleBox item xs={4}>
                  총 합계(VAT포함)
                </TableTitleBox>
                <TableContentBox
                  container
                  item
                  xs={8}
                  alignItems="center"
                  justifyContent="right"
                  style={{ paddingRight: "10px" }}
                >
                  {addAllProductPrice(data?.orders).toLocaleString()} 원
                </TableContentBox>
              </Grid>
            </TableBorderBox>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={0} md={0.2}></Grid>
          <ByCaseMainTitleBox container justifyContent="center" item xs={4}>
            첨부 파일
          </ByCaseMainTitleBox>
          <Grid container item xs={12} justifyContent="right">
            <TableBorderBox item xs={11.8}>
              <TableContentDetailBox item xs={12} container>
                {data.attachmentFiles.length === 0 ? (
                  <Grid container item xs={12} justifyContent="center">
                    등록된 첨부 파일이 없습니다.
                  </Grid>
                ) : (
                  <>
                    {data.attachmentFiles.map(
                      (dt: AttachmentFilesInfo, index: number) => (
                        <AttachmentFileBox
                          key={index}
                          container
                          item
                          xs={12}
                          onClick={() => {
                            handleDownload(dt.filePath + sasToken, dt.filename);
                          }}
                        >
                          <DownloadIcon color="action" />

                          <Grid ml="5px">{dt.filename}</Grid>
                        </AttachmentFileBox>
                      )
                    )}
                  </>
                )}
              </TableContentDetailBox>
            </TableBorderBox>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle("30rem")}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              <CustomText type="title">
                {" "}
                {qaqcData?.id ? "조달검사검수 요청 수정" : "조달검사검수 요청"}
              </CustomText>
              <PointerItem onClick={handleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <CustomText type="subtitle">장비명</CustomText>
              </Grid>
              <Grid item xs={12} mb="5px">
                {selectedData.product.name}
              </Grid>
              <Grid container item xs={12} alignItems="center">
                <CustomText type="subtitle">문서번호</CustomText>
                <CustomText type="small" color={REDTITLE}>
                  *
                </CustomText>
              </Grid>
              <Grid item xs={12} mb="5px">
                <InputBox
                  type="text"
                  name="documentId"
                  placeholder="문서번호를 입력하세요."
                  autoComplete="new-password"
                  onChange={onChange}
                  value={qaqcData.documentId}
                />
              </Grid>
              <Grid container item xs={12} alignItems="center">
                <CustomText type="subtitle">납품일</CustomText>
                <CustomText type="small" color={REDTITLE}>
                  *
                </CustomText>
              </Grid>
              <Grid item xs={12} mb="5px">
                <DatePickerComponent
                  name="deliveryDate"
                  placeholder="납품일을 선택하세요."
                  format="yyyy-MM-dd"
                  locale="ko"
                  value={
                    qaqcData.deliveryDate
                      ? new Date(qaqcData.deliveryDate)
                      : undefined
                  }
                  onChange={onChangeDate}
                  showClearButton={false}
                  max={new Date()}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomText type="subtitle">
                  시리얼번호 (
                  {inputValue.split(/,\s*|\s+/).filter((item) => item).length}/
                  {selectedData.quantity}개 작성)
                </CustomText>
              </Grid>
              <Grid item xs={12}>
                <CustomText type="subtitle" bold400>
                  엑셀 내용을 붙여넣기하시거나 ,(콤마)로 구분해서 작성해주세요.
                </CustomText>
              </Grid>
              <Grid item xs={12} mb="5px">
                <InputBox
                  type="text"
                  name="serialNumbers"
                  placeholder="시리얼 번호를 입력하세요."
                  autoComplete="new-password"
                  value={inputValue}
                  onChange={handleChange}
                  onPaste={handlePaste}
                />
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
}

export default LeadDetailInfo;

const TableContentRequestBox = styled(Grid)({
  borderRight: `1px solid ${GRAYPALEBORDER}`,
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  backgroundColor: `#F0FAF7`,
  color: "#47B998",
  minHeight: "40px",
  overflow: "auto",
  overflowWrap: "anywhere",
  cursor: "pointer",
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "#E4F7F1",
    color: "#47B998",
  },
});

const TableContentEditBox = styled(Grid)({
  borderRight: `1px solid ${GRAYPALEBORDER}`,
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  backgroundColor: "#fff9c4",
  color: "#F59210",
  minHeight: "40px",
  overflow: "auto",
  overflowWrap: "anywhere",
  cursor: "pointer",
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "#fff59d",
    color: "#F59210",
  },
});

const TableContentRequestCompleteBox = styled(Grid)({
  borderRight: `1px solid ${GRAYPALEBORDER}`,
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  minHeight: "40px",
  overflow: "auto",
  overflowWrap: "anywhere",
});
