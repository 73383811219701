import { Grid } from "@mui/material";
import { ByCaseMainTitleBox } from "../../../../styles/BycaseStyle";
import {
  DropDownListComponentBox,
  DropDownTreeComponentBox,
  TableBorderBox,
  TableContentDropdownBox,
  TableTitleBox,
} from "../../../../styles/theme";

import { LeadCustomerInfo } from "../../../../system/types/Customer";
import { Dispatch } from "react";
import { EmployeeTableInfo } from "../../../../system/types/Employee";
import { GroupApi } from "../../../../system/api/GroupApi";
import { ErrorHandler } from "../../../../system/ApiService";
import AlertModal from "../../../../components/Common/AlertModal";
import { GroupInfo } from "../../../../system/types/Group";
import { useRecoilValue } from "recoil";
import { ManagedGroupDataState } from "../../../../system/atoms";

interface ByCaseBranchInfoProps {
  data: LeadCustomerInfo;
  setData: Dispatch<React.SetStateAction<LeadCustomerInfo>>;
  employeeList: EmployeeTableInfo[];
  setEmployeeList: (dt: EmployeeTableInfo[]) => void;
}

function ByCaseBranchInfo({
  data,
  setData,
  employeeList,
  setEmployeeList,
}: ByCaseBranchInfoProps) {
  const groupData = useRecoilValue<GroupInfo[]>(ManagedGroupDataState);

  const onChange = (args: any) => {
    if (args.target.itemData) {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.itemData,
      });
    } else {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: null,
      });
    }
  };

  const findObjectByID = (
    id: number,
    tree: GroupInfo[]
  ): GroupInfo | undefined => {
    for (const node of tree) {
      if (node.id === id) {
        return node;
      }
      if (node.children && node.children.length > 0) {
        const foundInChildren = findObjectByID(id, node.children);
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
  };

  const onChangeTree = (args: any) => {
    if (args.value && args.value.length > 0) {
      const selectedGroupDt = findObjectByID(Number(args.value[0]), groupData);
      if (selectedGroupDt) {
        GroupApi.findGroupEmployee(Number(args.value[0]))
          .then((employeeRes) => {
            setEmployeeList(employeeRes.data);
            if (employeeRes.data.length > 0) {
              setData({
                ...data,
                group: selectedGroupDt,
                seller: employeeRes.data[0],
              });
            } else {
              setData({ ...data, group: selectedGroupDt, seller: null });
            }
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      }
    }
  };
  // 영업 담당자

  const employeeFields: Object = {
    value: "id",
    text: "name",
  };

  let groupfields: Object = {
    dataSource: groupData,
    value: "id",
    text: "name",
    child: "children",
  };

  return (
    <Grid container>
      <Grid item xs={0} md={0.2}></Grid>
      <ByCaseMainTitleBox container justifyContent="center" item xs={4}>
        지사 정보
      </ByCaseMainTitleBox>
      <Grid container item xs={12} justifyContent="right">
        <TableBorderBox container item xs={11.8}>
          <TableTitleBox item xs={4}>
            지사
          </TableTitleBox>
          <TableContentDropdownBox item xs={8}>
            <DropDownTreeComponentBox
              colorvalue={data.group?.id ? "#000000" : "#B2B4B8"}
              fields={groupfields}
              changeOnBlur={false}
              change={onChangeTree}
              placeholder={
                data.group?.id ? data.group.name : "지사를 선택해주세요."
              }
              showClearButton={false}
              autoComplete="new-password"
            />
          </TableContentDropdownBox>

          <TableTitleBox item xs={4}>
            영업 담당자
          </TableTitleBox>

          <TableContentDropdownBox item xs={8}>
            <DropDownListComponentBox
              showClearButton={true}
              dataSource={employeeList}
              colorvalue={data?.seller?.id ? "#000000" : "#B2B4B8"}
              fields={employeeFields}
              name="seller"
              value={data?.seller?.id || null}
              onChange={onChange}
              placeholder={
                data?.seller?.id
                  ? data.seller.name
                  : "영업 담당자를 선택해주세요."
              }
              autoComplete="new-password"
            />
          </TableContentDropdownBox>
        </TableBorderBox>
      </Grid>
    </Grid>
  );
}

export default ByCaseBranchInfo;
