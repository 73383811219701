import { Box, Chip, Grid, Modal, Pagination, Tooltip } from "@mui/material";
import {
  InputSmallBox,
  ModalStyle,
  PointerItem,
  TableBorderBox,
  TableContentBox,
  TableContentBoxPointer,
  TableTitleBox,
} from "../../../styles/theme";
import EditIcon from "@mui/icons-material/Edit";

import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { MyInfoState } from "../../../system/atoms";
import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import { QaQcInfo, QaQcListInfo } from "../../../system/types/Customer";
import { QaQcApi } from "../../../system/api/QaQcApi";
import { isoDateFormatter } from "../../../components/Common/IsoDateFormatter";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../../components/Common/AlertModal";
import {
  Pc,
  TabletMobile,
} from "../../../components/Common/Responseive/ResponsiveScreen";
import CheckIcon from "@mui/icons-material/Check";
import { ButtonBox } from "../../../components/ListSearchBar";
import { MagnifyingGlass, X } from "@phosphor-icons/react";
import { CustomText } from "../../../styles/CustomText";
import {
  InitQaQcInfo,
  InitQaQcListInfo,
} from "../../../system/types/initObject";
import uuid from "react-uuid";
import QAQCModal from "./QAQCModal";
function QAQCTable() {
  const myInfoData = useRecoilValue(MyInfoState);
  const [qaqcDataList, setQaQcDataList] = useState<QaQcListInfo[]>([]);
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const today = new Date();
  const tomorrowDate = new Date(today.setDate(today.getDate() + 1));
  const refreshData = () => {
    QaQcApi.getQaQcList(
      placeName,
      documentId,
      "2000-01-01",
      isoDateFormatter(tomorrowDate),
      pageNumber - 1,
      10
    )
      .then((res) => {
        setQaQcDataList(res.data.qaqcs);
        setTotalPageNumber(res.data.totalPages);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };
  const [prePlaceName, setPrePlaceName] = useState("");
  const [placeName, setPlaceName] = useState("");

  const onChangePlaceName = (args: any) => {
    setPrePlaceName(args.target.value);
  };

  const onClickPlaceName = () => {
    setPlaceName(prePlaceName);
  };

  const handleOnKeyDownPlaceName = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onClickPlaceName();
    }
  };
  const [preDocumentId, setPreDocumentId] = useState("");
  const [documentId, setDocumentId] = useState("");
  const onChangeDocumentId = (args: any) => {
    setPreDocumentId(args.target.value);
  };

  const onClickDocumentId = () => {
    setDocumentId(preDocumentId);
  };

  const handleOnKeyDownDocumentId = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onClickDocumentId();
    }
  };

  useEffect(() => {
    refreshData();
  }, [placeName, documentId]);
  // status = detail, edit, request
  const [qaqcStatus, setQaQcStatus] = useState("detail");
  const [selectedQaQcData, setSelectedQaQcData] =
    useState<QaQcListInfo>(InitQaQcListInfo);
  const [inputValue, setInputValue] = useState("");
  const [editQaQcData, setEditQaQcData] = useState<QaQcInfo>(InitQaQcInfo);
  const [open, setOpen] = useState(false);
  const convertListToString = (list: string[]) => {
    return list.join(", ");
  };

  const onHandleOpen = (item: QaQcListInfo) => {
    setOpen(true);
    setSelectedQaQcData(item);
    {
      item.isChecked === true ? (
        setQaQcStatus("detail")
      ) : (
        <>
          {myInfoData.role === "QAQC" || myInfoData.role === "ADMIN"
            ? setQaQcStatus("request")
            : setQaQcStatus("edit")}
        </>
      );
    }
    if (item?.id) {
      setEditQaQcData({
        ...InitQaQcInfo,
        orderId: item?.order?.id ? item.order?.id : null,
        documentId: item.documentId,
        deliveryDate: item.deliveryDate,
      });
      if (item?.serialNumbers) {
        setInputValue(convertListToString(item.serialNumbers));
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onChange = () => {};

  return (
    <>
      <TableBorderBox container item xs={12} margin="10px 0px">
        <Grid container item xs={12} sm={6}>
          <TableTitleBox
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={4}
          >
            상호명
          </TableTitleBox>
          <TableContentBox
            container
            alignItems="center"
            justifyContent="space-between"
            item
            xs={8}
          >
            <Grid item xs={10}>
              <InputSmallBox
                type="text"
                name="placeName"
                onChange={onChangePlaceName}
                value={prePlaceName}
                placeholder="검색하실 상호명을 입력해 주세요."
                autoComplete="new-password"
                onKeyDown={handleOnKeyDownPlaceName}
              />
            </Grid>
            <ButtonBox item xs={2} onClick={onClickPlaceName}>
              <Tooltip title="검색">
                <MagnifyingGlass size={20} />
              </Tooltip>
            </ButtonBox>
          </TableContentBox>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <TableTitleBox
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={4}
          >
            문서번호
          </TableTitleBox>
          <TableContentBox
            container
            alignItems="center"
            justifyContent="space-between"
            item
            xs={8}
          >
            <Grid item xs={10}>
              <InputSmallBox
                type="text"
                name="documentId"
                onChange={onChangeDocumentId}
                value={preDocumentId}
                placeholder="검색하실 문서번호를 입력해 주세요."
                autoComplete="new-password"
                onKeyDown={handleOnKeyDownDocumentId}
              />
            </Grid>
            <ButtonBox item xs={2} onClick={onClickPlaceName}>
              <Tooltip title="검색">
                <MagnifyingGlass size={20} />
              </Tooltip>
            </ButtonBox>
          </TableContentBox>
        </Grid>
      </TableBorderBox>
      <TableBorderBox container item xs={12} mt="10px">
        <Pc>
          <Grid container item xs={12}>
            <TableTitleBox item xs={3}>
              상호명
            </TableTitleBox>
            <TableTitleBox item xs={2}>
              문서번호
            </TableTitleBox>
            <TableTitleBox item xs={2}>
              제품명
            </TableTitleBox>
            <TableTitleBox item xs={2}>
              요청자
            </TableTitleBox>
            <TableTitleBox item xs={2}>
              판매일
            </TableTitleBox>
            <TableTitleBox item xs={1}></TableTitleBox>
          </Grid>
        </Pc>
        <TabletMobile>
          <Grid container item xs={12}>
            <TableTitleBox item xs={5}>
              상호명
            </TableTitleBox>
            <TableTitleBox item xs={4}>
              문서번호
            </TableTitleBox>

            <TableTitleBox item xs={3}></TableTitleBox>
          </Grid>
        </TabletMobile>

        {qaqcDataList.map((dt, index) => (
          <Grid
            container
            item
            xs={12}
            onClick={() => {
              onHandleOpen(dt);
            }}
            key={uuid()}
          >
            <Pc>
              <>
                <TableContentBoxPointer
                  item
                  xs={3}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  {dt.placeName}
                </TableContentBoxPointer>
                <TableContentBoxPointer
                  item
                  xs={2}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  {dt.documentId}
                </TableContentBoxPointer>
                <TableContentBoxPointer
                  item
                  xs={2}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  {dt.order.product.name}
                </TableContentBoxPointer>
                <TableContentBoxPointer
                  item
                  xs={2}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  {dt.createBy.name}
                </TableContentBoxPointer>
                <TableContentBoxPointer
                  item
                  xs={2}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  {dt.deliveryDate}
                </TableContentBoxPointer>
                <TableContentBox
                  item
                  xs={1}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  {dt.isChecked === true ? (
                    <>
                      {" "}
                      <Chip
                        icon={<CheckIcon />}
                        style={{ cursor: "pointer" }}
                        size="small"
                        color="success"
                        label="완료처리"
                        variant="outlined"
                      />
                    </>
                  ) : (
                    <>
                      {myInfoData.role === "QAQC" ||
                      myInfoData.role === "ADMIN" ? (
                        <Chip
                          icon={<PlaylistAddCheckIcon />}
                          style={{ cursor: "pointer" }}
                          size="small"
                          label="확인요청"
                          variant="outlined"
                          color="error"
                        />
                      ) : (
                        <Chip
                          icon={<EditIcon />}
                          style={{ cursor: "pointer" }}
                          size="small"
                          label="수정"
                          variant="outlined"
                          color="warning"
                        />
                      )}
                    </>
                  )}
                </TableContentBox>
              </>
            </Pc>
            <TabletMobile>
              <>
                <TableContentBoxPointer
                  item
                  xs={5}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  {dt.placeName}
                </TableContentBoxPointer>
                <TableContentBoxPointer
                  item
                  xs={4}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  {dt.documentId}
                </TableContentBoxPointer>
                <TableContentBox
                  item
                  xs={3}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  {dt.isChecked === true ? (
                    <>
                      <Chip
                        icon={<CheckIcon />}
                        size="small"
                        label="완료처리"
                        variant="outlined"
                        color="success"
                        style={{ cursor: "pointer" }}
                      />
                    </>
                  ) : (
                    <>
                      {myInfoData.role === "QAQC" ? (
                        <Chip
                          icon={<PlaylistAddCheckIcon />}
                          size="small"
                          label="확인요청"
                          variant="outlined"
                          color="error"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <Chip
                          icon={<EditIcon />}
                          size="small"
                          label="수정"
                          variant="outlined"
                          color="warning"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </>
                  )}
                </TableContentBox>
              </>
            </TabletMobile>
          </Grid>
        ))}

        {qaqcDataList.length === 0 && (
          <TableContentBox container item justifyContent="center">
            <CustomText type="subtitle" bold400 margin="100px 0px">
              조회된 데이터가 없습니다.
            </CustomText>
          </TableContentBox>
        )}
      </TableBorderBox>
      <Grid container justifyContent="center" alignItems="center" mt="20px">
        <Pagination
          count={totalPageNumber}
          showFirstButton
          showLastButton
          page={pageNumber}
          boundaryCount={2}
          onChange={handleChange}
          size="large"
        />
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle("48rem")}>
          <QAQCModal
            handleClose={handleClose}
            qaqcStatus={qaqcStatus}
            selectedQaQcData={selectedQaQcData}
            editQaQcData={editQaQcData}
            setEditQaQcData={setEditQaQcData}
            inputValue={inputValue}
            setInputValue={setInputValue}
            refreshData={refreshData}
          />
        </Box>
      </Modal>
    </>
  );
}

export default QAQCTable;
