export const EXPECTED_PURCHASE_STAGE = [
  "신규 거래",
  "1개월 이내",
  "3개월 이내",
  "6개월 이내",
  "12개월 이내",
  "장기F/U",
];
export const NOTI_TYPE = [
  { id: "all", name: "전체" },
  { id: "comment", name: "댓글" },
  { id: "meetingNote", name: "미팅노트" },
  { id: "customer", name: "Pipeline" },
  { id: "qna", name: "질문/답변" },
];

export const NOTI_CHECKTYPE = ["전체", "읽음", "안 읽음"];

export const ROLE_TYPE = ["USER", "CS", "ADMIN", "MANAGER", "QAQC"];

export const ENABLED_TYPE = [
  { name: "활성화", value: true },
  { name: "비활성화", value: false },
];

export const QNACLS_TYPE = ["기획", "일정", "기술", "기타"];

export const MONTH_LIST = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];
