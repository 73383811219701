import { useHistory } from "react-router-dom";
import { QnaInfo } from "../../../system/types/Qna";
import { Grid, styled } from "@mui/material";
import uuid from "react-uuid";
import { NameFormatter } from "../../../components/Common/NameFormatter";
import { isoDateFormatter } from "../../../components/Common/IsoDateFormatter";
import {
  BLUEBACKGROUND,
  GRAYPALEBACKGROUND2,
  GRAYPALEBORDER,
} from "../../../styles/Color";
import { TableTypeChip } from "../../../styles/theme";

interface QnaTableProps {
  data: QnaInfo[];
}

function QnaTable({ data }: QnaTableProps) {
  const history = useHistory();

  function onMove(id: number | null | undefined) {
    if (id) {
      history.push(`/qna/detail/${id}`);
    }
  }
  return (
    <MainTableBox container mt="10px">
      <TableTitleBox container>
        <Grid item xs={2}>
          분류
        </Grid>
        <Grid item xs={5}>
          제목
        </Grid>
        <Grid item xs={2.5}>
          작성자
        </Grid>
        <Grid item xs={2.5}>
          작성일
        </Grid>
      </TableTitleBox>

      {data.length > 0 ? (
        <>
          {data.map((dt: QnaInfo, index) => (
            <TableContentsBox
              container
              alignItems="center"
              key={uuid()}
              onClick={() => onMove(dt.id)}
            >
              <TableBox container item xs={2} justifyContent="center">
                <TableTypeChip>{dt.qnaCls}</TableTypeChip>
              </TableBox>
              <TableBox container item xs={5} paddingLeft="10px">
                {dt.title}
              </TableBox>
              <TableBox
                container
                justifyContent="center"
                item
                xs={2.5}
                padding="0px 5px"
              >
                {dt.createBy?.name ? NameFormatter(dt.createBy?.name) : ""}
              </TableBox>
              <TableBox container justifyContent="center" item xs={2.5}>
                {dt.createdAt ? isoDateFormatter(new Date(dt.createdAt)) : ""}
              </TableBox>
            </TableContentsBox>
          ))}
        </>
      ) : (
        <EmptyTableBolder container padding="20px" justifyContent="center">
          작성된 질의응답이 없습니다.
        </EmptyTableBolder>
      )}
    </MainTableBox>
  );
}

export default QnaTable;

const TableBox = styled(Grid)({
  padding: "0px 5px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

const TableTitleBox = styled(Grid)({
  borderTop: `1px solid ${GRAYPALEBORDER}`,
  backgroundColor: GRAYPALEBACKGROUND2,
  padding: "7px 0px",
  textAlign: "center",
  minHeight: "40px",
  overflow: "auto",
});

const TableContentsBox = styled(Grid)({
  borderTop: `1px solid ${GRAYPALEBORDER}`,
  height: "40px",
  cursor: "pointer",
  "&:hover": { backgroundColor: BLUEBACKGROUND },
});

const MainTableBox = styled(Grid)({
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
});

const EmptyTableBolder = styled(Grid)({
  borderTop: `1px solid ${GRAYPALEBORDER}`,
  padding: "200px 0px",
});
