import { useCallback, useEffect, useState } from "react";
import { ErrorHandler } from "../../../../../system/ApiService";
import AlertModal from "../../../../../components/Common/AlertModal";
import { Box, Grid, IconButton, InputBase, Modal } from "@mui/material";
import {
  CustomButtonBlue,
  CustomButtonGreen,
  CustomButtonRed,
  CustomButtonYellow,
} from "../../../../../styles/ButtonStyle";
import { CustomText } from "../../../../../styles/CustomText";
import uuid from "react-uuid";
import {
  AdminGroupListBorder,
  TabContentBox,
} from "../../../../../styles/AdminStyle";
import {
  GRAYPALEDISABLED,
  GRAYPALETITLE,
  GREENCONTENTS,
  GREENTITLE,
  REDTITLE,
} from "../../../../../styles/Color";
import Swal from "sweetalert2";
import {
  DropDownListComponentBox,
  DropDownListComponentGridBox,
  InputBox,
  ModalStyle,
  PointerItem,
  SearchBoxComponent,
} from "../../../../../styles/theme";
import { CheckCircle, X } from "@phosphor-icons/react";
import { InitDropdownListInfo } from "../../../../../system/types/initObject";
import SearchIcon from "@mui/icons-material/Search";
import { DropdownListInfo } from "../../../../../system/types/List";
import { ListApi } from "../../../../../system/api/ListApi";
import { ENABLED_TYPE } from "../../../../../system/Constants";
import { SettingApi } from "../../../../../system/api/SettingApi";
import DropdownSetting from "../OrderChangeSetting/DropdownSetting";

function validation(data: DropdownListInfo) {
  if (data.name === "") {
    AlertModal("check", "공급유형을 작성해 주세요.");
    return false;
  }
  return true;
}
interface enabledProps {
  name: string;
  value: boolean;
}

function SupplyTypeSetting() {
  const [data, setData] = useState<DropdownListInfo[]>([]);
  const [supplyType, setSupplyType] =
    useState<DropdownListInfo>(InitDropdownListInfo);
  const [preSearchWord, setPreSearchWord] = useState("");
  const [searchWord, setSearchWord] = useState("");

  var submitcheck = false;

  const refreshJobSupplyType = useCallback(() => {
    ListApi.getDropdownList("공급유형", searchWord)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [searchWord]);

  useEffect(() => {
    refreshJobSupplyType();
  }, [refreshJobSupplyType]);

  const onChangeEnabled = (args: any) => {
    if (args.target.value === true) {
      setSupplyType((prev) => ({ ...prev, enabled: true }));
    } else {
      setSupplyType((prev) => ({ ...prev, enabled: false }));
    }
  };

  const deleteGroupMember = (id: number | null | undefined) => {
    if (submitcheck === false) {
      submitcheck = true;

      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 공급유형이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed && id) {
          ListApi.deleteDropdownList(id)
            .then(() => {
              AlertModal("success", "선택하신 공급유형이 삭제되었습니다.");
              refreshJobSupplyType();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              submitcheck = false;
            });
        }
      });
    }
  };
  const onChangeSearch = (e: any) => {
    setPreSearchWord(e.target.value);
  };
  const ClickSearch = () => {
    setSearchWord(preSearchWord);
  };

  const handleOnKeyDownSearch = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      ClickSearch();
    }
  };

  // 모달
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const openModal = (dt: DropdownListInfo) => {
    setOpen(true);
    setSupplyType(dt);
  };
  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onSave();
    }
  };
  const onChange = (args: any) => {
    setSupplyType({
      ...supplyType,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onSave = () => {
    if (validation(supplyType)) {
      submitcheck = true;
      if (submitcheck === true) {
        if (supplyType.id) {
          ListApi.updateDropdownList(supplyType)
            .then(() => {
              handleClose();
              refreshJobSupplyType();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
              submitcheck = false;
            });
        } else {
          ListApi.createDropdownList({
            ...supplyType,
            dropDownType: "공급유형",
            priority: data.length,
          })
            .then(() => {
              handleClose();
              refreshJobSupplyType();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
              submitcheck = false;
            });
        }
      }
    }
  };

  const enabledFields = { text: "name", value: "value" };

  const itemTemplate = (data: enabledProps) => {
    return <Grid container>{data.name}</Grid>;
  };

  const valueTemplate = (data: enabledProps) => {
    return (
      <CustomText type="subtitle" bold400>
        {data.name}
      </CustomText>
    );
  };

  // 순서 변경
  const [orderChange, setOrderChange] = useState(false);

  const onChangeChangeState = () => {
    if (orderChange) {
      const transformedData = data.map((item) => {
        return {
          targetId: item.id,
          priority: item.priority,
        };
      });
      SettingApi.changePriority({
        infoType: "공급유형",
        changeInfo: transformedData,
      })
        .then(() => {
          refreshJobSupplyType();
          AlertModal("success", "공급유형 순서가 변경되었습니다.");
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    } else {
      setSearchWord("");
    }
    setOrderChange(!orderChange);
  };

  return (
    <Grid>
      {orderChange ? (
        <Grid container justifyContent="right" mt="10px" pr="8px">
          <CustomButtonBlue onClick={onChangeChangeState}>
            <CustomText type="subtitle" bold600 cursorPointer>
              저장
            </CustomText>
          </CustomButtonBlue>{" "}
        </Grid>
      ) : (
        <Grid container justifyContent="right">
          <Grid marginTop="10px">
            <SearchBoxComponent>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="검색어를 입력하세요."
                inputProps={{ "aria-label": "검색어를 입력하세요." }}
                onChange={onChangeSearch}
                onKeyDown={handleOnKeyDownSearch}
                autoComplete="new-password"
              />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={ClickSearch}
              >
                <SearchIcon />
              </IconButton>
            </SearchBoxComponent>
          </Grid>
          <Grid margin="10px 8px 0px 5px">
            <CustomButtonGreen
              onClick={() => {
                openModal(InitDropdownListInfo);
              }}
              style={{ marginRight: "5px" }}
            >
              <CustomText type="subtitle" bold600 cursorPointer>
                추가
              </CustomText>
            </CustomButtonGreen>
            <CustomButtonBlue onClick={onChangeChangeState}>
              <CustomText type="subtitle" bold600 cursorPointer>
                순서 변경
              </CustomText>
            </CustomButtonBlue>
          </Grid>
        </Grid>
      )}
      {orderChange ? (
        <DropdownSetting data={data} setData={setData} />
      ) : (
        <TabContentBox item xs={12}>
          {data.map((child: DropdownListInfo, index: number) => (
            <Grid item xs={12} key={uuid()}>
              <AdminGroupListBorder
                container
                justifyContent="space-between"
                alignItems="center"
              >
                {child.enabled ? (
                  <CustomText type="subtitle" color={GRAYPALETITLE}>
                    {child.name}
                  </CustomText>
                ) : (
                  <CustomText type="subtitle" color={GRAYPALEDISABLED}>
                    {child.name} (비활성화)
                  </CustomText>
                )}

                <Grid item xs={12} sm={6}>
                  <Grid container alignItems="center" justifyContent="right">
                    <CustomButtonYellow
                      style={{ marginRight: "5px" }}
                      onClick={() => {
                        openModal(child);
                      }}
                    >
                      <CustomText type="subtitle" bold600 cursorPointer>
                        수정
                      </CustomText>
                    </CustomButtonYellow>
                    <CustomButtonRed
                      onClick={() => deleteGroupMember(child.id)}
                    >
                      <CustomText type="subtitle" bold600 cursorPointer>
                        삭제
                      </CustomText>
                    </CustomButtonRed>
                  </Grid>
                </Grid>
              </AdminGroupListBorder>
            </Grid>
          ))}
        </TabContentBox>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle("25rem")}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              {supplyType.id ? (
                <CustomText type="title"> 공급유형 수정</CustomText>
              ) : (
                <CustomText type="title"> 공급유형 추가</CustomText>
              )}

              <PointerItem onClick={handleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
            <Grid item xs={12} mt={3} mb={3}>
              <Grid container item xs={12} alignItems="center">
                <Grid mr="5px">공급유형명</Grid>
                {supplyType.name === "" ? (
                  <CustomText type="small" color={REDTITLE}>
                    * 공급유형을 입력해 주세요.
                  </CustomText>
                ) : (
                  <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                )}
              </Grid>
              <InputBox
                type="text"
                name="name"
                onChange={(e: any) => {
                  onChange(e);
                }}
                value={supplyType.name || ""}
                placeholder="이름"
                onKeyDown={handleOnKeyDown}
                autoComplete="new-password"
              />
              <Grid container item xs={12} alignItems="center">
                <Grid mr="5px">활성화 여부</Grid>
                <DropDownListComponentGridBox>
                  <DropDownListComponentBox
                    fields={enabledFields}
                    dataSource={ENABLED_TYPE}
                    value={supplyType.enabled}
                    onChange={onChangeEnabled}
                    itemTemplate={itemTemplate}
                    valueTemplate={valueTemplate}
                    autoComplete="new-password"
                  />
                </DropDownListComponentGridBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="right" mt="20px">
            <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
}

export default SupplyTypeSetting;
