import { Grid, styled } from "@mui/material";
import { MainTableChip } from "../../../styles/theme";
import { NoticeInfo } from "../../../system/types/Board";
import uuid from "react-uuid";
import { useHistory } from "react-router-dom";
import { isoDateFormatter } from "../../../components/Common/IsoDateFormatter";
import { NameFormatter } from "../../../components/Common/NameFormatter";
import {
  BLUEBACKGROUND,
  GRAYPALEBACKGROUND1,
  GRAYPALEBACKGROUND2,
  GRAYPALEBORDER,
  PRIMARY,
  REDBACKGROUND,
} from "../../../styles/Color";

interface NoticeTableProps {
  data: NoticeInfo[];
}

function NoticeTable({ data }: NoticeTableProps) {
  const todayDate = new Date();
  const history = useHistory();

  function onMove(id: number | null | undefined) {
    if (id) {
      history.push(`/notice/detail/${id}`);
    }
  }
  return (
    <MainTableBox container mt="10px">
      <TableTitleBox container>
        <Grid item xs={2}></Grid>
        <Grid item xs={5}>
          제목
        </Grid>
        <Grid item xs={2.5}>
          작성자
        </Grid>
        <Grid item xs={2.5}>
          작성일
        </Grid>
      </TableTitleBox>

      {data.length > 0 ? (
        <>
          {data.map((dt: NoticeInfo, index) => (
            <TableContentsBox
              container
              alignItems="center"
              key={uuid()}
              onClick={() => onMove(dt.id)}
              style={
                dt.mainStartDate &&
                dt.mainEndDate &&
                new Date(dt.mainStartDate) < todayDate &&
                new Date(dt.mainEndDate) > todayDate
                  ? { backgroundColor: GRAYPALEBACKGROUND1 }
                  : {}
              }
            >
              <TableBox
                container
                item
                xs={2}
                paddingLeft="10px"
                justifyContent="center"
              >
                {dt.mainStartDate &&
                  dt.mainEndDate &&
                  new Date(dt.mainStartDate) < todayDate &&
                  new Date(dt.mainEndDate) > todayDate && (
                    <MainTableChip>중요</MainTableChip>
                  )}
              </TableBox>
              <TableBox container item xs={5} paddingLeft="10px">
                {dt.title}
              </TableBox>
              <TableBox
                container
                justifyContent="center"
                item
                xs={2.5}
                padding="0px 5px"
              >
                {dt.createBy?.name ? NameFormatter(dt.createBy?.name) : ""}
              </TableBox>
              <TableBox container justifyContent="center" item xs={2.5}>
                {dt.createdAt ? isoDateFormatter(new Date(dt.createdAt)) : ""}
              </TableBox>
            </TableContentsBox>
          ))}
        </>
      ) : (
        <EmptyTableBolder container padding="20px" justifyContent="center">
          작성된 공지사항이 없습니다.
        </EmptyTableBolder>
      )}
    </MainTableBox>
  );
}

export default NoticeTable;

const TableBox = styled(Grid)({
  padding: "0px 5px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

const TableTitleBox = styled(Grid)({
  borderTop: `1px solid ${GRAYPALEBORDER}`,
  backgroundColor: GRAYPALEBACKGROUND2,
  padding: "7px 0px",
  textAlign: "center",
  minHeight: "40px",
  overflow: "auto",
});

const TableContentsBox = styled(Grid)({
  borderTop: `1px solid ${GRAYPALEBORDER}`,
  height: "40px",
  cursor: "pointer",
  "&:hover": { backgroundColor: BLUEBACKGROUND },
});

const MainTableBox = styled(Grid)({
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
});

const EmptyTableBolder = styled(Grid)({
  borderTop: `1px solid ${GRAYPALEBORDER}`,
  padding: "200px 0px",
});
