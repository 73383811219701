import { Grid } from "@mui/material";
import { CustomText } from "../../../styles/CustomText";
import {
  LoginPageIconBox,
  LoginPageIconButton,
  LoginPageLeftBox,
} from "../../../styles/MainStyle";
import { GRAYPALEDISABLED } from "../../../styles/Color";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { DownloadSimple, House, EnvelopeSimple } from "@phosphor-icons/react";
import AlertModal from "../../../components/Common/AlertModal";
import { MarginLayout } from "../../../styles/theme";

function LeftBox() {
  return (
    <LoginPageLeftBox container alignItems="flex-end">
      <Grid item>
        <Grid container>
          <LoginPageIconBox
            item
            onClick={() => window.open("https://www.inbody.co.kr/")}
          >
            <LoginPageIconButton
              container
              justifyContent="center"
              alignItems="center"
            >
              <House size={32} />
            </LoginPageIconButton>
            <CustomText
              type="body"
              bold400
              align="center"
              color={GRAYPALEDISABLED}
            >
              홈페이지
            </CustomText>
          </LoginPageIconBox>
          <LoginPageIconBox
            item
            onClick={() => AlertModal("memo", "아직 서비스 준비 중입니다.")}
          >
            <LoginPageIconButton
              container
              justifyContent="center"
              alignItems="center"
            >
              <DownloadSimple size={32} />
            </LoginPageIconButton>
            <CustomText
              type="body"
              bold400
              align="center"
              color={GRAYPALEDISABLED}
            >
              가이드
            </CustomText>
          </LoginPageIconBox>
          <LoginPageIconBox
            item
            onClick={() => AlertModal("memo", "아직 서비스 준비 중입니다.")}
          >
            <LoginPageIconButton
              container
              justifyContent="center"
              alignItems="center"
            >
              {" "}
              <EnvelopeSimple size={32} />
            </LoginPageIconButton>
            <CustomText
              type="body"
              bold400
              align="center"
              color={GRAYPALEDISABLED}
            >
              문의하기
            </CustomText>
          </LoginPageIconBox>
        </Grid>
        <MarginLayout marginNumber={10}>
          <CustomText type="subtitle" bold600 color={GRAYPALEDISABLED}>
            InBody
          </CustomText>
          <CustomText type="body" bold400 color={GRAYPALEDISABLED}>
            서울시 강남구 언주로 625 인바디빌딩
          </CustomText>
          <CustomText type="body" bold400 color={GRAYPALEDISABLED}>
            TEL : 02-501-3939 FAX : 02-501-3978
          </CustomText>
        </MarginLayout>
        <MarginLayout marginNumber={10}>
          <CustomText type="body" bold400 color={GRAYPALEDISABLED}>
            Copyright <CopyrightIcon fontSize="small" />{" "}
            {new Date().getFullYear()} InBody CO., LTD. All right reserved
          </CustomText>
        </MarginLayout>
      </Grid>
    </LoginPageLeftBox>
  );
}

export default LeftBox;
