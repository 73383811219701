import client from "../ApiService";
import HttpClient from "../http-client";
import { QnaInfo } from "../types/Qna";

const listUri: string = "/qna";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _QnaApi extends _BasicApi {
  getQna(page: number, size: number, sort: string, searchText?: string) {
    return this.client.get(`${listUri}`, {
      params: { page: page, size: size, sort: sort, searchText: searchText },
    });
  }
  updateQna(data: QnaInfo) {
    return this.client.put(`${listUri}/question`, data);
  }
  createQna(data: QnaInfo) {
    return this.client.post(`${listUri}/question`, data);
  }
  deleteQna(id: number) {
    return this.client.delete(`${listUri}`, { params: { id: id } });
  }
  getQnaDetail(id: number) {
    return this.client.get(`${listUri}/detail`, {
      params: { id: id },
    });
  }
}

export const QnaApi = new _QnaApi(client);
