import { Grid, Pagination } from "@mui/material";
import { CustomText } from "../../../styles/CustomText";
import { NotificationInfo } from "../../../system/types/Notification";
import { useCallback, useEffect, useState } from "react";
import NotiPcList from "./components/NotiPcList";
import {
  Pc,
  TabletMobile,
} from "../../../components/Common/Responseive/ResponsiveScreen";
import NotiMobileList from "./components/NotiMobileLlist";
import { NotificationApi } from "../../../system/api/NotificationApi";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../../components/Common/AlertModal";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { NOTI_CHECKTYPE, NOTI_TYPE } from "../../../system/Constants";
import { useRecoilState } from "recoil";
import { BoardPageNumber, NotiDataState } from "../../../system/atoms";
import {
  CustomButtonDisabled,
  CustomButtonPrimary2,
} from "../../../styles/ButtonStyle";
function NotiMainPage() {
  const [notiData, setNotiData] = useState<NotificationInfo[]>([]);
  const [, setNotificationData] = useRecoilState(NotiDataState);
  const [notifiIdData, setNotifiIdData] = useState<string[]>([]);
  const [checkType, setCheckType] = useState<boolean | null>(null);
  const [type, setType] = useState("all");
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [pageNumber, setPageNumber] = useRecoilState(BoardPageNumber);

  const getNotiData = useCallback(() => {
    NotificationApi.getMyNotification(type, pageNumber - 1, 10, checkType)
      .then((res) => {
        setNotiData(res.data.notifications);
        setTotalPageNumber(res.data.totalPages);
        setNotifiIdData(
          res.data.notifications.map((item: NotificationInfo) => item.messageId)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [type, checkType, pageNumber]);

  useEffect(() => {
    getNotiData();
  }, [type, checkType, getNotiData]);

  function onChangeType(args: any) {
    const selectedType = args.target.value;
    setType(selectedType);
  }

  function onChangeCheckType(args: any) {
    const selectedCheckType = args.target.value;
    if (selectedCheckType === "안 읽음") {
      setCheckType(false);
    } else if (selectedCheckType === "읽음") {
      setCheckType(true);
    } else {
      setCheckType(null);
    }
  }

  const onChangeNotiRead = () => {
    NotificationApi.updateReadState(notifiIdData)
      .then(() => {
        AlertModal("success", "알림이 모두 읽음 처리되었습니다.");
        setNotificationData([]);
        getNotiData();
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  const typeFields: Object = {
    value: "id",
    text: "name",
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };
  return (
    <>
      <CustomText type="title"> 알림 내역 목록</CustomText>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        margin="10px 0px"
      >
        <Grid container alignItems="center" item xs={6}>
          <Grid item xs={5.5} sm={4} md={3}>
            <DropDownListComponent
              dataSource={NOTI_TYPE}
              onChange={onChangeType}
              fields={typeFields}
              placeholder="유형"
            />
          </Grid>

          <Grid ml="10px" item xs={5.5} sm={4} md={3}>
            <DropDownListComponent
              dataSource={NOTI_CHECKTYPE}
              onChange={onChangeCheckType}
              placeholder="확인 여부"
            />
          </Grid>
        </Grid>
        <Grid container item xs={6} sm={6} justifyContent="right">
          {notifiIdData.length === 0 ? (
            <CustomButtonDisabled
              container
              justifyContent="center"
              alignItems="center"
            >
              모두 읽음 처리
            </CustomButtonDisabled>
          ) : (
            <CustomButtonPrimary2 onClick={onChangeNotiRead}>
              모두 읽음 처리
            </CustomButtonPrimary2>
          )}
        </Grid>
      </Grid>
      <Grid>
        <Pc>
          <NotiPcList notiData={notiData} />
        </Pc>
        <TabletMobile>
          <NotiMobileList notiData={notiData} />
        </TabletMobile>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" mt="20px">
        <Pagination
          count={totalPageNumber}
          showFirstButton
          showLastButton
          page={pageNumber}
          boundaryCount={2}
          onChange={handleChange}
          size="large"
        />
      </Grid>
    </>
  );
}

export default NotiMainPage;
