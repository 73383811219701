import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ByCaseMainTitleBox } from "../../../../styles/BycaseStyle";
import {
  DropDownListComponentBox,
  InputSmallBox,
  TableBorderBox,
  TableContentBox,
  TableContentDropdownBox,
  TableContentNoPaddingBox,
  TableTitleBox,
  TextareaBox,
} from "../../../../styles/theme";
import { Dispatch, useEffect, useMemo, useState } from "react";
import {
  AddressInfo,
  IntermediaryCompanyInfo,
  JusoApiInfo,
  LeadCustomerInfo,
} from "../../../../system/types/Customer";
import { debounce } from "@mui/material/utils";
import axios from "axios";
import { InitAddressInfo } from "../../../../system/types/initObject";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { CustomText } from "../../../../styles/CustomText";
import {
  ContactTypeListInfo,
  DropdownListInfo,
  TagListInfo,
} from "../../../../system/types/List";
import { ListApi } from "../../../../system/api/ListApi";
import { ErrorHandler } from "../../../../system/ApiService";
import AlertModal from "../../../../components/Common/AlertModal";
import { CustomerApi } from "../../../../system/api/CustomerApi";
import { TagApi } from "../../../../system/api/TagApi";
import SelectTag from "../../../../components/Common/SelectTag";
import { isoDateFormatter } from "../../../../components/Common/IsoDateFormatter";
import * as gregorian from "../../../../../node_modules/cldr-data/main/ko/ca-gregorian.json";
import * as numbers from "../../../../../node_modules/cldr-data/main/ko/numbers.json";
import * as timeZoneNames from "../../../../../node_modules/cldr-data/main/ko/timeZoneNames.json";
import * as numberingSystems from "../../../../../node_modules/cldr-data/supplemental/numberingSystems.json";
import { loadCldr, L10n } from "@syncfusion/ej2-base";
import { ExpectedTimingConversion } from "../../../../components/Common/ExpectedTimingConversion";
import { REDTITLE } from "../../../../styles/Color";
import { MessageComponent } from "@syncfusion/ej2-react-notifications";
import { useRecoilValue } from "recoil";
import {
  AdminState,
  CSState,
  MyInfoState,
  registerLeadStateAtom,
} from "../../../../system/atoms";
import uuid from "react-uuid";
import { FilteringEventArgs } from "@syncfusion/ej2-react-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import { GroupInfo } from "../../../../system/types/Group";
import { GroupApi } from "../../../../system/api/GroupApi";

interface ByCaseCustomerInfoProps {
  leadId: number;
  data: LeadCustomerInfo;
  setData: Dispatch<React.SetStateAction<LeadCustomerInfo>>;
  onSave: () => void;
  directWritePlaceName: string;
  setDirectWritePlaceName: (directWritePlaceName: string) => void;
  directInput: boolean;
  setDirectInput: (directInput: boolean) => void;
}
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

L10n.load({
  ko: {
    datepicker: { today: "오늘" },
    dropdowns: { noRecordsTemplate: "해당 데이터가 없습니다." },
  },
});

function ByCaseCustomerInfo({
  data,
  setData,
  onSave,
  directWritePlaceName,
  setDirectWritePlaceName,
  directInput,
  setDirectInput,
}: ByCaseCustomerInfoProps) {
  // kakao data
  const [options, setOptions] = useState<AddressInfo[]>([]);
  const [value, setValue]: any = useState(null);
  const [inputValue, setInputValue] = useState("");
  const kakaoKey = "b4cfe488cd8ef4b5f7bc9fbcd0f4b055";
  const jusoKey = `${process.env.REACT_APP_ADDRESS_KEY}`;
  const debounceTic = 100;
  const debounceTic2 = 100;
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const RegisterLeadState = useRecoilValue(registerLeadStateAtom);
  const [jusoValue, setJusoValue]: any = useState(null);
  const [jusoInputValue, setJusoInputValue] = useState("");
  const [jusoOptions, setJusoOptions] = useState<AddressInfo[]>([]);
  const myInfoData = useRecoilValue(MyInfoState);
  const adminValue = useRecoilValue(AdminState);
  const CSValue = useRecoilValue(CSState);

  useEffect(() => {
    const findTopGroupNameByName = (
      id: number,
      groupData: GroupInfo[]
    ): number | null => {
      for (const item of groupData) {
        if (item.id === id) {
          if (item.parentId) {
            return item.parentId;
          } else {
            return item.id;
          }
        } else if (item.children && item.children.length > 0) {
          const result: number | null = findTopGroupNameByName(
            id,
            item.children
          );
          if (result) {
            return result;
          }
        }
      }

      return null;
    };

    if (
      myInfoData?.groups &&
      myInfoData.groups.length > 0 &&
      myInfoData.groups[0]?.name
    ) {
      const myGroup = myInfoData.groups[0].id;

      GroupApi.getAllGroupInfo()
        .then((res) => {
          if (myGroup) {
            const topGroupId = findTopGroupNameByName(myGroup, res.data);

            if (topGroupId === 2) {
              ListApi.getContactTypeList()
                .then((res) => {
                  setContactTypeData(
                    res.data.filter(
                      (item: ContactTypeListInfo) =>
                        item.branchEditable && item.enabled
                    )
                  );
                })
                .catch((err) => {
                  let msg = ErrorHandler(err);
                  AlertModal("msg", msg);
                });
            } else {
              ListApi.getContactTypeList()
                .then((res) => {
                  setContactTypeData(
                    res.data.filter((item: DropdownListInfo) => item.enabled)
                  );
                })
                .catch((err) => {
                  let msg = ErrorHandler(err);
                  AlertModal("msg", msg);
                });
            }
          }
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    } else {
      ListApi.getContactTypeList()
        .then((res) => {
          setContactTypeData(
            res.data.filter((item: DropdownListInfo) => item.enabled)
          );
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [adminValue, myInfoData.groups]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDirectInput(event.target.checked);
    setData({ ...data, address: InitAddressInfo });
    setValue(null);
    setJusoValue(null);
    setJusoOptions([]);
    setOptions([]);
  };
  const [addressData, setAddressData] = useState<AddressInfo>({
    placeName: "",
    road: "",
    bdNm: "",
  });

  useEffect(() => {
    if (addressData?.road && directInput === false) {
      setData((prev: LeadCustomerInfo) => ({
        ...prev,
        address: {
          ...addressData,
          placeName: value?.placeName,
          category: value?.category,
        },
      }));
    }
  }, [
    addressData?.road,
    setData,
    value?.placeName,
    value?.category,
    addressData,
    directInput,
  ]);

  useEffect(() => {
    // Clean up timeout when component unmounts
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const fetchKakaoLocations = useMemo(
    () =>
      debounce(async (value, active) => {
        if (!active || value === null || value === "") {
          setOptions([]);
          return undefined;
        }
        try {
          const response = await axios.get(
            `https://dapi.kakao.com/v2/local/search/keyword.json?query=${value}`,
            {
              headers: {
                Authorization: `KakaoAK ${kakaoKey}`, // YOUR_KAKAO_API_KEY를 발급받은 키로 대체해주세요.
              },
            }
          );

          const locations = response.data.documents.map((location: any) => ({
            road: !location.road_address_name
              ? location.address_name
              : location.road_address_name,
            old: location.address_name,
            locId: location.id,
            category: location.category_name,
            placeName: location.place_name,
            longitude: location.x,
            latitude: location.y,
            placeUrl: location.place_url,
          }));
          setOptions([]);
          setOptions(locations);
        } catch (error) {
          console.error("Error fetching Kakao location data:", error);
        }
      }, debounceTic),
    [debounceTic]
  );

  // 국가주소시스템 API
  const fetchJuso = useMemo(
    () =>
      debounce(async (jusoValue, active) => {
        if (!active || jusoValue === null || jusoValue === "") {
          setJusoOptions([]);
          return undefined;
        }

        try {
          const response = await axios.get(
            `https://business.juso.go.kr/addrlink/addrLinkApi.do?currentPage=1&countPerPage=100&keyword=${jusoValue}&confmKey=${jusoKey}&resultType=json`
          );
          if (directInput === true) {
            const locations = response.data.results?.juso
              ? response.data.results?.juso.map((location: any) => ({
                  admCd: location?.admCd,
                  rn: location?.rn,
                  emdNm: location?.emdNm,
                  zipNo: location?.zipNo,
                  emdNo: location?.emdNo,
                  sggNm: location?.sggNm,
                  engRoad: location?.engAddr,
                  rnMgtSn: location?.rnMgtSn,
                  jibun: location?.jibunAddr,
                  siNm: location?.siNm,
                  bdNm: location?.bdNm,
                  lnbrMnnm: location?.lnbrMnnm,
                  road: location?.roadAddr,
                  lnbrSlno: location?.lnbrSlno,
                  buldMnnm: location?.buldMnnm,
                  liNm: location?.liNm,
                  bdMgtSn: location?.bdMgtSn,
                  buldSlno: location?.buldSlno,
                  category: location?.category,
                }))
              : [];

            setJusoOptions([]);
            setJusoOptions(locations);
          } else {
            const location: JusoApiInfo[] = response.data?.results?.juso;
            const locationData: JusoApiInfo =
              location.length > 0 ? location[0] : {};

            if (locationData) {
              const resultData = {
                placeName: locationData?.bdNm
                  ? locationData?.bdNm
                  : value?.placeName,
                admCd: locationData?.admCd,
                rn: locationData?.rn,
                emdNm: locationData?.emdNm,
                zipNo: locationData?.zipNo,
                emdNo: locationData?.emdNo,
                sggNm: locationData?.sggNm,
                engRoad: locationData?.engAddr,
                rnMgtSn: locationData?.rnMgtSn,
                jibun: locationData?.jibunAddr,
                siNm: locationData?.siNm,
                bdNm: locationData?.bdNm ? locationData?.bdNm : "",
                lnbrMnnm: locationData?.lnbrMnnm,
                road: locationData?.roadAddr ? locationData?.roadAddr : "",
                lnbrSlno: locationData?.lnbrSlno,
                buldMnnm: locationData?.buldMnnm,
                liNm: locationData?.liNm,
                bdMgtSn: locationData?.bdMgtSn,
                buldSlno: locationData?.buldSlno,
              };
              setAddressData({ placeName: "", road: "", bdNm: "" });
              setAddressData(resultData);
            }
          }
        } catch (error) {
          console.error("Error fetching juso location data:", error);
        }
      }, debounceTic2),
    [directInput, value?.placeName, jusoKey]
  );
  useEffect(() => {
    if (directInput === true) {
      let active = true;
      if (jusoInputValue === "") {
        setJusoOptions(jusoValue ? [jusoValue] : []);
        return undefined;
      }

      setTimeoutId((prevTimeoutId) => {
        // Cancel the previous timeout
        if (prevTimeoutId) {
          clearTimeout(prevTimeoutId);
        }

        // Set up a new timeout
        const newTimeoutId = setTimeout(() => {
          fetchJuso(jusoInputValue, active);
        }, 300);

        return newTimeoutId;
      });

      return () => {
        active = false;
      };
    }
  }, [jusoValue, jusoInputValue, fetchJuso, directInput]);

  useEffect(() => {
    let active = true;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    setTimeoutId((prevTimeoutId) => {
      // Cancel the previous timeout
      if (prevTimeoutId) {
        clearTimeout(prevTimeoutId);
      }

      // Set up a new timeout
      const newTimeoutId = setTimeout(() => {
        fetchKakaoLocations(inputValue, active);
      }, 300);

      return newTimeoutId;
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, fetchKakaoLocations]);

  // 중개업체명
  const [intermediaryData, setIntermediaryData] = useState<
    IntermediaryCompanyInfo[]
  >([]);
  const intermediaryFields: Object = {
    value: "id",
    text: "address.placeName",
    child: "children",
  };
  useEffect(() => {
    if (RegisterLeadState === "edit" && data?.id) {
      const placeNameData = data?.address?.placeName;
      setValue((prevValues: any) => ({
        ...prevValues,
        placeName: placeNameData,
      }));
    }
  }, [data?.id, RegisterLeadState]);

  useEffect(() => {
    CustomerApi.getIntermediaryInfo()
      .then((res) => {
        setIntermediaryData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 업종

  const [IndustryClassificationData, setIndustryClassificationData] = useState<
    DropdownListInfo[]
  >([]);
  const industryClassficationFields: Object = {
    dataSource: IndustryClassificationData,
    value: "id",
    text: "name",
    child: "children",
  };
  useEffect(() => {
    ListApi.getDropdownList("업종구분")
      .then((res) => {
        setIndustryClassificationData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);
  // 과/팀/센터
  const [classTeamCenterData, setClassTeamCenterData] = useState<
    DropdownListInfo[]
  >([]);
  const classTeamCenterDataFields: Object = {
    dataSource: classTeamCenterData,
    value: "id",
    text: "name",
    child: "children",
  };
  useEffect(() => {
    ListApi.getDropdownList("과팀센터")
      .then((res) => {
        setClassTeamCenterData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 수주유형
  const [contractTypeData, setContractTypeData] = useState<DropdownListInfo[]>(
    []
  );
  const contractTypeFields: Object = {
    dataSource: contractTypeData,
    value: "id",
    text: "name",
    child: "children",
  };
  useEffect(() => {
    ListApi.getDropdownList("수주유형")
      .then((res) => {
        setContractTypeData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 판매방식
  const [saleTypeData, setSaleTypeData] = useState<DropdownListInfo[]>([]);

  const saleTypeDataFields: Object = {
    dataSource: saleTypeData,
    value: "id",
    text: "name",
    child: "children",
  };
  useEffect(() => {
    ListApi.getDropdownList("판매방식")
      .then((res) => {
        setSaleTypeData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 공급유형
  const [supplyTypeData, setSupplyTypeData] = useState<DropdownListInfo[]>([]);

  const supplyTypeDataFields: Object = {
    value: "id",
    text: "name",
  };
  useEffect(() => {
    ListApi.getDropdownList("공급유형")
      .then((res) => {
        setSupplyTypeData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 영업방식
  const [contactTypeData, setContactTypeData] = useState<ContactTypeListInfo[]>(
    []
  );
  const contactTypeDataFields: Object = {
    groupBy: "major",
    value: "id",
    text: "sub",
  };

  // 영업 단계
  const [saleStageData, setSaleStageData] = useState<DropdownListInfo[]>([]);
  const saleStageDataFields: Object = {
    value: "id",
    text: "name",
  };
  useEffect(() => {
    ListApi.getDropdownList("영업단계")
      .then((res) => {
        setSaleStageData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 태그
  const [tagData, setTagData] = useState<TagListInfo[]>([]);
  const onChangeTag = (tagList: TagListInfo[]) => {
    setData({ ...data, tags: tagList });
  };
  useEffect(() => {
    TagApi.getTagList()
      .then((res) => {
        setTagData(res.data.filter((item: DropdownListInfo) => item.enabled));
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const onChangePhone = (args: any) => {
    const targetValue = args.target.value
      // eslint-disable-next-line
      .replace(/[^0-9]/g, "")
      // eslint-disable-next-line
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      // eslint-disable-next-line
      .replace(/(\-{1,2})$/g, "");
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: targetValue,
    });
  };

  const onChange = (args: any, type: string) => {
    if (type === "input") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else if (type === "dropdown") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.itemData,
      });
    } else if (type === "dropdownStage") {
      if (args.target.itemData) {
        setData({
          ...data,
          [(args.target as HTMLInputElement).name]: args.target.itemData,
          estimatedDate: ExpectedTimingConversion(args.target.itemData.name),
        });
      } else {
        setData({
          ...data,
          [(args.target as HTMLInputElement).name]: null,
          estimatedDate: null,
        });
      }
    } else if (type === "date") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: isoDateFormatter(
          new Date(args.target.value)
        ),
      });
    } else if (type === "inputdetail") {
      setData({
        ...data,
        address: { ...data.address, detail: args.target.value },
      });
    } else if (type === "inputName") {
      setDirectWritePlaceName(args.target.value);
    } else if (type === "intermediaryDropdown") {
      const targetData = args.target.itemData;
      if (targetData) {
        setData({
          ...data,
          [(args.target as HTMLInputElement).name]: {
            ...targetData,
            address: args.target.itemData.address,
          },
        });
      } else {
        setData((prev: LeadCustomerInfo) => ({ ...prev }));
        setData({
          ...data,
          [(args.target as HTMLInputElement).name]: null,
        });
      }
    }
  };
  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onSave();
    }
  };

  const onChangeAddress = (args: any, newValue: any) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);

    const searchAddress = newValue?.road;

    let active = true;
    if (inputValue === "") {
      setJusoOptions(jusoValue ? [jusoValue] : []);
      return undefined;
    }
    fetchJuso(searchAddress, active);

    return () => {
      active = false;
    };
  };

  const onFiltering = (args: FilteringEventArgs, queryData: any) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("name", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };

  const onFilteringIntermediary = (
    args: FilteringEventArgs,
    queryData: any
  ) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("address.placeName", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };

  const onFilteringType = (args: FilteringEventArgs, queryData: any) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("sub", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };
  useEffect(() => {
    if (directInput && jusoValue && jusoValue?.bdNm) {
      setData((prev: LeadCustomerInfo) => ({
        ...prev,
        address: { ...prev.address, detail: jusoValue.bdNm },
      }));
    }
  }, [jusoValue, setData, directInput]);
  return (
    <Grid container item xs={12} mb="35px">
      <ByCaseMainTitleBox container justifyContent="center" item xs={4}>
        고객 정보
      </ByCaseMainTitleBox>
      <Grid container item xs={8} padding="7px">
        <CustomText fontSize="small" color={REDTITLE}>
          *
        </CustomText>
        표시는 필수값입니다.
      </Grid>
      {data.customerType === "일반" && (
        <Grid item xs={12} md={11.7} paddingBottom="5px">
          <MessageComponent content="간판의 경우, 중개업체를 먼저 선택해주세요. 중개업체가 없는 경우, 중개업체 추가 후 진행해주세요." />
        </Grid>
      )}

      <TableBorderBox container item xs={11.71}>
        <TableTitleBox container justifyContent="center" item xs={4}>
          상호명
          <CustomText fontSize="small" color={REDTITLE}>
            *
          </CustomText>
        </TableTitleBox>
        <TableContentNoPaddingBox container item xs={8}>
          <Grid item xs={8}>
            {directInput ? (
              <InputSmallBox
                type="text"
                name="email"
                onChange={(e: any) => {
                  onChange(e, "inputName");
                }}
                value={directWritePlaceName || ""}
                placeholder="상호명을 입력해 주세요."
                onKeyDown={handleOnKeyDown}
                style={{ margin: "10px 0px 0px 13px" }}
                autoComplete="new-password"
              />
            ) : (
              <Autocomplete
                sx={{
                  "& .MuiInputBase-root": {
                    fontFamily: "Noto sans CJK KR",

                    paddingLeft: "3px",
                    "&.MuiInput-root:after": {
                      borderBottom: "2px solid #000000",
                    },
                  },
                }}
                getOptionLabel={(option: AddressInfo) =>
                  typeof option === "string"
                    ? value?.placeName
                    : option.placeName
                }
                size="small"
                filterOptions={(x) => x}
                options={options}
                autoComplete
                //   includeInputInList
                autoSelect
                filterSelectedOptions
                value={value ? value : null}
                isOptionEqualToValue={(
                  option: AddressInfo,
                  value: AddressInfo
                ) => option.id === value.id}
                onChange={(event, newValue) => {
                  onChangeAddress(event, newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={uuid()}>
                      <Grid container alignItems="center">
                        <Grid item sx={{ display: "flex", width: 44 }}>
                          <LocationOnIcon sx={{ color: "text.secondary" }} />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: "calc(100% - 44px)",
                            wordWrap: "break-word",
                          }}
                        >
                          {option.placeName}

                          <Typography variant="body2" color="text.secondary">
                            {option.road}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{ width: "90%", margin: "5px 5% 0px 5%" }}
                  />
                )}
                noOptionsText={
                  <Grid container justifyContent="center" margin="10px 0px">
                    <CustomText
                      type="subtitle"
                      textAlign="center"
                      color="#3D4861"
                    >
                      검색 결과가 없습니다.
                    </CustomText>
                  </Grid>
                }
              />
            )}
          </Grid>
          <Grid item xs={4} container justifyContent="center">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    checked={directInput}
                    onChange={handleChange}
                  />
                }
                label={
                  <CustomText type="subtitle" bold400 marginTop="-5px">
                    직접 입력
                  </CustomText>
                }
              />
            </FormGroup>
          </Grid>
        </TableContentNoPaddingBox>

        <TableTitleBox container item xs={4} justifyContent="center">
          고객 담당자{" "}
        </TableTitleBox>
        <TableContentBox container item xs={8} alignItems="center">
          <InputSmallBox
            type="text"
            name="name"
            onChange={(e: any) => {
              onChange(e, "input");
            }}
            value={data.name || ""}
            placeholder="고객 담당자를 작성해 주세요."
            onKeyDown={handleOnKeyDown}
            autoComplete="new-password"
          />
        </TableContentBox>

        <TableTitleBox container item xs={4} justifyContent="center">
          고객 연락처(핸드폰)
        </TableTitleBox>
        <TableContentBox container item xs={8} alignItems="center">
          <InputSmallBox
            type="text"
            name="phone"
            onChange={(e: any) => {
              onChangePhone(e);
            }}
            value={data.phone || ""}
            placeholder="ex) 010-0000-0000"
            onKeyDown={handleOnKeyDown}
            autoComplete="new-password"
          />
        </TableContentBox>

        <TableTitleBox container item xs={4} justifyContent="center">
          고객 연락처(내선)
        </TableTitleBox>
        <TableContentBox container item xs={8} alignItems="center">
          <InputSmallBox
            type="text"
            name="officeNumber"
            onChange={(e: any) => {
              onChange(e, "input");
            }}
            value={data.officeNumber || ""}
            placeholder="ex) 02-000-0000"
            onKeyDown={handleOnKeyDown}
            autoComplete="new-password"
          />
        </TableContentBox>

        <TableTitleBox container item xs={4} justifyContent="center">
          고객 이메일
        </TableTitleBox>
        <TableContentBox container item xs={8} alignItems="center">
          <InputSmallBox
            type="text"
            name="email"
            onChange={(e: any) => {
              onChange(e, "input");
            }}
            value={data.email || ""}
            placeholder="고객 이메일 주소를 작성해 주세요."
            onKeyDown={handleOnKeyDown}
            autoComplete="new-password"
          />
        </TableContentBox>

        <TableTitleBox item xs={4}>
          고객 주소
        </TableTitleBox>
        <TableContentNoPaddingBox container item xs={8} alignItems="center">
          <Grid item xs={12}>
            {directInput ? (
              <Autocomplete
                size="small"
                sx={{
                  "& .MuiInputBase-root": {
                    fontFamily: "Noto sans CJK KR",

                    paddingLeft: "3px",
                    "&.MuiInput-root:after": {
                      borderBottom: "2px solid #000000",
                    },
                  },
                }}
                getOptionLabel={(option: AddressInfo) =>
                  typeof option === "string" ? option : option.road
                }
                filterOptions={(x) => x}
                options={jusoOptions}
                autoComplete
                //   includeInputInList
                autoSelect
                filterSelectedOptions
                value={jusoValue ? jusoValue : null}
                isOptionEqualToValue={(
                  option: AddressInfo,
                  value: AddressInfo
                ) => option.id === value.id}
                onChange={(event, newValue) => {
                  setOptions(
                    newValue ? [newValue, ...jusoOptions] : jusoOptions
                  );
                  setJusoValue(newValue);
                  setData({
                    ...data,
                    address: newValue ? newValue : InitAddressInfo,
                  });
                }}
                onInputChange={(event, newInputValue) => {
                  setJusoInputValue(newInputValue);
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={uuid()}>
                      <Grid container alignItems="center">
                        <Grid item sx={{ display: "flex", width: 44 }}>
                          <LocationOnIcon sx={{ color: "text.secondary" }} />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: "calc(100% - 44px)",
                            wordWrap: "break-word",
                          }}
                        >
                          {option.placeName}
                          <Typography variant="body2" color="text.secondary">
                            {option.road}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {option.jibun}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{ width: "90%", margin: "5px 5% 0px 5%" }}
                  />
                )}
                noOptionsText={
                  <Grid container justifyContent="center" margin="10px 0px">
                    <CustomText
                      type="subtitle"
                      textAlign="center"
                      color="#3D4861"
                    >
                      검색 결과가 없습니다.
                    </CustomText>
                  </Grid>
                }
              />
            ) : (
              <>
                {data?.address?.road ? (
                  <Grid marginLeft="25px">
                    <CustomText type="subtitle" bold400>
                      {data?.address?.road}
                    </CustomText>
                  </Grid>
                ) : (
                  <Grid margin="5px 0px 0px 19px">
                    <CustomText
                      color="#B2B4B8"
                      type="subtitle"
                      bold300
                      paddingLeft="5px"
                    >
                      상호명을 입력하시면 자동 입력됩니다.
                    </CustomText>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </TableContentNoPaddingBox>

        <TableTitleBox
          item
          xs={4}
          style={{ borderBottom: "1px solid #B3BBC5" }}
        >
          상세 주소
        </TableTitleBox>
        <TableContentBox
          item
          xs={8}
          style={{ borderBottom: "1px solid #B3BBC5" }}
          container
          alignItems="center"
        >
          <InputSmallBox
            type="text"
            name="email"
            onChange={(e: any) => {
              onChange(e, "inputdetail");
            }}
            value={data.address.detail || ""}
            placeholder="상세 주소를 작성해 주세요."
            onKeyDown={handleOnKeyDown}
            autoComplete="new-password"
          />
        </TableContentBox>

        {data.customerType === "일반" && (
          <>
            <TableTitleBox container justifyContent="center" item xs={4}>
              업종
              {!CSValue && (
                <CustomText fontSize="small" color={REDTITLE}>
                  *
                </CustomText>
              )}
            </TableTitleBox>
            <TableContentDropdownBox item xs={8}>
              <DropDownListComponentBox
                locale="ko"
                showClearButton={true}
                dataSource={IndustryClassificationData}
                filtering={(args: any) =>
                  onFiltering(args, IndustryClassificationData)
                }
                allowFiltering={true}
                colorvalue={data.businessType?.id ? "#000000" : "#B2B4B8"}
                fields={industryClassficationFields}
                name="businessType"
                value={data.businessType?.id}
                onChange={(e: any) => {
                  onChange(e, "dropdown");
                }}
                placeholder={
                  data.businessType?.id
                    ? data.businessType.name
                    : "업종을 선택해주세요."
                }
                autoComplete="new-password"
              />
            </TableContentDropdownBox>

            <TableTitleBox
              container
              justifyContent="center"
              item
              xs={4}
              style={{ borderBottom: "1px solid #B3BBC5" }}
            >
              과/팀/센터
              {(data.businessType?.name === "양방종합병원" ||
                data.businessType?.name === "한방병원") && (
                <CustomText fontSize="small" color={REDTITLE}>
                  *
                </CustomText>
              )}
            </TableTitleBox>
            <TableContentDropdownBox
              item
              xs={8}
              style={{ borderBottom: "1px solid #B3BBC5" }}
            >
              <DropDownListComponentBox
                locale="ko"
                colorvalue={data.department?.id ? "#000000" : "#B2B4B8"}
                dataSource={classTeamCenterData}
                filtering={(args: any) =>
                  onFiltering(args, classTeamCenterData)
                }
                allowFiltering={true}
                fields={classTeamCenterDataFields}
                name="department"
                value={data.department?.id}
                showClearButton={true}
                onChange={(e: any) => {
                  onChange(e, "dropdown");
                }}
                placeholder={
                  data.department?.id
                    ? data.department.name
                    : "과/팀/센터를 선택해주세요."
                }
                autoComplete="new-password"
              />
            </TableContentDropdownBox>

            <TableTitleBox item xs={4}>
              중개업체명
            </TableTitleBox>
            <TableContentDropdownBox item xs={8}>
              <DropDownListComponentBox
                locale="ko"
                showClearButton={true}
                dataSource={intermediaryData}
                colorvalue={
                  data.intermediaryCompany?.id ? "#000000" : "#B2B4B8"
                }
                filtering={(args: any) =>
                  onFilteringIntermediary(args, intermediaryData)
                }
                allowFiltering={true}
                fields={intermediaryFields}
                name="intermediaryCompany"
                value={data.intermediaryCompany?.id}
                onChange={(e: any) => {
                  onChange(e, "intermediaryDropdown");
                }}
                placeholder={
                  data.intermediaryCompany?.id
                    ? data.intermediaryCompany.name
                    : "중개업체를 선택해주세요."
                }
                autoComplete="new-password"
              />
            </TableContentDropdownBox>

            <TableTitleBox container justifyContent="center" item xs={4}>
              판매방식
              {!CSValue && (
                <CustomText fontSize="small" color={REDTITLE}>
                  *
                </CustomText>
              )}
            </TableTitleBox>
            <TableContentDropdownBox item xs={8}>
              <DropDownListComponentBox
                locale="ko"
                showClearButton={true}
                colorvalue={data.saleType?.id ? "#000000" : "#B2B4B8"}
                dataSource={saleTypeData}
                filtering={(args: any) => onFiltering(args, saleTypeData)}
                allowFiltering={true}
                fields={saleTypeDataFields}
                name="saleType"
                value={data.saleType?.id}
                onChange={(e: any) => {
                  onChange(e, "dropdown");
                }}
                placeholder={
                  data.saleType?.id
                    ? data.saleType.name
                    : "판매방식을 선택해주세요."
                }
                autoComplete="new-password"
              />
            </TableContentDropdownBox>
          </>
        )}
        <Grid container>
          <TableTitleBox container justifyContent="center" item xs={4}>
            영업방식
            <CustomText fontSize="small" color={REDTITLE}>
              *
            </CustomText>
          </TableTitleBox>

          <TableContentDropdownBox item xs={8}>
            <DropDownListComponentBox
              locale="ko"
              filtering={(args: any) => onFilteringType(args, contactTypeData)}
              allowFiltering={true}
              showClearButton={true}
              colorvalue={data.contactType?.id ? "#000000" : "#B2B4B8"}
              dataSource={contactTypeData}
              fields={contactTypeDataFields}
              name="contactType"
              value={data.contactType?.id}
              onChange={(e: any) => {
                onChange(e, "dropdown");
              }}
              placeholder={
                data.contactType?.id
                  ? data.contactType.sub
                  : "영업방식을 선택해주세요."
              }
              autoComplete="new-password"
            />
          </TableContentDropdownBox>
        </Grid>
        {data.customerType === "일반" && (
          <>
            <TableTitleBox item xs={4}>
              수주유형
            </TableTitleBox>
            <TableContentDropdownBox item xs={8}>
              <DropDownListComponentBox
                locale="ko"
                colorvalue={data.contractType?.id ? "#000000" : "#B2B4B8"}
                dataSource={contractTypeData}
                filtering={(args: any) => onFiltering(args, contractTypeData)}
                allowFiltering={true}
                fields={contractTypeFields}
                name="contractType"
                value={data.contractType?.id}
                showClearButton={true}
                onChange={(e: any) => {
                  onChange(e, "dropdown");
                }}
                placeholder={
                  data.contractType?.id
                    ? data.contractType.name
                    : "수주유형을 선택해주세요."
                }
                autoComplete="new-password"
              />
            </TableContentDropdownBox>

            <TableTitleBox item xs={4}>
              공급유형
            </TableTitleBox>
            <TableContentDropdownBox item xs={8}>
              <DropDownListComponentBox
                locale="ko"
                showClearButton={true}
                colorvalue={data.supplyType?.id ? "#000000" : "#B2B4B8"}
                dataSource={supplyTypeData}
                filtering={(args: any) => onFiltering(args, supplyTypeData)}
                allowFiltering={true}
                fields={supplyTypeDataFields}
                name="supplyType"
                value={data.supplyType?.id}
                onChange={(e: any) => {
                  onChange(e, "dropdown");
                }}
                placeholder={
                  data.supplyType?.id
                    ? data.supplyType.name
                    : "공급유형을 선택해주세요."
                }
                autoComplete="new-password"
              />
            </TableContentDropdownBox>

            <TableTitleBox container justifyContent="center" item xs={4}>
              영업단계
              <CustomText fontSize="small" color={REDTITLE}>
                *
              </CustomText>
            </TableTitleBox>

            <TableContentDropdownBox item xs={8}>
              <DropDownListComponentBox
                locale="ko"
                showClearButton={true}
                colorvalue={data.step?.id ? "#000000" : "#B2B4B8"}
                dataSource={saleStageData}
                filtering={(args: any) => onFiltering(args, saleStageData)}
                allowFiltering={true}
                fields={saleStageDataFields}
                name="step"
                value={data.step?.id}
                onChange={(e: any) => {
                  onChange(e, "dropdownStage");
                }}
                placeholder={
                  data.step?.id ? data.step.name : "영업단계를 선택해주세요."
                }
                autoComplete="new-password"
              />
            </TableContentDropdownBox>

            <TableTitleBox item xs={4}>
              구매 예상 시기
            </TableTitleBox>

            <TableContentDropdownBox container item xs={8} alignItems="center">
              {data.estimatedDate ? (
                data.estimatedDate
              ) : (
                <CustomText
                  color="#B2B4B8"
                  type="subtitle"
                  bold300
                  paddingLeft="5px"
                >
                  영업단계를 입력하시면 자동 입력됩니다.
                </CustomText>
              )}
            </TableContentDropdownBox>
          </>
        )}

        <TableTitleBox item xs={4}>
          태그
        </TableTitleBox>
        <TableContentDropdownBox item xs={8}>
          <SelectTag
            list={data.tags}
            onChange={onChangeTag}
            tagList={tagData}
          />
        </TableContentDropdownBox>

        <TableTitleBox item xs={4}>
          비고
        </TableTitleBox>
        <TableContentDropdownBox item xs={8}>
          <TextareaBox
            name="description"
            onChange={(e: any) => {
              onChange(e, "input");
            }}
            value={data.description || ""}
            placeholder="비고를 작성해 주세요."
            autoComplete="new-password"
          />
        </TableContentDropdownBox>
      </TableBorderBox>
    </Grid>
  );
}

export default ByCaseCustomerInfo;
