import client from "../ApiService";
import HttpClient from "../http-client";

const listUri: string = "/statistics";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _StatisticsApi extends _BasicApi {
  groupStatistcs(year: number, month: number, range: number) {
    return this.client.get(`${listUri}/group`, {
      params: { year: year, month: month, range: range },
    });
  }
  groupProduct(year: number, month: number, range: number) {
    return this.client.get(`${listUri}/product`, {
      params: { year: year, month: month, range: range },
    });
  }
  groupProductFamily(year: number, month: number, range: number) {
    return this.client.get(`${listUri}/product/family`, {
      params: { year: year, month: month, range: range },
    });
  }
  contactTypeMajorStatistics(year: number, month: number, range: number) {
    return this.client.get(`${listUri}/contact-type/major`, {
      params: { year: year, month: month, range: range },
    });
  }
  contactTypeSubStatistics(year: number, month: number, range: number) {
    return this.client.get(`${listUri}/contact-type/sub`, {
      params: { year: year, month: month, range: range },
    });
  }
  branchForecastStatistics(year: number, month: number, range: number) {
    return this.client.get(`${listUri}/forecast`, {
      params: { year: year, month: month, range: range },
    });
  }
  businessStatistics(year: number, month: number, range: number) {
    return this.client.get(`${listUri}/business`, {
      params: { year: year, month: month, range: range },
    });
  }
}

export const StatisticsApi = new _StatisticsApi(client);
