import client from "../ApiService";
import HttpClient from "../http-client";

const baseUri: string = "/history/customer";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _HistoryApi extends _BasicApi {
  getHistoryInfo(id?: number) {
    return this.client.get(`${baseUri}`, { params: { id: id } });
  }
}

export const HistoryApi = new _HistoryApi(client);
