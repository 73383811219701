import client from "../ApiService";
import HttpClient from "../http-client";
import { GroupEmployeeInfo, GroupInfo } from "../types/Group";

const baseUri: string = "/group";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _GroupApi extends _BasicApi {
  getAllGroupInfo(id?: number) {
    return this.client.get(`${baseUri}`, { params: { id: id } });
  }
  updateGroupInfo(data: GroupInfo) {
    return this.client.put(`${baseUri}`, data);
  }
  createGroupInfo(data: GroupInfo) {
    return this.client.post(`${baseUri}`, data);
  }
  deleteGroupInfo(id: number | undefined) {
    return this.client.delete(`${baseUri}`, { params: { id: id } });
  }

  findGroupEmployee(groupId: number) {
    return this.client.get(`${baseUri}/employee`, {
      params: { groupId: groupId },
    });
  }
  deleteGroupEmployee(data: GroupEmployeeInfo) {
    return this.client.put(`${baseUri}/employee`, data);
  }
  createGroupEmployee(data: GroupEmployeeInfo) {
    return this.client.post(`${baseUri}/employee`, data);
  }
  getManagedGroupInfo() {
    return this.client.get(`${baseUri}/managed`);
  }
  getSalesProtectionStatistics() {
    return this.client.get(`${baseUri}/sales/protection`);
  }
}

export const GroupApi = new _GroupApi(client);
