import client from "../ApiService";
import HttpClient from "../http-client";
import { SettingInfo } from "../types/Setting";

const listUri: string = "/setting/list/priority";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _SettingApi extends _BasicApi {
  changePriority(data: SettingInfo) {
    return this.client.post(`${listUri}`, data);
  }
}

export const SettingApi = new _SettingApi(client);
