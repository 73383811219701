import Swal from "sweetalert2";
import { GREENCONTENTS } from "../../styles/Color";

function AlertModal(type: string, message?: string, content?: string) {
  return (
    <>
      {type === "success" &&
        Swal.fire({
          title:
            "<div style='font-family:Noto sans CJK KR;font-size:20px;margin-bottom:10px'>" +
            message +
            "</div>",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        })}
      {type === "successMessage" &&
        Swal.fire({
          title:
            "<div style='font-family:Noto sans CJK KR;font-size:20px;'>" +
            message +
            "</div>",
          text: content,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        })}
      {type === "msg" &&
        Swal.fire({
          title:
            "<div style='font-family:Noto sans CJK KR;font-size:20px;margin-bottom:10px'>" +
            message +
            "</div>",
          icon: "error",
          confirmButtonText: "확인",
          confirmButtonColor: GREENCONTENTS,
        })}

      {type === "check" &&
        Swal.fire({
          title:
            "<div style='font-family:Noto sans CJK KR;font-size:20px;margin-bottom:10px'>" +
            message +
            "</div>",
          icon: "warning",
          showConfirmButton: false,
          timer: 1500,
        })}

      {type === "memo" &&
        Swal.fire({
          icon: "success",
          title:
            "<div style='font-family:Noto sans CJK KR;font-size:20px;margin-bottom:10px'>" +
            message +
            "</div>",
          text: content,
          confirmButtonText: "확인",
          confirmButtonColor: GREENCONTENTS,
          timer: 1500,
        })}
    </>
  );
}

export default AlertModal;
