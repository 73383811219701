import client from "../ApiService";
import HttpClient from "../http-client";
import { EmployeeInfo } from "../types/Employee";
import { SalesProtectionSettingInfo } from "../types/Setting";

const baseUri: string = "/admin";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _AdminApi extends _BasicApi {
  CreateBranchEmployee(data: EmployeeInfo) {
    return this.client.post(`${baseUri}/employee`, data);
  }
  UpdateBranchEmployee(data: EmployeeInfo) {
    return this.client.put(`${baseUri}/employee`, data);
  }
  GetSalesProtection() {
    return this.client.get(`${baseUri}/sales`);
  }
  UpdateSalesProtection(dt: SalesProtectionSettingInfo) {
    return this.client.put(`${baseUri}/sales`, dt);
  }
}

const AdminApi = new _AdminApi(client);
export default AdminApi;
