import { Grid, styled } from "@mui/material";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  LineSeries,
  Legend,
  Tooltip,
  AxisModel,
  ColumnSeries,
  DataLabel,
  Highlight,
  DateTimeCategory,
} from "@syncfusion/ej2-react-charts";
import { DropDownListComponentBox } from "../../../styles/theme";
import { MONTH_LIST } from "../../../system/Constants";
import { useEffect, useState } from "react";
import {
  GroupStatisticsDataInfo,
  GroupStatisticsEmployeesInfo,
  GroupStatisticsGroupsInfo,
  GroupStatisticsInfo,
} from "../../../system/types/Statistics";
import { StatisticsApi } from "../../../system/api/StatisticsApi";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../../components/Common/AlertModal";
import { CustomText } from "../../../styles/CustomText";
import StatusByBranchTable from "./StatusByBranchTable";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

interface StatusByBranchProps {
  yearList: string[];
}

function StatusByBranch(this: any, { yearList }: StatusByBranchProps) {
  const [data, setData] = useState<GroupStatisticsInfo>({
    groups: [],
    employees: {},
  });
  const [groupData, setGroupData] = useState<GroupStatisticsDataInfo[]>([]);
  const [employeesData, setEmployeesData] = useState<GroupStatisticsDataInfo[]>(
    []
  );
  // dropdown
  const [searchYear, setSearchYear] = useState(
    new Date().getFullYear().toString()
  );
  const [searchMonth, setSearchMonth] = useState((1).toString());
  const [searchGap, setSearchGap] = useState("12");
  const [branchList, setBranchList] = useState<string[]>([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [tableOpen, setTableOpen] = useState(false);
  const onChangeDate = (args: any, type: string) => {
    if (type === "year") {
      setSearchYear(args.target.value.toString());
    } else if (type === "month") {
      setSearchMonth(args.target.value.toString());
    } else if (type === "gap") {
      setSearchGap(args.target.value.toString());
    } else if (type === "branch") {
      setSelectedBranch(args.target.value);
    }
  };

  const onChangeTableOpen = () => {
    setTableOpen(!tableOpen);
  };

  useEffect(() => {
    StatisticsApi.groupStatistcs(
      Number(searchYear),
      Number(searchMonth),
      Number(searchGap)
    )
      .then((res) => {
        const transformedData = res.data.groups.map(
          (item: GroupStatisticsGroupsInfo) => ({
            groupName: item.groupName,
            data: item.monthTotal.map((count, index) => ({
              x: `${new Date(
                Number(searchYear),
                Number(searchMonth) + index - 1
              )}`,
              y: count,
            })),
          })
        );
        const groupNameData = Object.keys(res.data.employees);
        setBranchList(groupNameData);
        if (groupNameData.length > 0) {
          setSelectedBranch(groupNameData[0]);
          const transformedEmployeesData = res.data.employees[
            groupNameData[0]
          ].map((item: GroupStatisticsEmployeesInfo) => ({
            groupName: item.employeeName,
            data: item.monthTotal.map((count, index) => ({
              x: `${new Date(
                Number(searchYear),
                Number(searchMonth) + index - 1
              )}`,
              y: count,
            })),
          }));
          setEmployeesData(transformedEmployeesData);
        }

        setData(res.data);
        setGroupData(transformedData);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [searchYear, searchMonth, searchGap]);

  useEffect(() => {
    if (selectedBranch && data.employees[selectedBranch]) {
      const transformedEmployeesData = data.employees[selectedBranch].map(
        (item: GroupStatisticsEmployeesInfo) => ({
          groupName: item.employeeName,
          data: item.monthTotal.map((count, index) => ({
            x: `${new Date(
              Number(searchYear),
              Number(searchMonth) + index - 1
            )}`,
            y: count,
          })),
        })
      );
      setEmployeesData(transformedEmployeesData);
    }
  }, [selectedBranch]);

  const primaryxAxis: AxisModel = {
    valueType: "DateTimeCategory",
    labelFormat: "y/M",
    skeleton: "Ed",
  };

  const margin = { left: 25, right: 25, top: 0, bottom: 0 };

  const combineValues = (obj: any) => {
    return Object.values(obj).join("");
  };

  function yearValueTemplate(item: any) {
    return (
      <Grid container justifyContent="center">
        <CustomText type="subtitle" bold400>
          {combineValues(item)}년
        </CustomText>
      </Grid>
    );
  }

  function monthValueTemplate(item: any) {
    return (
      <Grid container justifyContent="center">
        <CustomText type="subtitle" bold400>
          {combineValues(item)}월 부터
        </CustomText>
      </Grid>
    );
  }

  function gapValueTemplate(item: any) {
    return (
      <Grid container justifyContent="center">
        <CustomText type="subtitle" bold400>
          {combineValues(item)}개월
        </CustomText>
      </Grid>
    );
  }

  function branchValueTemplate(item: any) {
    return (
      <Grid container justifyContent="center">
        <CustomText type="subtitle" bold400>
          {combineValues(item)}
        </CustomText>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center">
      <Grid container justifyContent="right" item xs={12} mb="10px">
        <DropdownBox mr="5px">
          <DropDownListComponentBox
            popupWidth={120}
            showClearButton={false}
            dataSource={yearList}
            placeholder="년도"
            onChange={(e: any) => {
              onChangeDate(e, "year");
            }}
            value={searchYear}
            valueTemplate={yearValueTemplate}
          />
        </DropdownBox>
        <DropdownBox mr="5px">
          <DropDownListComponentBox
            popupWidth={120}
            showClearButton={false}
            dataSource={MONTH_LIST}
            placeholder="월"
            onChange={(e: any) => {
              onChangeDate(e, "month");
            }}
            value={searchMonth}
            valueTemplate={monthValueTemplate}
          />
        </DropdownBox>
        <DropdownBox>
          <DropDownListComponentBox
            popupWidth={120}
            showClearButton={false}
            dataSource={MONTH_LIST}
            placeholder="기간"
            onChange={(e: any) => {
              onChangeDate(e, "gap");
            }}
            value={searchGap}
            valueTemplate={gapValueTemplate}
          />
        </DropdownBox>
      </Grid>
      <Grid item xs={12}>
        <ChartComponent
          id="charts"
          primaryXAxis={primaryxAxis}
          tooltip={{ enable: true }}
          legendSettings={{
            enableHighlight: true,
            alignment: "Center",
            position: "Right",
          }}
          title="지사별 Pipeline 현황"
          width="100%"
          margin={margin}
        >
          <Inject
            services={[
              ColumnSeries,
              DataLabel,
              Tooltip,
              LineSeries,
              DateTimeCategory,
              Legend,
              Highlight,
            ]}
          />
          <SeriesCollectionDirective>
            {groupData.map((child: GroupStatisticsDataInfo, index: number) => (
              <SeriesDirective
                key={index}
                dataSource={child.data}
                xName="x"
                yName="y"
                name={child.groupName}
                width={2}
                marker={{
                  visible: true,
                  width: 5,
                  height: 5,
                  shape: "Circle",
                  isFilled: true,
                }}
                type="Line"
              ></SeriesDirective>
            ))}
          </SeriesCollectionDirective>
        </ChartComponent>
      </Grid>
      <Grid item xs={12} mt="50px">
        <Grid container justifyContent="right" item xs={12} mb="10px">
          <DropdownBox mr="5px">
            <DropDownListComponentBox
              popupWidth={150}
              showClearButton={false}
              dataSource={branchList}
              placeholder="지사"
              onChange={(e: any) => {
                onChangeDate(e, "branch");
              }}
              value={selectedBranch}
              valueTemplate={branchValueTemplate}
            />
          </DropdownBox>
        </Grid>
        <Grid item xs={12}>
          <ChartComponent
            id="charts2"
            primaryXAxis={primaryxAxis}
            tooltip={{ enable: true }}
            legendSettings={{
              enableHighlight: true,
              alignment: "Center",
              position: "Right",
            }}
            title="영업사원별 Pipeline 현황"
            width="100%"
            margin={margin}
          >
            <Inject
              services={[
                ColumnSeries,
                DataLabel,
                Tooltip,
                LineSeries,
                DateTimeCategory,
                Legend,
                Highlight,
              ]}
            />
            <SeriesCollectionDirective>
              {employeesData.map(
                (child: GroupStatisticsDataInfo, index: number) => (
                  <SeriesDirective
                    key={index}
                    dataSource={child.data}
                    xName="x"
                    yName="y"
                    name={child.groupName}
                    width={2}
                    marker={{
                      visible: true,
                      width: 5,
                      height: 5,
                      shape: "Circle",
                      isFilled: true,
                    }}
                    type="Line"
                  ></SeriesDirective>
                )
              )}
            </SeriesCollectionDirective>
          </ChartComponent>
        </Grid>
      </Grid>
      <TableOpenBox container onClick={onChangeTableOpen}>
        {tableOpen ? (
          <Grid style={{ cursor: "pointer" }}>
            <ArrowDropUpIcon />
            지사별 Pipeline 현황 표 닫기
          </Grid>
        ) : (
          <Grid style={{ cursor: "pointer" }}>
            <ArrowDropDownIcon />
            지사별 Pipeline 현황 표 펼쳐보기
          </Grid>
        )}
      </TableOpenBox>
      {tableOpen && (
        <Grid container mt="20px">
          <StatusByBranchTable
            data={data}
            searchYear={searchYear}
            searchMonth={searchMonth}
            searchGap={Number(searchGap) + 1}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default StatusByBranch;

const DropdownBox = styled(Grid)({
  width: "120px",
});

const TableOpenBox = styled(Grid)({
  marginTop: "50px",
  textDecoration: "underline",
});
