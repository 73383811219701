import { atom } from "recoil";
import {
  InitGroupInfo,
  InitListSearchInfo,
  InitMyInfo,
} from "./types/initObject";
import { NotificationInfo } from "./types/Notification";

export const leftDrawerOpenState = atom({
  key: "leftDrawerOpenState",
  default: true,
});

export const loginState = atom({
  key: "loginState",
  default: true,
});

export const passwordState = atom({
  key: "passwordState",
  default: false,
});

export const LoadingAtom = atom({
  key: "LoadingAtom",
  default: false,
});

export const loginAccountState = atom({
  key: "loginAccountState",
  default: "",
});

export const AdminState = atom({
  key: "AdminState",
  default: false,
});

export const ManagerState = atom({
  key: "ManagerState",
  default: false,
});

export const CSState = atom({
  key: "CSState",
  default: false,
});

export const MyInfoState = atom({
  key: "MyInfoState",
  default: InitMyInfo,
});

export const BoardPageNumber = atom({
  key: "BoardPageNumber",
  default: 1,
});

export const ManagedGroupDataState = atom({
  key: "ManagedGroupDataState",
  default: [],
});

export const MyBranchDataState = atom({
  key: "MyBranchDataState",
  default: InitGroupInfo,
});

export const registerLeadStateAtom = atom({
  key: "registerLeadStateAtom",
  default: "",
});

export const SearchDataAtom = atom({
  key: "SearchDataAtom",
  default: InitListSearchInfo,
});

export const NotiDataState = atom<NotificationInfo[]>({
  key: "NotiDataState",
  default: [],
});

export const NotiIdState = atom<string[]>({
  key: "NotiIdState",
  default: [],
});

export const ListTypeState = atom<string>({
  key: "ListTypeState",
  default: "list",
});
