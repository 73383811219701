import client from "../ApiService";
import HttpClient from "../http-client";
import { ProductPricesInfo, SearchProductDataInfo } from "../types/Product";

const listUri: string = "/product";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _ProductApi extends _BasicApi {
  searchProduct(familyName?: string | null, productName?: string | null) {
    return this.client.get(`${listUri}`, {
      params: { familyName: familyName, productName: productName },
    });
  }
  updateProduct(data: SearchProductDataInfo) {
    return this.client.put(`${listUri}`, data);
  }
  createProduct(data: SearchProductDataInfo) {
    return this.client.post(`${listUri}`, data);
  }
  deleteProduct(id: number) {
    return this.client.delete(`${listUri}`, { params: { id: id } });
  }
  searchProductFamily(searchText?: string) {
    return this.client.get(`${listUri}/family`, {
      params: { searchText: searchText },
    });
  }
  searchProductPrice(
    productId: number,
    groupId?: number | null,
    currency?: string
  ) {
    return this.client.get(`${listUri}/price`, {
      params: { productId: productId, groupId: groupId, currency: currency },
    });
  }
  updateProductPrice(data: ProductPricesInfo) {
    return this.client.put(`${listUri}/price`, data);
  }
  createProductPrice(data: ProductPricesInfo) {
    return this.client.post(`${listUri}/price`, data);
  }
  deleteProductPrice(id: number) {
    return this.client.delete(`${listUri}/price`, { params: { id: id } });
  }
}

export const ProductApi = new _ProductApi(client);
