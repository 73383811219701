import { useHistory, useParams } from "react-router-dom";
import { CustomText } from "../../styles/CustomText";

import { useCallback, useEffect, useState } from "react";

import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../../components/Common/AlertModal";
import { CustomButtonGreen, CustomButtonRed } from "../../styles/ButtonStyle";
import { Grid, styled } from "@mui/material";
import {
  TableBorderBox,
  TableNoticeTitleBox,
  TableTitleBox,
  TableTypeChip,
} from "../../styles/theme";
import { isoDateFormatter } from "../../components/Common/IsoDateFormatter";
import { GRAYPALEBORDER, GREENCONTENTS, REDTITLE } from "../../styles/Color";
import { QnaInfo } from "../../system/types/Qna";
import { QnaApi } from "../../system/api/QnaApi";
import { MyInfoState } from "../../system/atoms";
import { useRecoilValue } from "recoil";
import Swal from "sweetalert2";
import {
  AttachmentFilesInfo,
  CommentInfo,
} from "../../system/types/MeetingNote";
import { InitCommentInfo, InitQnaInfo } from "../../system/types/initObject";
import { CommentApi } from "../../system/api/CommentApi";
import CommentList from "../Register/ByCase/Components/CommentList";
import {
  AttachmentFileBox,
  MeetingNoteReplyBox,
  MeetingNoteTextarea,
} from "../../styles/BycaseStyle";
import { TokenApi } from "../../system/api/TokenApi";
import DownloadIcon from "@mui/icons-material/Download";

interface Params {
  id: string;
}

function QnaDetailPage() {
  const { id } = useParams<Params>();
  const detailId = parseInt(id);
  const [data, setData] = useState<QnaInfo>(InitQnaInfo);
  const myInfoData = useRecoilValue(MyInfoState);
  const history = useHistory();

  function onMoveEdit() {
    history.push(`/qna/edit/${id}`);
  }

  const getQna = useCallback(() => {
    QnaApi.getQnaDetail(detailId)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [detailId]);

  useEffect(() => {
    getQna();
  }, [getQna]);

  var submitcheck = false;

  const deleteQna = () => {
    if (submitcheck === false) {
      submitcheck = true;
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 질문이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          QnaApi.deleteQna(detailId)
            .then(() => {
              AlertModal("success", "선택하신 질문이 삭제되었습니다.");
              history.push(`/qna`);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              submitcheck = false;
            });
        }
      });
    }
  };
  // 답변
  const [commentContent, setCommentContent] = useState("");
  const [replyData, setReplyData] = useState<CommentInfo>(InitCommentInfo);
  const [replyContent, setReplyContent] = useState("");
  const [openReply, setOpenReply] = useState<boolean>(false);
  const [openEditReply, setOpenEditReply] = useState<boolean>(false);

  const onChangeComment = (dt: CommentInfo, type: string) => {
    if (type === "add") {
      if (openReply) {
        setOpenReply(false);
      } else {
        setOpenReply(true);
        setReplyData(dt);
        setReplyContent("");
        setOpenEditReply(false);
      }
    } else {
      if (openEditReply) {
        setOpenEditReply(false);
      } else {
        setOpenReply(false);
        setOpenEditReply(true);
        setReplyContent(dt.content);
        setReplyData(dt);
      }
    }
  };

  function onSaveComment() {
    submitcheck = true;
    CommentApi.createComment({
      content: commentContent,
      depth: 0,
      commentType: "qna",
      articleId: detailId,
      isChecked: false,
      parentId: null,
    })
      .then(() => {
        AlertModal("success", "답변이 생성되었습니다.");
        setCommentContent("");
        getQna();
        submitcheck = false;
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
        submitcheck = false;
      });
  }

  function onSaveReply() {
    submitcheck = true;
    if (replyData.id) {
      CommentApi.updateComment({
        ...replyData,
        content: replyContent,
        commentType: "qna",
        articleId: detailId,
      })
        .then(() => {
          AlertModal("success", "답변이 수정되었습니다.");
          setReplyData(InitCommentInfo);
          getQna();
          submitcheck = false;
          setOpenReply(false);
          setOpenEditReply(false);
          setReplyContent("");
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
          submitcheck = false;
        });
    } else {
      CommentApi.createComment({
        ...replyData,
        content: replyContent,
        commentType: "qna",
        articleId: detailId,
      })
        .then(() => {
          AlertModal("success", "답변이 생성되었습니다.");
          setReplyData(InitCommentInfo);
          getQna();
          submitcheck = false;
          setOpenReply(false);
          setOpenEditReply(false);
          setReplyContent("");
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
          submitcheck = false;
        });
    }
  }
  const onChangeReply = (args: any) => {
    setCommentContent(args.target.value);
  };

  const [sasToken, setSasToken] = useState("");

  useEffect(() => {
    TokenApi.getSasToken()
      .then((res) => {
        const sasUrlText = res.data.sasUrl;
        const questionMarkIndex = sasUrlText.indexOf("?");
        if (questionMarkIndex !== -1) {
          setSasToken(sasUrlText.slice(questionMarkIndex));
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  function handleDownloadFromStorage(filename: string) {
    // 로컬 스토리지에서 Blob URL 가져오기
    const blobURL = localStorage.getItem("downloadedFile");
    if (!blobURL) {
      console.error("No file downloaded yet.");
      return;
    }
    // 새로운 Blob 생성
    fetch(blobURL)
      .then((response) => response.blob())
      .then((blob) => {
        const newBlob = new Blob([blob], { type: blob.type });
        // Blob을 다운로드
        const url = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        // 다운로드가 완료되면 URL 객체를 해제
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error reading file from local storage:", error);
      });
  }

  function handleDownload(url: string, filename: string) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Blob을 로컬 스토리지에 저장
        const blobURL = URL.createObjectURL(blob);
        localStorage.setItem("downloadedFile", blobURL);
        // 다운로드 실행
        handleDownloadFromStorage(filename);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }

  return (
    <>
      <CustomText type="title">질의응답 상세</CustomText>
      {myInfoData.id === data?.createBy?.id && (
        <Grid container justifyContent="right" margin="5px 0px">
          <Grid mr="5px">
            <CustomButtonGreen onClick={onMoveEdit}>수정</CustomButtonGreen>
          </Grid>
          <Grid>
            <CustomButtonRed onClick={deleteQna}>삭제</CustomButtonRed>
          </Grid>
        </Grid>
      )}

      <Grid container mt="10px">
        <TableNoticeTitleBox
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            container
            item
            padding="0px 10px"
            xs={12}
            sm={6}
            alignItems="center"
          >
            <TableTypeChip mr="10px">{data.qnaCls}</TableTypeChip> {data?.title}
          </Grid>
          <Grid
            container
            item
            padding="0px 10px"
            xs={12}
            sm={6}
            justifyContent="right"
          >
            {data?.createBy?.name} /{" "}
            {data?.createdAt ? isoDateFormatter(new Date(data?.createdAt)) : ""}
          </Grid>
        </TableNoticeTitleBox>

        {data.content && (
          <TableContentPreLine item xs={12}>
            <div dangerouslySetInnerHTML={{ __html: data.content }} />
          </TableContentPreLine>
        )}
      </Grid>
      <AttachmentTableContentBox container>
        <AttachmentTitleBox container>첨부파일</AttachmentTitleBox>
        <Grid container justifyContent="right">
          {data.attachmentFiles.length === 0 ? (
            <Grid container item xs={12} justifyContent="center" mt="10px">
              첨부 파일 없음
            </Grid>
          ) : (
            <>
              {data.attachmentFiles.map(
                (dt: AttachmentFilesInfo, index: number) => (
                  <AttachmentFileBox
                    key={index}
                    container
                    item
                    xs={12}
                    onClick={() => {
                      handleDownload(dt.filePath + sasToken, dt.filename);
                    }}
                  >
                    <DownloadIcon color="action" />

                    <Grid ml="5px">{dt.filename}</Grid>
                  </AttachmentFileBox>
                )
              )}
            </>
          )}
        </Grid>
      </AttachmentTableContentBox>
      <TableBorderBox mt="10px">
        <MeetingNoteReplyBox container>
          <Grid item xs={12}>
            <MeetingNoteTextarea
              onChange={onChangeReply}
              placeholder="답변을 작성해 주세요."
              value={commentContent}
            />
          </Grid>
          <Grid container justifyContent="right" alignItems="center">
            <CustomButtonGreen onClick={onSaveComment}>저장</CustomButtonGreen>
          </Grid>
        </MeetingNoteReplyBox>
        {data?.comments &&
          data.comments.map((commentDt: any, index: number) => (
            <CommentList
              key={"comments" + index}
              commentData={commentDt}
              replyData={replyData}
              replyContent={replyContent}
              openReply={openReply}
              openEditReply={openEditReply}
              onChangeComment={onChangeComment}
              onSaveReply={onSaveReply}
              setReplyContent={setReplyContent}
              commentType="qna"
              getMeetingNoteList={getQna}
            />
          ))}
      </TableBorderBox>
    </>
  );
}

export default QnaDetailPage;

export const TableContentPreLine = styled(Grid)({
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  padding: "10px",
  minHeight: "300px",
});
const AttachmentTitleBox = styled(Grid)({
  padding: "5px 0px",
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
});

const AttachmentTableContentBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  padding: "10px 20px",
  marginTop: "10px",
  textAlign: "center",
  minHeight: "40px",
  overflow: "auto",
  overflowWrap: "anywhere",
});
