import { Box, Grid, Modal, styled } from "@mui/material";
import { CustomText } from "../../../styles/CustomText";
import {
  CustomButtonGreen,
  CustomButtonRed,
  CustomButtonYellow,
} from "../../../styles/ButtonStyle";
import LeadDetailInfo from "./Components/LeadDetailInfo";
import ByCaseMeetingNote from "./Components/ByCaseMeetingNote";
import { useHistory, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { CustomerApi } from "../../../system/api/CustomerApi";
import { LeadCustomerInfo } from "../../../system/types/Customer";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../../components/Common/AlertModal";
import { ByCaseMainTitleBox } from "../../../styles/BycaseStyle";
import { MeetingNoteInfo } from "../../../system/types/MeetingNote";
import { MeetingNoteApi } from "../../../system/api/MeetingNoteApi";
import ByCaseMeetingNoteList from "./Components/ByCaseMeetingNoteList";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  AdminState,
  MyInfoState,
  registerLeadStateAtom,
} from "../../../system/atoms";
import {
  BigModalStyle,
  PointerItem,
  TableContentBox,
  TableTitleBox,
} from "../../../styles/theme";
import { X } from "@phosphor-icons/react";
import { HistoryApi } from "../../../system/api/HistoryApi";
import { HistoryInfo } from "../../../system/types/History";
import { isoDateFormatter } from "../../../components/Common/IsoDateFormatter";
import Swal from "sweetalert2";
import {
  GREENCONTENTS,
  PRIMARY,
  PRIMARYHOVERED,
  REDTITLE,
} from "../../../styles/Color";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { GroupInfo } from "../../../system/types/Group";
import CheckIcon from "@mui/icons-material/CheckBox";
interface Params {
  id: string;
}

function LeadDetailPage() {
  const { id } = useParams<Params>();
  const detailId = parseInt(id);
  const [data, setData] = useState<LeadCustomerInfo>();
  const [meetingNoteList, setMeetingNoteList] = useState<MeetingNoteInfo[]>([]);
  const history = useHistory();
  const adminValue = useRecoilValue(AdminState);
  const myInfoData = useRecoilValue(MyInfoState);
  const [, setRegisterLeadState] = useRecoilState(registerLeadStateAtom);
  const [protectionStatus, setProtectionStatus] = useState(false);
  const containsDataId = (
    items: GroupInfo[],
    id: number | null | undefined
  ) => {
    for (let item of items) {
      if (item.id === id) {
        return true;
      }
      if (item.children && containsDataId(item.children, id)) {
        return true;
      }
    }
    return false;
  };

  function onMoveEdit() {
    setRegisterLeadState("edit");
    history.push(`/register/edit/${id}`);
  }
  var submitcheck = false;
  function deleteCustomer() {
    if (submitcheck === false) {
      submitcheck = true;
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 Pipeline이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          CustomerApi.deleteCustomerInfo(detailId)
            .then(() => {
              AlertModal("success", "선택하신 Pipeline이 삭제되었습니다.");
              history.push(`/`);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              submitcheck = false;
            });
        }
      });
    }
  }

  const getMeetingNoteList = useCallback(() => {
    MeetingNoteApi.getMeetingNote(detailId)
      .then((res) => {
        setMeetingNoteList(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [detailId]);

  const refreshData = () => {
    CustomerApi.getCustomerDetailInfo(detailId)
      .then((res) => {
        if (
          !(
            myInfoData.role === "ADMIN" ||
            myInfoData.role === "CS" ||
            myInfoData.groups.some((item) => item.id === res.data.group.id)
          )
        ) {
          history.push("/");
          AlertModal("msg", "해당 Pipeline 조회 권한이 없습니다.");
        } else {
          setProtectionStatus(res.data.salesProtection);
          setData(res.data);
          getMeetingNoteList();
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
        history.push(`/`);
      });
  };

  useEffect(() => {
    if (detailId && myInfoData.email) {
      refreshData();
    }
  }, [detailId, myInfoData.email]);
  // 히스토리 모달
  const [hisotryData, setHistoryData] = useState<HistoryInfo[]>([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const openModal = () => {
    setOpen(true);
    if (detailId) {
      HistoryApi.getHistoryInfo(detailId)
        .then((res) => {
          setHistoryData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  };

  const updateProtection = () => {
    if (protectionStatus) {
      Swal.fire({
        title: "영업보호 설정을 해제하시겠습니까?",
        text: "선택하신 가망고객의 영업보호권이 해제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed && data?.id) {
          CustomerApi.updateSalesProtection(data?.id, !protectionStatus)
            .then(() => {
              setProtectionStatus(!protectionStatus);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        }
      });
    } else {
      if (data?.id) {
        CustomerApi.updateSalesProtection(data?.id, !protectionStatus)
          .then(() => {
            setProtectionStatus(!protectionStatus);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      }
    }
  };

  return (
    <>
      <CustomText type="title">Pipeline 상세 페이지</CustomText>
      <Grid container justifyContent="right" margin="5px 0px">
        {(myInfoData.id === data?.seller?.id ||
          myInfoData.id === data?.group?.manager?.id) && (
          <>
            {protectionStatus ? (
              <CustomButtonPrimary2 onClick={updateProtection}>
                <CheckIcon />
                영업 보호권 적용
              </CustomButtonPrimary2>
            ) : (
              <CustomButtonPrimary onClick={updateProtection}>
                <CheckBoxOutlineBlankIcon />
                영업 보호권 적용
              </CustomButtonPrimary>
            )}
          </>
        )}

        {(adminValue ||
          myInfoData.id === data?.seller?.id ||
          myInfoData.id === data?.group?.manager?.id) && (
          <>
            {/* <CustomButtonYellow
            style={{ marginRight: "10px" }}
            onClick={() => AlertModal("memo", "아직 서비스 준비 중입니다.")}
          >
            구매 보류
          </CustomButtonYellow>
          <CustomButtonBlue
            style={{ marginRight: "10px" }}
            onClick={() => AlertModal("memo", "아직 서비스 준비 중입니다.")}
          >
            판매처 연동
          </CustomButtonBlue> */}

            {adminValue && (
              <CustomButtonYellow
                style={{ marginLeft: "5px" }}
                onClick={openModal}
              >
                히스토리
              </CustomButtonYellow>
            )}
            <CustomButtonRed
              onClick={deleteCustomer}
              style={{ marginLeft: "5px" }}
            >
              삭제
            </CustomButtonRed>
            <CustomButtonGreen
              onClick={onMoveEdit}
              style={{ marginLeft: "5px" }}
            >
              수정
            </CustomButtonGreen>
          </>
        )}
      </Grid>
      <Grid container mt="10px">
        {data && <LeadDetailInfo data={data} refreshData={refreshData} />}
      </Grid>
      <Grid container mt="10px">
        <ByCaseMainTitleBox
          container
          justifyContent="center"
          item
          sm={4}
          md={2}
        >
          미팅 노트
        </ByCaseMainTitleBox>
        <ByCaseMeetingNote
          detailId={detailId}
          getMeetingNoteList={getMeetingNoteList}
        />
        <Grid container>
          <ByCaseMeetingNoteList
            detailId={detailId}
            meetingNoteList={meetingNoteList}
            getMeetingNoteList={getMeetingNoteList}
          />
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={BigModalStyle("35rem")}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
              mb={3}
            >
              <CustomText type="title"> 히스토리 내역</CustomText>

              <PointerItem onClick={handleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
            <Grid container>
              <TableTitleBox
                container
                alignItems="center"
                justifyContent="center"
                item
                xs={8}
              >
                내용
              </TableTitleBox>
              <TableTitleBox
                container
                alignItems="center"
                justifyContent="center"
                item
                xs={2}
              >
                담당자
              </TableTitleBox>
              <TableTitleBox
                container
                alignItems="center"
                justifyContent="center"
                item
                xs={2}
              >
                날짜
              </TableTitleBox>
              {hisotryData.map((dt: HistoryInfo, index: number) => (
                <Grid container key={index}>
                  <TableContentBox item xs={8} container alignItems="center">
                    {dt.message}
                  </TableContentBox>
                  <TableContentBox
                    item
                    xs={2}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    {dt.generator.name}
                  </TableContentBox>
                  <TableContentBox
                    item
                    xs={2}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    {isoDateFormatter(new Date(dt.createdAt))}
                  </TableContentBox>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default LeadDetailPage;

const CustomButtonPrimary = styled("button")({
  backgroundColor: "#ffffff",
  color: `${PRIMARY}`,
  boxShadow: "none",
  borderRadius: "5px",
  width: "160px",
  height: "2.5rem",
  cursor: "pointer",
  fontWeight: 600,
  border: `1px solid ${PRIMARY}`,
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: `${PRIMARYHOVERED}`,
    boxShadow: "none",
    color: "#ffffff",
  },
});

const CustomButtonPrimary2 = styled("button")({
  backgroundColor: `${PRIMARY}`,
  color: "#ffffff",
  boxShadow: "none",
  borderRadius: "5px",
  width: "160px",
  height: "2.5rem",
  cursor: "pointer",
  fontWeight: 600,
  border: "0px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: `${PRIMARYHOVERED}`,
    boxShadow: "none",
    color: "#ffffff",
  },
});
