import client from "../ApiService";
import HttpClient from "../http-client";
import { JobInfo } from "../types/Group";

const positionUri: string = "/job/position";
const titleUri: string = "/job/title";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _JobApi extends _BasicApi {
  searchJobPosition(searchText?: string) {
    return this.client.get(`${positionUri}`, {
      params: { searchText: searchText },
    });
  }

  updateJobPosition(data: JobInfo) {
    return this.client.put(`${positionUri}`, data);
  }
  createJobPosition(data: JobInfo) {
    return this.client.post(`${positionUri}`, data);
  }
  deleteJobPosition(id: number) {
    return this.client.delete(`${positionUri}`, { params: { id: id } });
  }

  searchJobTitle(searchText?: string) {
    return this.client.get(`${titleUri}`, {
      params: { searchText: searchText },
    });
  }

  updateJobTitle(data: JobInfo) {
    return this.client.put(`${titleUri}`, data);
  }
  createJobTitle(data: JobInfo) {
    return this.client.post(`${titleUri}`, data);
  }
  deleteJobTitle(id: number) {
    return this.client.delete(`${titleUri}`, { params: { id: id } });
  }
}

export const JobApi = new _JobApi(client);
