import { Chip, Divider, Grid } from "@mui/material";
import { CustomText } from "../../styles/CustomText";
import StatusByBranch from "./components/StatusByBranch";
import { useEffect, useState } from "react";
import StatusByProduct from "./components/StatusByProduct";
import { useHistory } from "react-router-dom";
import AlertModal from "../../components/Common/AlertModal";
import { EmployeeApi } from "../../system/api/EmployeeApi";
import { ErrorHandler } from "../../system/ApiService";
import StatusByContactType from "./components/StatusByContactType";
import BranchForecast from "./components/BranchForecast";
import YearList from "../../components/Common/YearList";
import StatusByBusiness from "./components/StatusByBusiness";

function StatusGraphPage() {
  const [years, setYears] = useState<string[]>([]);
  const history = useHistory();
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearList = [];

    // 현재 년도부터 전년도 3개까지 추가
    for (let i = currentYear; i >= currentYear - 3; i--) {
      yearList.push(i.toString());
    }

    // 현재 년도 다음부터 후년도 3개까지 추가
    for (let i = currentYear + 1; i <= currentYear + 3; i++) {
      yearList.push(i.toString());
    }

    setYears(yearList.sort());
  }, []); // 한 번만 실행하도록 빈 배열을 useEffect의 두 번째 매개변수로 전달

  useEffect(() => {
    EmployeeApi.getMyInfo()
      .then((res) => {
        if (res.data.role === "ADMIN" || res.data.role === "MANAGER") {
        } else {
          history.push(`/`);
          AlertModal("msg", "현황 레포트 조회 권한이 없습니다.");
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [history]);

  return (
    <>
      <CustomText type="title">Pipeline 현황 </CustomText>
      <Grid item xs={12}></Grid>
      <Grid container item xs={12} justifyContent="center" mt="30px">
        <StatusByBranch yearList={years} />
      </Grid>
      <Divider style={{ marginTop: "100px" }}>
        <Chip label="제품" style={{ fontSize: "16px", fontWeight: 600 }} />
      </Divider>
      <Grid container item xs={12} justifyContent="center" mt="30px">
        <StatusByProduct yearList={years} />
      </Grid>
      <Divider style={{ marginTop: "100px" }}>
        <Chip label="영업방식" style={{ fontSize: "16px", fontWeight: 600 }} />
      </Divider>
      <Grid container item xs={12} justifyContent="center" mt="30px">
        <StatusByContactType yearList={years} />
      </Grid>
      {/* <Divider style={{ marginTop: "100px" }}>
        <Chip label="업종" style={{ fontSize: "16px", fontWeight: 600 }} />
      </Divider>
      <Grid container item xs={12} justifyContent="center" mt="30px">
        <StatusByBusiness yearList={years} />
      </Grid> */}
      <Divider style={{ marginTop: "100px" }}>
        <Chip
          label="지사별 포캐스트"
          style={{ fontSize: "16px", fontWeight: 600 }}
        />
      </Divider>
      <Grid container item xs={12} justifyContent="center" mt="30px">
        <BranchForecast yearList={years} />
      </Grid>
    </>
  );
}

export default StatusGraphPage;
