import client from "../ApiService";
import HttpClient from "../http-client";

const listUri: string = "/sas/token";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _TokenApi extends _BasicApi {
  getSasToken() {
    return this.client.get(`${listUri}`);
  }
}

export const TokenApi = new _TokenApi(client);
