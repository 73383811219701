import { CustomText } from "../../../styles/CustomText";

import CommonSetting from "./components/CommonSetting";
import {
  Pc,
  TabletMobile,
} from "../../../components/Common/Responseive/ResponsiveScreen";
import { TabContentMobileBox } from "../../../styles/AdminStyle";

function AdminInformationPage() {
  return (
    <>
      <CustomText type="title">정보 관리</CustomText>
      <Pc>
        <CommonSetting />
      </Pc>
      <TabletMobile>
        <TabContentMobileBox item xs={12}>
          <CommonSetting />
        </TabContentMobileBox>
      </TabletMobile>
    </>
  );
}

export default AdminInformationPage;
