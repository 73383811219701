import styled from "@emotion/styled";
import { Drawer, IconButton } from "@mui/material";
import { useRecoilState } from "recoil";
import { leftDrawerOpenState } from "../../system/atoms";
import DrawerMenuList from "./components/DrawerMenuList";
import {
  TabletMobile,
  Pc,
} from "../../components/Common/Responseive/ResponsiveScreen";
import DrawerMenuMobileList from "./components/DrawerMenuMobileList";
import MenuIcon from "@mui/icons-material/Menu";

const drawerWidth = "240px";

export default function PersistentDrawerLeft() {
  const [open, setOpen] = useRecoilState(leftDrawerOpenState);

  return (
    <>
      <Pc>
        <Drawer
          anchor={"left"}
          open={open}
          hideBackdrop={true}
          variant={"persistent"}
          onClose={() => setOpen(false)}
        >
          <LeftMenuWrapper>
            <DrawerMenuList />
          </LeftMenuWrapper>
        </Drawer>
      </Pc>
      <TabletMobile>
        <Drawer
          sx={{
            width: "100%",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "100%",
            },
          }}
          hideBackdrop={true}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton
              style={{ marginLeft: "7px", marginTop: "5px" }}
              edge="start"
              onClick={() => setOpen(false)}
              color="inherit"
              aria-label="menu"
              size="large"
            >
              <MenuIcon sx={{ fontSize: 30 }} />
            </IconButton>
          </DrawerHeader>
          <LeftMenuWrapperMobile>
            <DrawerMenuMobileList />
          </LeftMenuWrapperMobile>
        </Drawer>
      </TabletMobile>
    </>
  );
}

const LeftMenuWrapper = styled.div`
  z-index: 50;
  position: fixed;
  overflow-y: scroll;
  top: 64px;
  left: 0;
  height: 100%;
  width: ${drawerWidth};
  background-color: rgb(60, 60, 59);
  &::-webkit-scrollbar {
    display: none;
  }
  & * {
    color: #ffffff;
  }
`;

const LeftMenuWrapperMobile = styled.div`
  z-index: 1200;
  position: fixed;
  overflow-y: scroll;
  top: 64px;
  left: 0;
  height: 100%;
  width: 100vw;
  background-color: rgb(60, 60, 59);
  &::-webkit-scrollbar {
    display: none;
  }
  & * {
    color: #ffffff;
  }
`;

const DrawerHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
});
