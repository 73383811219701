import { Grid } from "@mui/material";
import {
  DropDownListComponentBox,
  DropDownListComponentGridBox,
  DropDownTreeComponentBox,
  InputBox,
  PointerItem,
} from "../../../../styles/theme";
import {
  CustomButtonDisabled,
  CustomButtonGreen,
} from "../../../../styles/ButtonStyle";
import { CheckCircle, X } from "@phosphor-icons/react";
import { CustomText } from "../../../../styles/CustomText";
import { EmployeeInfo } from "../../../../system/types/Employee";
import { Dispatch, useEffect, useState } from "react";
import { JobApi } from "../../../../system/api/JobApi";
import { GroupInfo, JobInfo } from "../../../../system/types/Group";
import { ErrorHandler } from "../../../../system/ApiService";
import AlertModal from "../../../../components/Common/AlertModal";
import { GREENTITLE, REDTITLE } from "../../../../styles/Color";
import { ENABLED_TYPE, ROLE_TYPE } from "../../../../system/Constants";
import AdminApi from "../../../../system/api/AdminApi";

function validation(data: EmployeeInfo, type: string, modalType?: string) {
  // eslint-disable-next-line
  const exptext = /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}$/;
  if (!data.name) {
    type === "alert" && AlertModal("check", "이름을 작성해 주세요.");

    return false;
  } else if (!data.phone || exptext.test(data.phone) === false) {
    type === "alert" && AlertModal("check", "연락처를 작성해 주세요.");

    return false;
  } else if (!data.email) {
    type === "alert" && AlertModal("check", "이메일을 작성해 주세요.");

    return false;
  } else if (!data.groupId && modalType === "추가") {
    type === "alert" && AlertModal("check", "소속 그룹을 선택해주세요.");

    return false;
  } else if (!data.jobTitle?.name) {
    type === "alert" && AlertModal("check", "직책을 선택해주세요.");

    return false;
  } else if (!data.jobPosition?.name) {
    type === "alert" && AlertModal("check", "직위를 선택해주세요.");

    return false;
  }
  return true;
}

function emailValidation(data: string) {
  // eslint-disable-next-line
  const exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
  if (exptext.test(data) === false || data.length > 100) {
    return false;
  }
  return true;
}

function phoneValiation(data: string) {
  // eslint-disable-next-line
  const exptext = /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}$/;
  if (exptext.test(data) === false) {
    return false;
  }
  return true;
}

interface AddEmployeeProps {
  employeeData: EmployeeInfo;
  setEmployeeData: Dispatch<React.SetStateAction<EmployeeInfo>>;
  modalType: string;
  handleClose: () => void;
  refreshEmployeeData: () => void;
  groupData: GroupInfo[];
}

function AdminUserModal({
  employeeData,
  setEmployeeData,
  modalType,
  handleClose,
  groupData,
  refreshEmployeeData,
}: AddEmployeeProps) {
  // const history = useHistory();
  var submitcheck = false;

  const [jobTitleData, setJobTitleData] = useState<JobInfo[]>([]);
  const [jobPositionData, setJobPositionData] = useState<JobInfo[]>([]);

  const titleFields = { text: "name", value: "id" };
  const positionFields = { text: "name", value: "id" };
  const onChange = (args: any, type: string) => {
    if (type === "input") {
      setEmployeeData((prev) => ({
        ...prev,
        [(args.target as HTMLInputElement).name]: args.target.value,
      }));
    } else if (type === "dropdown") {
      setEmployeeData((prev) => ({
        ...prev,
        [(args.target as HTMLInputElement).name]: args.target.itemData,
      }));
    }
  };

  const onChangePhone = (args: any) => {
    const targetValue = args.target.value
      // eslint-disable-next-line
      .replace(/[^0-9]/g, "")
      // eslint-disable-next-line
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      // eslint-disable-next-line
      .replace(/(\-{1,2})$/g, "");

    setEmployeeData((prev) => ({
      ...prev,
      [(args.target as HTMLInputElement).name]: targetValue,
    }));
  };

  const onChangeGroup = (args: any) => {
    setEmployeeData((prev) => ({
      ...prev,
      groupId: args.value && args.value.length > 0 ? args.value[0] : "",
    }));
  };

  function onSave() {
    if (validation(employeeData, "alert", modalType)) {
      submitcheck = true;
      if (submitcheck === true) {
        if (modalType === "추가") {
          AdminApi.CreateBranchEmployee(employeeData)
            .then(() => {
              handleClose();
              refreshEmployeeData();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
              submitcheck = false;
            });
        } else {
          AdminApi.UpdateBranchEmployee(employeeData)
            .then(() => {
              handleClose();
              refreshEmployeeData();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
              submitcheck = false;
            });
        }
      }
    }
  }

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onSave();
    }
  };

  useEffect(() => {
    JobApi.searchJobPosition()
      .then((res) => {
        setJobPositionData(res.data.filter((item: JobInfo) => item.enabled));
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
    JobApi.searchJobTitle()
      .then((res) => {
        setJobTitleData(res.data.filter((item: JobInfo) => item.enabled));
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  let fields: Object = {
    dataSource: groupData,
    value: "id",
    text: "name",
    child: "children",
  };

  const enabledFields = { text: "name", value: "value" };
  interface enabledProps {
    name: string;
    value: boolean;
  }

  const itemTemplate = (data: enabledProps) => {
    return <Grid container>{data.name}</Grid>;
  };

  const valueTemplate = (data: enabledProps) => {
    return (
      <CustomText type="subtitle" bold400 ml="5px">
        {data.name}
      </CustomText>
    );
  };
  const onChangeEnabled = (args: any) => {
    if (args.target.value === true) {
      setEmployeeData((prev) => ({
        ...prev,
        [(args.target as HTMLInputElement).name]: true,
      }));
    } else {
      setEmployeeData((prev) => ({
        ...prev,
        [(args.target as HTMLInputElement).name]: false,
      }));
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          item
          xs={12}
        >
          {modalType === "추가" ? (
            <CustomText type="title"> 사용자 추가</CustomText>
          ) : (
            <CustomText type="title"> 정보 수정</CustomText>
          )}

          <PointerItem onClick={handleClose}>
            <X size={32} />
          </PointerItem>
        </Grid>
        <Grid item xs={12}>
          <Grid container item xs={12} alignItems="center">
            <Grid mr="5px">이름</Grid>
            {employeeData.name === "" ? (
              <CustomText type="small" color={REDTITLE}>
                * 이름을 입력해 주세요.
              </CustomText>
            ) : (
              <CheckCircle size={20} color={GREENTITLE} weight="bold" />
            )}
          </Grid>
          <InputBox
            type="text"
            name="name"
            onChange={(e: any) => {
              onChange(e, "input");
            }}
            value={employeeData.name || ""}
            placeholder="이름"
            onKeyDown={handleOnKeyDown}
            autoComplete="new-password"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container item xs={12} alignItems="center">
            <Grid mr="5px">연락처</Grid>
            {employeeData.phone === "" ? (
              <CustomText type="small" color={REDTITLE}>
                * 연락처를 입력해 주세요.
              </CustomText>
            ) : (
              <>
                {phoneValiation(employeeData.phone) ? (
                  <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                ) : (
                  <CustomText type="small" color={REDTITLE}>
                    * 올바른 연락처 형식이 아닙니다. ex. 010-0000-0000
                  </CustomText>
                )}
              </>
            )}
          </Grid>
          <InputBox
            type="text"
            name="phone"
            value={employeeData.phone || ""}
            onChange={(e: any) => {
              onChangePhone(e);
            }}
            placeholder="ex) 010-0000-0000"
            onKeyDown={handleOnKeyDown}
            autoComplete="new-password"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container item xs={12} alignItems="center">
            <Grid mr="5px">이메일</Grid>
            {employeeData.email === "" ? (
              <CustomText type="small" color={REDTITLE}>
                * 이메일을 입력해 주세요.
              </CustomText>
            ) : (
              <>
                {employeeData.email.length > 100 ? (
                  <CustomText type="small" color={REDTITLE}>
                    * 이메일을 100자 이내로 입력해 주세요.
                  </CustomText>
                ) : (
                  <>
                    {emailValidation(employeeData.email) ? (
                      <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                    ) : (
                      <CustomText type="small" color={REDTITLE}>
                        * 올바른 이메일 형식이 아닙니다.
                      </CustomText>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
          <InputBox
            type="text"
            name="email"
            value={employeeData.email || ""}
            onChange={(e: any) => {
              onChange(e, "input");
            }}
            placeholder="ex) 1234@inbody.com"
            onKeyDown={handleOnKeyDown}
            autoComplete="new-password"
          />
        </Grid>
        {modalType === "추가" && groupData && (
          <Grid item xs={12}>
            <Grid container item xs={12} alignItems="center">
              <Grid mr="5px">소속 그룹</Grid>
              {employeeData.groupId ? (
                <CheckCircle size={20} color={GREENTITLE} weight="bold" />
              ) : (
                <CustomText type="small" color={REDTITLE}>
                  * 소속 그룹을 선택해주세요.
                </CustomText>
              )}
            </Grid>
            <DropDownListComponentGridBox>
              <DropDownTreeComponentBox
                showClearButton={false}
                fields={fields}
                changeOnBlur={false}
                change={onChangeGroup}
                placeholder="소속 그룹"
                onKeyDown={handleOnKeyDown}
                autoComplete="new-password"
              />
            </DropDownListComponentGridBox>
          </Grid>
        )}
        {modalType === "수정" && (
          <Grid item xs={12}>
            <Grid container item xs={12} alignItems="center">
              <Grid mr="5px">권한</Grid>
              <DropDownListComponentGridBox>
                <DropDownListComponentBox
                  dataSource={ROLE_TYPE}
                  name="role"
                  showClearButton={false}
                  onChange={(e: any) => {
                    onChange(e, "dropdown");
                  }}
                  value={employeeData.role}
                  placeholder="권한"
                  onKeyDown={handleOnKeyDown}
                  autoComplete="new-password"
                />
              </DropDownListComponentGridBox>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container item xs={12} alignItems="center">
            <Grid mr="5px">직책</Grid>
            {employeeData.jobTitle?.id ? (
              <CheckCircle size={20} color={GREENTITLE} weight="bold" />
            ) : (
              <CustomText type="small" color={REDTITLE} ml="5px">
                * 직책을 선택해주세요.
              </CustomText>
            )}
          </Grid>
          <DropDownListComponentGridBox>
            <DropDownListComponentBox
              dataSource={jobTitleData}
              fields={titleFields}
              name="jobTitle"
              value={employeeData.jobPosition?.id || ""}
              onChange={(e: any) => {
                onChange(e, "dropdown");
              }}
              placeholder="직책"
              onKeyDown={handleOnKeyDown}
              autoComplete="new-password"
            />
          </DropDownListComponentGridBox>
        </Grid>
        <Grid item xs={12}>
          <Grid container item xs={12} alignItems="center">
            <Grid mr="5px">직위</Grid>
            {employeeData.jobPosition?.id ? (
              <CheckCircle size={20} color={GREENTITLE} weight="bold" />
            ) : (
              <CustomText type="small" color={REDTITLE}>
                * 직위를 선택해주세요.
              </CustomText>
            )}
          </Grid>

          <DropDownListComponentGridBox>
            <DropDownListComponentBox
              dataSource={jobPositionData}
              fields={positionFields}
              name="jobPosition"
              value={employeeData.jobPosition?.id || ""}
              onChange={(e: any) => {
                onChange(e, "dropdown");
              }}
              placeholder="직위"
              onKeyDown={handleOnKeyDown}
              autoComplete="new-password"
            />
          </DropDownListComponentGridBox>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={12} sm={6} alignItems="center">
            <Grid mr="5px">활성화 여부</Grid>
            <DropDownListComponentGridBox>
              <DropDownListComponentBox
                fields={enabledFields}
                dataSource={ENABLED_TYPE}
                name="enabled"
                showClearButton={false}
                onChange={onChangeEnabled}
                value={employeeData.enabled}
                placeholder="활성화 여부"
                itemTemplate={itemTemplate}
                valueTemplate={valueTemplate}
                autoComplete="new-password"
              />
            </DropDownListComponentGridBox>
          </Grid>
          <Grid container item xs={12} sm={6} alignItems="center">
            <Grid mr="5px">영업사원 여부</Grid>
            <DropDownListComponentGridBox>
              <DropDownListComponentBox
                fields={enabledFields}
                dataSource={ENABLED_TYPE}
                name="isSales"
                showClearButton={false}
                onChange={onChangeEnabled}
                value={employeeData.isSales}
                placeholder="영업사원 여부"
                itemTemplate={itemTemplate}
                valueTemplate={valueTemplate}
                autoComplete="new-password"
              />
            </DropDownListComponentGridBox>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent="right" mt="20px">
        {validation(employeeData, "check") ? (
          <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
        ) : (
          <CustomButtonDisabled
            container
            justifyContent="center"
            alignItems="center"
            onClick={onSave}
          >
            저장
          </CustomButtonDisabled>
        )}
      </Grid>
    </>
  );
}

export default AdminUserModal;
