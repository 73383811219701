import { Grid, IconButton, Tooltip } from "@mui/material";
import {
  ByCaseCommentBox,
  ByCaseContentEmptyBox,
} from "../../../../styles/BycaseStyle";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import {
  TableBorderBox,
  TableCardBox,
  TableContentBox,
  TableContentNoPaddingBox,
  TableContentTextareaBox,
  TableTitleBox,
  TextareaBox,
  TextareaLineBox,
  TextareaPreLine,
} from "../../../../styles/theme";
import {
  CommentInfo,
  MeetingNoteInfo,
} from "../../../../system/types/MeetingNote";
import {
  GRAYPALEBORDER,
  GRAYPALECONTENTS,
  GREENCONTENTS,
  REDTITLE,
} from "../../../../styles/Color";
import { MeetingNoteApi } from "../../../../system/api/MeetingNoteApi";
import { ErrorHandler } from "../../../../system/ApiService";
import AlertModal from "../../../../components/Common/AlertModal";
import Swal from "sweetalert2";
import {
  InitCommentInfo,
  InitMeetingNoteInfo,
} from "../../../../system/types/initObject";
import { isoDateFormatter } from "../../../../components/Common/IsoDateFormatter";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import CommentList from "./CommentList";
import CommentInputBox from "./CommentInputBox";
import { CommentApi } from "../../../../system/api/CommentApi";
import {
  Pc,
  TabletMobile,
} from "../../../../components/Common/Responseive/ResponsiveScreen";
import { CustomText } from "../../../../styles/CustomText";
import { CustomButtonGreen } from "../../../../styles/ButtonStyle";
import CommentMobileList from "./CommentMobileList";
import { useRecoilValue } from "recoil";
import { MyInfoState } from "../../../../system/atoms";

interface ByCaseMeetingNoteListProps {
  detailId: number;
  meetingNoteList: MeetingNoteInfo[];
  getMeetingNoteList: () => void;
}

function ByCaseMeetingNoteList({
  detailId,
  meetingNoteList,
  getMeetingNoteList,
}: ByCaseMeetingNoteListProps) {
  const [meetingNoteData, setMeetingNoteData] =
    useState<MeetingNoteInfo>(InitMeetingNoteInfo);
  const [replyData, setReplyData] = useState<CommentInfo>({
    ...InitCommentInfo,
    commentType: "meetingNote",
  });
  const myInfoData = useRecoilValue(MyInfoState);
  const [openReply, setOpenReply] = useState<boolean>(false);
  const [openMeetingNoteReply, setOpenMeetingNoteReply] =
    useState<boolean>(false);
  const [openEditReply, setOpenEditReply] = useState<boolean>(false);
  const [replyContent, setReplyContent] = useState("");
  const onChangeMeetingNote = (dt: MeetingNoteInfo) => {
    if (dt.id) {
      setMeetingNoteData({
        id: dt.id,
        content: dt.content,
        nextAction: dt.nextAction,
        isChecked: dt.isChecked,
        attachmentFiles: dt.attachmentFiles,
        meetingDate: dt.meetingDate,
        followUpDate: dt.followUpDate,
        customerId: detailId,
      });
    }
  };

  const onChange = (args: any, type: string) => {
    if (type === "input") {
      setMeetingNoteData({
        ...meetingNoteData,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else if (type === "dropdown") {
      setMeetingNoteData({
        ...meetingNoteData,
        [(args.target as HTMLInputElement).name]: args.target.itemData,
      });
    } else if (type === "date") {
      setMeetingNoteData({
        ...meetingNoteData,
        [(args.target as HTMLInputElement).name]: isoDateFormatter(
          new Date(args.target.value)
        ),
      });
    }
  };

  var submitcheck = false;

  const onDeleteMeetingNote = (id: number | undefined) => {
    if (id && submitcheck === false) {
      submitcheck = true;

      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 미팅노트가 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          MeetingNoteApi.deleteMeetingNote(id)
            .then(() => {
              AlertModal("success", "선택하신 미팅노트가 삭제되었습니다.");
              getMeetingNoteList();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              submitcheck = false;
            });
        }
      });
    }
  };

  const onSave = () => {
    submitcheck = true;
    if (submitcheck === true) {
      MeetingNoteApi.updateMeetingNote(meetingNoteData)
        .then(() => {
          AlertModal("success", "미팅노트가 수정되었습니다.");
          setMeetingNoteData(InitMeetingNoteInfo);
          getMeetingNoteList();
          submitcheck = false;
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
          submitcheck = false;
        });
    }
  };

  // comment
  const onAddReply = (dt: MeetingNoteInfo) => {
    setOpenMeetingNoteReply(true);
    if (dt.id) {
      setReplyData({ ...replyData, articleId: dt.id });
    }
  };

  const onChangeComment = (dt: CommentInfo, type: string) => {
    if (type === "add") {
      if (openReply) {
        setOpenReply(false);
      } else {
        setOpenReply(true);
        setReplyData(dt);
        setReplyContent("");
        setOpenEditReply(false);
      }
    } else {
      if (openEditReply) {
        setOpenEditReply(false);
      } else {
        setOpenReply(false);
        setOpenEditReply(true);
        setReplyContent(dt.content);
        setReplyData(dt);
      }
    }
  };

  function onSaveReply() {
    submitcheck = true;
    if (replyData.id) {
      CommentApi.updateComment({ ...replyData, content: replyContent })
        .then(() => {
          AlertModal("success", "댓글이 수정되었습니다.");
          setReplyData({
            ...InitCommentInfo,
            commentType: "meetingNote",
          });
          getMeetingNoteList();
          submitcheck = false;
          setOpenReply(false);
          setOpenMeetingNoteReply(false);
          setOpenEditReply(false);
          setReplyContent("");
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
          submitcheck = false;
        });
    } else {
      CommentApi.createComment({ ...replyData, content: replyContent })
        .then(() => {
          AlertModal("success", "댓글이 생성되었습니다.");
          setReplyData({
            ...InitCommentInfo,
            commentType: "meetingNote",
          });
          getMeetingNoteList();
          submitcheck = false;
          setOpenReply(false);
          setOpenMeetingNoteReply(false);
          setOpenEditReply(false);
          setReplyContent("");
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
          submitcheck = false;
        });
    }
  }

  return (
    <>
      <Pc>
        <TableBorderBox container mt="10px">
          <Grid container>
            <TableTitleBox item xs={6} sm={2} md={1}>
              날짜
            </TableTitleBox>
            <TableTitleBox item xs={6} sm={2} md={1}>
              담당자
            </TableTitleBox>
            <TableTitleBox item xs={12} sm={8} md={5}>
              내용
            </TableTitleBox>
            <TableTitleBox item xs={12} sm={8} md={3}>
              NextAction 내용
            </TableTitleBox>
            <TableTitleBox item xs={6} sm={2} md={1}>
              F/U 일자
            </TableTitleBox>
            <TableTitleBox item xs={6} sm={2} md={1}>
              기타
            </TableTitleBox>
          </Grid>
          {meetingNoteList.length < 1 && (
            <Grid
              container
              padding="20px"
              justifyContent="center"
              style={{
                borderBottom: `1px solid ${GRAYPALEBORDER}`,
                borderRight: `1px solid ${GRAYPALEBORDER}`,
              }}
            >
              작성된 미팅 노트가 없습니다.
            </Grid>
          )}

          {meetingNoteList.map((child: MeetingNoteInfo, index: number) => (
            <Grid container key={index}>
              {meetingNoteData.id && meetingNoteData.id === child.id ? (
                <TableContentNoPaddingBox
                  item
                  xs={6}
                  sm={2}
                  md={1}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <DatePickerComponent
                    name="meetingDate"
                    format="yyyy-MM-dd"
                    locale="ko"
                    value={new Date(meetingNoteData.meetingDate)}
                    onChange={(e: any) => {
                      onChange(e, "date");
                    }}
                    placeholder="날짜"
                  />
                </TableContentNoPaddingBox>
              ) : (
                <TableContentBox
                  item
                  xs={6}
                  sm={2}
                  md={1}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid> {child.meetingDate}</Grid>
                  {child.createdAt &&
                    isoDateFormatter(new Date(child.meetingDate)) !==
                      isoDateFormatter(new Date(child.createdAt)) && (
                      <CustomText type="small" color={GRAYPALECONTENTS}>
                        작성일 {isoDateFormatter(new Date(child.createdAt))}
                      </CustomText>
                    )}
                </TableContentBox>
              )}

              <TableContentBox
                item
                xs={6}
                sm={2}
                md={1}
                container
                justifyContent="center"
                alignItems="center"
              >
                {child?.createBy?.name}
              </TableContentBox>

              {meetingNoteData.id && meetingNoteData.id === child.id ? (
                <TableContentBox item xs={12} sm={8} md={5}>
                  <TextareaBox
                    name="content"
                    onChange={(e: any) => {
                      onChange(e, "input");
                    }}
                    value={meetingNoteData.content || ""}
                    placeholder="내용을 작성해 주세요."
                  />
                </TableContentBox>
              ) : (
                <TableContentTextareaBox
                  item
                  xs={12}
                  sm={8}
                  md={5}
                  container
                  alignItems="center"
                >
                  {child.content}
                </TableContentTextareaBox>
              )}
              {meetingNoteData.id && meetingNoteData.id === child.id ? (
                <TableContentBox
                  item
                  xs={12}
                  sm={8}
                  md={3}
                  container
                  overflow="scroll"
                >
                  <TextareaBox
                    name="nextAction"
                    onChange={(e: any) => {
                      onChange(e, "input");
                    }}
                    value={meetingNoteData.nextAction || ""}
                    placeholder="NextAction을 작성해 주세요."
                  />
                </TableContentBox>
              ) : (
                <TableContentTextareaBox
                  item
                  xs={12}
                  sm={8}
                  md={3}
                  container
                  alignItems="center"
                >
                  {child.nextAction}
                </TableContentTextareaBox>
              )}
              {meetingNoteData.id && meetingNoteData.id === child.id ? (
                <TableContentNoPaddingBox
                  item
                  xs={6}
                  sm={2}
                  md={1}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <DatePickerComponent
                    name="followUpDate"
                    format="yyyy-MM-dd"
                    locale="ko"
                    value={
                      meetingNoteData.followUpDate
                        ? new Date(meetingNoteData.followUpDate)
                        : undefined
                    }
                    onChange={(e: any) => {
                      onChange(e, "date");
                    }}
                    placeholder="F/U 일자"
                  />
                </TableContentNoPaddingBox>
              ) : (
                <TableContentBox
                  item
                  xs={6}
                  sm={2}
                  md={1}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  {child.followUpDate}
                </TableContentBox>
              )}
              {meetingNoteData.id && meetingNoteData.id === child.id ? (
                <ByCaseContentEmptyBox
                  container
                  item
                  xs={6}
                  sm={2}
                  md={1}
                  justifyContent="center"
                  alignItems="center"
                  onClick={onSave}
                >
                  저장
                </ByCaseContentEmptyBox>
              ) : (
                <TableContentBox
                  container
                  item
                  xs={6}
                  sm={2}
                  md={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Tooltip title="답변">
                    <IconButton onClick={() => onAddReply(child)}>
                      <ChatBubbleOutlineIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  {myInfoData.id === child.createBy?.id && (
                    <>
                      <Tooltip title="수정">
                        <IconButton onClick={() => onChangeMeetingNote(child)}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="삭제">
                        <IconButton
                          onClick={() => onDeleteMeetingNote(child.id)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </TableContentBox>
              )}
              {openMeetingNoteReply && replyData.articleId === child.id && (
                <CommentInputBox
                  onSaveReply={onSaveReply}
                  replyContent={replyContent}
                  setReplyContent={setReplyContent}
                />
              )}
              {child.comments &&
                child.comments.map((commentDt: any, index: number) => (
                  <CommentList
                    key={"comments" + index}
                    commentData={commentDt}
                    replyData={replyData}
                    replyContent={replyContent}
                    openReply={openReply}
                    openEditReply={openEditReply}
                    onChangeComment={onChangeComment}
                    onSaveReply={onSaveReply}
                    setReplyContent={setReplyContent}
                    commentType="meetingNote"
                    getMeetingNoteList={getMeetingNoteList}
                  />
                ))}
            </Grid>
          ))}
        </TableBorderBox>
      </Pc>
      <TabletMobile>
        <Grid container>
          {meetingNoteList.length < 1 && (
            <Grid
              container
              margin="5px 0px"
              padding="20px"
              justifyContent="center"
              style={{ border: `1px solid ${GRAYPALEBORDER}` }}
            >
              작성된 미팅 노트가 없습니다.
            </Grid>
          )}

          {meetingNoteList.map((child: MeetingNoteInfo, index: number) => (
            <Grid container key={index}>
              <TableCardBox item xs={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  marginBottom="5px"
                >
                  <Grid container item xs={12}>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <CustomText bold600 type="subtitle" mr="10px">
                        날짜
                      </CustomText>
                      {child.createdAt &&
                        isoDateFormatter(new Date(child.meetingDate)) !==
                          isoDateFormatter(new Date(child.createdAt)) && (
                          <CustomText
                            type="small"
                            color={GRAYPALECONTENTS}
                            marginLeft="5px"
                          >
                            작성일 {isoDateFormatter(new Date(child.createdAt))}
                          </CustomText>
                        )}
                    </Grid>

                    {meetingNoteData.id && meetingNoteData.id === child.id ? (
                      <Grid>
                        <DatePickerComponent
                          name="meetingDate"
                          format="yyyy-MM-dd"
                          locale="ko"
                          value={new Date(meetingNoteData.meetingDate)}
                          onChange={(e: any) => {
                            onChange(e, "date");
                          }}
                          placeholder="날짜"
                        />
                      </Grid>
                    ) : (
                      <Grid>{child.meetingDate}</Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} margin="5px 0px">
                  <CustomText bold600 type="subtitle" mr="10px">
                    내용
                  </CustomText>
                  {meetingNoteData.id && meetingNoteData.id === child.id ? (
                    <Grid item xs={12}>
                      <TextareaLineBox
                        name="content"
                        onChange={(e: any) => {
                          onChange(e, "input");
                        }}
                        value={meetingNoteData.content || ""}
                        placeholder="내용을 작성해 주세요."
                      />
                    </Grid>
                  ) : (
                    <TextareaPreLine item xs={12}>
                      {child.content}
                    </TextareaPreLine>
                  )}
                </Grid>
                <Grid item xs={12} margin="5px 0px">
                  <CustomText bold600 type="subtitle" mr="10px">
                    NextAction
                  </CustomText>

                  {meetingNoteData.id && meetingNoteData.id === child.id ? (
                    <Grid item xs={12}>
                      <TextareaLineBox
                        name="nextAction"
                        onChange={(e: any) => {
                          onChange(e, "input");
                        }}
                        value={meetingNoteData.nextAction || ""}
                        placeholder="NextAction을 작성해 주세요."
                      />
                    </Grid>
                  ) : (
                    <TextareaPreLine item xs={12}>
                      {child.nextAction}
                    </TextareaPreLine>
                  )}
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="space-between"
                  margin="5px 0px"
                >
                  <Grid container item xs={12}>
                    <CustomText bold600 type="subtitle" mr="10px">
                      F/U 일자
                    </CustomText>
                    {meetingNoteData.id && meetingNoteData.id === child.id ? (
                      <Grid>
                        <DatePickerComponent
                          name="followUpDate"
                          format="yyyy-MM-dd"
                          locale="ko"
                          value={
                            meetingNoteData.followUpDate
                              ? new Date(meetingNoteData.followUpDate)
                              : undefined
                          }
                          onChange={(e: any) => {
                            onChange(e, "date");
                          }}
                          placeholder="F/U 일자"
                        />
                      </Grid>
                    ) : (
                      <Grid> {child.followUpDate}</Grid>
                    )}
                  </Grid>
                  <Grid container item xs={12} margin="5px 0px">
                    <CustomText bold600 type="subtitle" mr="10px">
                      담당자
                    </CustomText>
                    <Grid container item xs={6}>
                      {child?.createBy?.name}
                    </Grid>
                  </Grid>
                </Grid>

                {meetingNoteData.id && meetingNoteData.id === child.id ? (
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="center"
                    marginTop="10px"
                  >
                    <CustomButtonGreen onClick={onSave}>
                      {" "}
                      저장
                    </CustomButtonGreen>
                  </Grid>
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                    marginTop="5px"
                  >
                    <Tooltip title="답변">
                      <IconButton onClick={() => onAddReply(child)}>
                        <ChatBubbleOutlineIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="수정">
                      <IconButton onClick={() => onChangeMeetingNote(child)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="삭제">
                      <IconButton onClick={() => onDeleteMeetingNote(child.id)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}

                {openMeetingNoteReply && replyData.articleId === child.id && (
                  <Grid container margin="10px 0px">
                    <CommentInputBox
                      onSaveReply={onSaveReply}
                      replyContent={replyContent}
                      setReplyContent={setReplyContent}
                    />
                  </Grid>
                )}

                {child.comments &&
                  child.comments.map((commentDt: any, index: number) => (
                    <ByCaseCommentBox key={"comments" + index}>
                      <Grid container justifyContent="space-between">
                        <CustomText bold600 type="subtitle" mr="10px">
                          답변
                        </CustomText>
                      </Grid>
                      <CommentMobileList
                        commentData={commentDt}
                        replyData={replyData}
                        replyContent={replyContent}
                        openReply={openReply}
                        openEditReply={openEditReply}
                        onChangeComment={onChangeComment}
                        onSaveReply={onSaveReply}
                        setReplyContent={setReplyContent}
                        commentType="meetingNote"
                      />
                    </ByCaseCommentBox>
                  ))}
              </TableCardBox>
            </Grid>
          ))}
        </Grid>
      </TabletMobile>
      <></>
    </>
  );
}

export default ByCaseMeetingNoteList;
