import {
  Box,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ByCaseMainTitleBox } from "../../../../styles/BycaseStyle";
import {
  DropDownListComponentBox,
  InputBox,
  InputSmallBox,
  ModalStyle,
  PointerItem,
  TableBorderBox,
  TableContentBox,
  TableContentDropdownBox,
  TableContentMoneyBox,
  TableTitleBox,
} from "../../../../styles/theme";
import {
  CustomButtonDisabled,
  CustomButtonGreen,
  CustomButtonRed,
} from "../../../../styles/ButtonStyle";
import {
  LeadCustomerInfo,
  LeadOrdersInfo,
} from "../../../../system/types/Customer";
import { InitLeadOrdersInfo } from "../../../../system/types/initObject";
import { useCallback, useEffect, useState } from "react";
import { ProductApi } from "../../../../system/api/ProductApi";
import { ErrorHandler } from "../../../../system/ApiService";
import AlertModal from "../../../../components/Common/AlertModal";
import { CustomText } from "../../../../styles/CustomText";
import { X } from "@phosphor-icons/react";
import { L10n } from "@syncfusion/ej2-base";
import { MessageComponent } from "@syncfusion/ej2-react-notifications";
import { FilteringEventArgs } from "@syncfusion/ej2-react-dropdowns";
import { Query } from "@syncfusion/ej2-data";
interface ByCaseProductInfoProps {
  orderData: LeadOrdersInfo[];
  setOrderData: (data: LeadOrdersInfo[]) => void;
  data: LeadCustomerInfo;
}

L10n.load({
  ko: {
    dropdowns: { noRecordsTemplate: "해당 데이터가 없습니다." },
  },
});

function ByCaseProductInfo({
  orderData,
  setOrderData,
  data,
}: ByCaseProductInfoProps) {
  const [productFamilyList, setProductFamilyList] = useState<string[]>([]);
  const [productList, setProductList] = useState([]);
  const [productPriceList, setProductPriceList] = useState<
    { name: string; title: string; price: number }[]
  >([]);
  const [productPrice, setProductPrice] = useState(0);
  const [indexNumber, setIndexNumber] = useState(0);
  const fetchProductFamily = useCallback(() => {
    ProductApi.searchProductFamily()
      .then((res) => {
        setProductFamilyList(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);
  const getProduct = useCallback((searchText: string) => {
    ProductApi.searchProduct(searchText)
      .then((res) => {
        setProductList(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const getPrice = useCallback((productId: number) => {
    ProductApi.searchProductPrice(productId)
      .then((res) => {
        var prices_list = [];
        if (res.data[0]["retailPrice"]) {
          prices_list.push({
            name: "retailPrice",
            title: "권장소비자가",
            price: res.data[0]["retailPrice"],
          });
        }
        if (res.data[0]["wholesalePrice"]) {
          prices_list.push({
            name: "wholesalePrice",
            title: "딜러가",
            price: res.data[0]["wholesalePrice"],
          });
        }
        if (res.data[0]["partnerPrice"]) {
          prices_list.push({
            name: "partnerPrice",
            title: "파트너가",
            price: res.data[0]["partnerPrice"],
          });
        }
        if (prices_list) {
          setProductPriceList(prices_list);
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const productFields: Object = {
    dataSource: productList,
    value: "id",
    text: "name",
    child: "children",
  };

  useEffect(() => {
    fetchProductFamily();
  }, [fetchProductFamily]);

  const addProduct = () => {
    setOrderData(orderData.concat(InitLeadOrdersInfo));
  };

  const deleteProduct = () => {
    setOrderData(orderData.slice(0, -1));
  };

  const onChange = (args: any, index: number, type = "") => {
    const updatedOrderData = [...orderData];
    if (type === "number") {
      updatedOrderData[index] = {
        ...updatedOrderData[index],
        [(args.target as HTMLInputElement).name]: Number(args.target.value),
      };
    } else if (type === "limitNumber") {
      const inputValue = Number(args.target.value);
      if (!isNaN(inputValue) && inputValue <= 99) {
        updatedOrderData[index] = {
          ...updatedOrderData[index],
          [(args.target as HTMLInputElement).name]: inputValue,
        };
      }
    } else {
      updatedOrderData[index] = {
        ...updatedOrderData[index],
        [(args.target as HTMLInputElement).name]: args.target.value,
      };
    }

    setOrderData(updatedOrderData);
  };

  const onChangeProductFamily = (args: any, index: number) => {
    const updatedOrderData = [...orderData];
    const updatedOrderProductData = orderData[index].product;
    updatedOrderData[index] = {
      ...updatedOrderData[index],
      product: { ...updatedOrderProductData, family: args.target.value },
    };
    setOrderData(updatedOrderData);
  };
  const onChangeProduct = (args: any, index: number) => {
    const updatedOrderData = [...orderData];
    if (data.saleType && data.saleType?.id) {
      ProductApi.searchProductPrice(args.target.value)
        .then((res) => {
          const priceDt = res.data[0];
          if (data.saleType?.name === "직판") {
            updatedOrderData[index] = {
              ...updatedOrderData[index],
              product: args.target.itemData,
              price: priceDt.retailPrice ? priceDt.retailPrice : 0,
            };
            setOrderData(updatedOrderData);
          } else if (
            data.saleType?.name === "딜러" ||
            data.saleType?.name === "프랜차이즈"
          ) {
            updatedOrderData[index] = {
              ...updatedOrderData[index],
              product: args.target.itemData,
              price: priceDt.wholesalePrice ? priceDt.wholesalePrice : 0,
            };
            setOrderData(updatedOrderData);
          } else if (data.saleType?.name === "파트너사") {
            updatedOrderData[index] = {
              ...updatedOrderData[index],
              product: args.target.itemData,
              price: priceDt.partnerPrice ? priceDt.partnerPrice : 0,
            };
            setOrderData(updatedOrderData);
          }
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    } else {
      updatedOrderData[index] = {
        ...updatedOrderData[index],
        product: args.target.itemData,
      };
      setOrderData(updatedOrderData);
    }
  };

  const onChangePrice = (selectedPrice: number) => {
    if (value === "write") {
      const updatedOrderData = [...orderData];
      updatedOrderData[indexNumber] = {
        ...updatedOrderData[indexNumber],
        price: Number(selectedPrice),
        currency: "KRW",
      };
      setOrderData(updatedOrderData);
      handleClose();
    } else {
      const valueDataObject = productPriceList.find(
        (item) => item.title === value
      );
      const valueData = valueDataObject ? valueDataObject.price : 0;
      const updatedOrderData = [...orderData];
      updatedOrderData[indexNumber] = {
        ...updatedOrderData[indexNumber],
        price: Number(valueData),
        currency: "KRW",
      };
      setOrderData(updatedOrderData);
      handleClose();
    }
  };

  const onChangeModalPrice = (args: any) => {
    setProductPrice(args.target.value);
  };

  // 가격 모달
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const handleClose = () => {
    setOpen(false);
    setProductPrice(0);
  };
  const openModal = (
    productId: number | null | undefined,
    indexNum: number
  ) => {
    if (orderData[indexNum]?.product?.name) {
      setIndexNumber(indexNum);
      setOpen(true);
      if (productId) {
        getPrice(productId);
      }
    } else {
      AlertModal("check", "제품을 먼저 선택해주세요.");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  function addProductPrice(dt: LeadOrdersInfo) {
    const addResult = dt.quantity * dt.price * (100 - dt.discountRate) * 0.01;
    return addResult;
  }

  function addAllProductPrice(dtList: LeadOrdersInfo[]) {
    return dtList.reduce((total, item) => total + addProductPrice(item), 0);
  }

  const onAlertEmptyPrice = () => {
    AlertModal("check", "단가를 선택해주세요.");
  };

  const onFiltering = (args: FilteringEventArgs, queryData: any) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("name", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };

  return (
    <Grid container justifyContent="right" item xs={12} mt="28px" mb="25px">
      <Grid item xs={0} md={0.2}></Grid>
      <Grid container item xs={12} md={11.8} justifyContent="space-between">
        <ByCaseMainTitleBox container justifyContent="center" item xs={4.065}>
          제품 정보
        </ByCaseMainTitleBox>
        <Grid container item xs={7.935} justifyContent="right">
          <CustomButtonGreen
            onClick={addProduct}
            style={{ marginRight: "5px" }}
          >
            제품 추가
          </CustomButtonGreen>
          {orderData.length > 1 ? (
            <CustomButtonRed onClick={deleteProduct}>제품 삭제</CustomButtonRed>
          ) : (
            <CustomButtonDisabled
              container
              justifyContent="center"
              alignItems="center"
            >
              제품 삭제
            </CustomButtonDisabled>
          )}
        </Grid>
      </Grid>
      <Grid item xs={11.8}>
        <Grid container margin="0px 0px 5px 0px">
          <Grid item xs={0} md={0.3}></Grid>
          <Grid item xs={12}>
            <MessageComponent content="제품군과 제품명을 차례대로 선택하신 후, 단가를 클릭해주세요." />
          </Grid>
        </Grid>
        <TableBorderBox>
          <Grid container>
            <TableTitleBox item xs={2.5} md={2}>
              제품군
            </TableTitleBox>
            <TableTitleBox item xs={2.5} md={3}>
              제품명
            </TableTitleBox>
            <TableTitleBox item xs={1} md={1}>
              수량
            </TableTitleBox>

            <TableTitleBox item xs={3}>
              단가(VAT포함)
            </TableTitleBox>

            <TableTitleBox item xs={3}>
              합계(VAT포함)
            </TableTitleBox>
          </Grid>
          {orderData.map((child: LeadOrdersInfo, index: number) => (
            <Grid container key={index}>
              <Grid container>
                <TableContentDropdownBox
                  item
                  xs={2.5}
                  md={2}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <DropDownListComponentBox
                    popupWidth={120}
                    colorvalue={child.product?.family ? "#000000" : "#B2B4B8"}
                    locale="ko"
                    dataSource={productFamilyList}
                    name="family"
                    value={child.product?.family}
                    onChange={(e: any) => {
                      onChangeProductFamily(e, index);
                    }}
                    placeholder={
                      child.product?.id ? child.product?.family : "제품군"
                    }
                    autoComplete="new-password"
                  />
                </TableContentDropdownBox>
                <TableContentDropdownBox
                  item
                  xs={2.5}
                  md={3}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <DropDownListComponentBox
                    colorvalue={child.product?.name ? "#000000" : "#B2B4B8"}
                    locale="ko"
                    popupWidth={350}
                    dataSource={productList}
                    filtering={(args: any) => onFiltering(args, productList)}
                    allowFiltering={true}
                    beforeOpen={() => getProduct(child.product?.family)}
                    fields={productFields}
                    name="name"
                    value={child.product?.name}
                    onChange={(e: any) => {
                      onChangeProduct(e, index);
                    }}
                    placeholder={
                      child.product?.id ? child.product?.name : "제품명"
                    }
                    autoComplete="new-password"
                  />
                </TableContentDropdownBox>
                <TableContentBox
                  item
                  xs={1}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <InputSmallBox
                    style={{ textAlign: "center" }}
                    type="text"
                    name="quantity"
                    onChange={(e: any) => {
                      onChange(e, index, "number");
                    }}
                    value={child.quantity}
                    placeholder="수량"
                  />
                </TableContentBox>
                <TableContentMoneyBox
                  container
                  justifyContent="right"
                  alignItems="center"
                  item
                  xs={3}
                  onClick={() => openModal(child.product?.id, index)}
                  style={{ cursor: "pointer" }}
                >
                  {child.price?.toLocaleString()} 원
                </TableContentMoneyBox>
                <TableContentMoneyBox
                  item
                  xs={3}
                  container
                  justifyContent="right"
                  alignItems="center"
                >
                  {addProductPrice(child)?.toLocaleString()} 원
                </TableContentMoneyBox>
              </Grid>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={ModalStyle("27rem")}>
                  <Grid container>
                    <Grid container justifyContent="space-between" item xs={12}>
                      <CustomText type="title"> 단가 설정</CustomText>

                      <PointerItem onClick={handleClose}>
                        <X size={32} />
                      </PointerItem>
                    </Grid>
                    <Grid
                      container
                      mt="20px"
                      style={{
                        height: "240px",
                      }}
                    >
                      <RadioGroup value={value} onChange={handleChange}>
                        {productPriceList.map((priceDt) => {
                          return (
                            <FormControlLabel
                              key={priceDt.title}
                              value={priceDt.title}
                              control={<Radio color="default" />}
                              label={
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  style={{ width: "300px", marginTop: "-3px" }}
                                >
                                  <Grid
                                    item
                                    xs={6}
                                    justifyContent="center"
                                    style={{
                                      width: "200px",
                                      textAlign: "center",
                                    }}
                                  >
                                    [ {priceDt.title} ]
                                  </Grid>
                                  <Grid item xs={6} justifyItems="right">
                                    {priceDt.price.toLocaleString()} 원
                                  </Grid>
                                </Grid>
                              }
                            />
                          );
                        })}
                        <FormControlLabel
                          value="write"
                          control={<Radio color="default" />}
                          label={
                            <CustomText
                              type="subtitle"
                              bold400
                              marginTop="-3px"
                            >
                              직접 입력
                            </CustomText>
                          }
                        />
                      </RadioGroup>
                      {value === "write" && (
                        <Grid container alignItems="start" item xs={12}>
                          <InputBox
                            type="text"
                            onChange={(e: any) => {
                              onChangeModalPrice(e);
                            }}
                            value={productPrice}
                            placeholder="가격"
                            autoComplete="new-password"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} justifyContent="right" mt="20px">
                    {value === "" ||
                    (value === "write" && productPrice === 0) ? (
                      <CustomButtonGreen onClick={onAlertEmptyPrice}>
                        저장
                      </CustomButtonGreen>
                    ) : (
                      <CustomButtonGreen
                        onClick={() => onChangePrice(productPrice)}
                      >
                        저장
                      </CustomButtonGreen>
                    )}
                  </Grid>
                </Box>
              </Modal>
            </Grid>
          ))}

          <Grid container justifyContent="right" alignItems="center">
            <TableTitleBox item xs={4} md={5}>
              총 합계(VAT포함)
            </TableTitleBox>
            <TableContentBox
              container
              justifyContent="right"
              alignItems="center"
              item
              xs={8}
              md={7}
              style={{ paddingRight: "10px" }}
            >
              {addAllProductPrice(orderData).toLocaleString()} 원
            </TableContentBox>
          </Grid>
        </TableBorderBox>
      </Grid>
    </Grid>
  );
}

export default ByCaseProductInfo;
