import { Grid, styled } from "@mui/material";
import uuid from "react-uuid";
import { MajorContactTypeInfo } from "../../../system/types/Statistics";
import {
  TableMainContentBox,
  TableMainNumberContentBox,
  TableMainTitleBox,
  TableOverflow,
} from "../../../styles/StatisticsTableStyle";
import { CustomText } from "../../../styles/CustomText";

interface StatusByContactTypeTableProps {
  allContactTypeData: any;
  contactTypeName: string[];
}

function StatusByContactTypeTable({
  allContactTypeData,

  contactTypeName,
}: StatusByContactTypeTableProps) {
  return (
    <>
      <TableMainTitleBox container alignItems="center">
        <Grid item xs={12 / (contactTypeName.length + 2)}></Grid>

        <Grid item xs={12 / (contactTypeName.length + 2)}>
          <CustomText type="body" paddingLeft="5px">
            구분
          </CustomText>
        </Grid>
        {contactTypeName.map((contactTypedt: string) => (
          <TableOverflow
            item
            xs={12 / (contactTypeName.length + 2)}
            key={uuid()}
            container
            alignItems="center"
            justifyContent="right"
            pr="5px"
          >
            <CustomText type="body">{contactTypedt}</CustomText>
          </TableOverflow>
        ))}
      </TableMainTitleBox>

      {Object.keys(allContactTypeData).map((key: string, index: number) => (
        <Grid container alignItems="center" key={uuid()}>
          <ContactTypeMajorBox
            item
            container
            xs={12 / (contactTypeName.length + 2)}
            justifyContent="center"
            alignItems="center"
            style={{
              height: `${Object.keys(allContactTypeData[key]).length * 32}px`, // 높이 설정
              width: "100%",
            }}
          >
            {key}
          </ContactTypeMajorBox>
          <Grid
            item
            xs={
              (12 / (contactTypeName.length + 2)) * (contactTypeName.length + 1)
            }
            container
          >
            {Object.keys(allContactTypeData[key]).map(
              (item: string, index: number) => (
                <TableMainContentBox container key={uuid()} item>
                  <TableOverflow
                    item
                    xs={12 / (contactTypeName.length + 1)}
                    container
                    alignItems="center"
                    pl="5px"
                  >
                    <CustomText type="body"> {item}</CustomText>
                  </TableOverflow>
                  {allContactTypeData[key][item].map(
                    (contactTypeDT: MajorContactTypeInfo) => (
                      <TableMainNumberContentBox
                        item
                        xs={12 / (contactTypeName.length + 1)}
                        key={uuid()}
                      >
                        <CustomText type="body">
                          {contactTypeDT.total}
                        </CustomText>
                      </TableMainNumberContentBox>
                    )
                  )}
                </TableMainContentBox>
              )
            )}
          </Grid>
        </Grid>
      ))}
    </>
  );
}

export default StatusByContactTypeTable;

const ContactTypeMajorBox = styled(Grid)({
  borderLeft: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
});
