import { Grid, Tooltip } from "@mui/material";
import {
  DropDownListComponentBox,
  DropDownTreeComponentBox,
  InputSmallBox,
  TableBorderBox,
  TableContentDropdownBox,
  TableTitleBox,
} from "../styles/theme";
import { useEffect, useState } from "react";
import { ListApi } from "../system/api/ListApi";
import { ContactTypeListInfo, DropdownListInfo } from "../system/types/List";
import { ErrorHandler } from "../system/ApiService";
import AlertModal from "./Common/AlertModal";
import { isoDateFormatter } from "./Common/IsoDateFormatter";
import {
  GRAYPALEBACKGROUND1,
  GRAYPALEBORDER,
  GRAYPALEDISABLED,
  GREENTITLE,
  ORANGEDISABLED,
  REDTITLE,
} from "../styles/Color";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { ProductApi } from "../system/api/ProductApi";
import { GroupInfo } from "../system/types/Group";
import { GroupApi } from "../system/api/GroupApi";
import { EmployeeTableInfo } from "../system/types/Employee";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import CircleIcon from "@mui/icons-material/Circle";
import { AddressApi } from "../system/api/AddressApi";
import { CustomText } from "../styles/CustomText";
import { Query } from "@syncfusion/ej2-data";
import { FilteringEventArgs } from "@syncfusion/ej2-react-dropdowns";
import { L10n } from "@syncfusion/ej2-base";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { useRecoilState, useRecoilValue } from "recoil";
import { AdminState, ListTypeState, SearchDataAtom } from "../system/atoms";
import { SearchProductDataInfo } from "../system/types/Product";

interface ListSearchBarProps {
  searchType: string;
  setPageNumber: (num: number) => void;
  groupData: GroupInfo[];
  managedGroupData?: GroupInfo[];
}

L10n.load({
  ko: {
    dropdowns: { noRecordsTemplate: "해당 데이터가 없습니다." },
  },
});

function ListSearchBar({
  searchType,
  setPageNumber,
  groupData,
  managedGroupData,
}: ListSearchBarProps) {
  const [searchWord, setSearchWord] = useState("");
  const adminValue = useRecoilValue(AdminState);
  const [searchDt, setSearchDt] = useRecoilState(SearchDataAtom);
  const listType = useRecoilValue(ListTypeState);
  const onChange = (args: any, type: string) => {
    if (type === "dropdown") {
      setSearchDt({
        ...searchDt,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else if (type === "dropdownStage") {
      setSearchDt({
        ...searchDt,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else if (type === "dropdownStageSeller") {
      setSearchDt({
        ...searchDt,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else if (type === "date") {
      setSearchDt({
        ...searchDt,
        [(args.target as HTMLInputElement).name]: args.target.value
          ? isoDateFormatter(new Date(args.target.value))
          : null,
      });
    }
    setPageNumber(0);
  };

  useEffect(() => {
    if (
      !adminValue &&
      searchType !== "lead" &&
      searchType !== "my" &&
      managedGroupData &&
      managedGroupData.length > 0 &&
      managedGroupData[0]?.id
    ) {
      GroupApi.findGroupEmployee(managedGroupData[0]?.id)
        .then((resEmp) => {
          setEmployeeList(resEmp.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [adminValue, managedGroupData, searchType]);

  useEffect(() => {
    if (
      adminValue &&
      searchType !== "lead" &&
      searchType !== "my" &&
      searchDt?.groupId
    ) {
      GroupApi.findGroupEmployee(Number(searchDt.groupId))
        .then((resEmp) => {
          setEmployeeList(resEmp.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [searchDt?.groupId, adminValue, searchType]);

  const dataFields: Object = {
    value: "id",
    text: "name",
  };

  const onChangePlaceName = (args: any) => {
    setSearchWord(args.target.value);
  };

  const onClickPlaceName = () => {
    setSearchDt({
      ...searchDt,
      placeName: searchWord,
    });
  };

  const handleOnKeyDownPassword = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onClickPlaceName();
    }
  };

  const onChangeStatus = (type: string | null) => {
    setSearchDt({ ...searchDt, status: type });
  };

  // 주소
  const [siDoData, setSiDoData] = useState<string[]>([]);
  const [gunGuData, setGunguData] = useState<string[]>([]);

  useEffect(() => {
    AddressApi.getAddressDistrictInfo("시도")
      .then((res) => {
        setSiDoData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
    AddressApi.getAddressDistrictInfo("구군")
      .then((res) => {
        setGunguData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const onChangeDistrictAddress = (args: any, type: string) => {
    if (type === "시도") {
      setSearchDt({ ...searchDt, siDo: args.target.value });
      AddressApi.getAddressDistrictInfo("구군", args.target.value)
        .then((res) => {
          setGunguData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    } else {
      setSearchDt({ ...searchDt, gunGu: args.target.value });
    }
  };

  // 업종

  const [IndustryClassificationData, setIndustryClassificationData] = useState<
    DropdownListInfo[]
  >([]);
  useEffect(() => {
    ListApi.getDropdownList("업종구분")
      .then((res) => {
        setIndustryClassificationData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);
  // 과/팀/센터
  const [classTeamCenterData, setClassTeamCenterData] = useState<
    DropdownListInfo[]
  >([]);
  useEffect(() => {
    ListApi.getDropdownList("과팀센터")
      .then((res) => {
        setClassTeamCenterData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 영업방식
  const [contactTypeData, setContactTypeData] = useState<ContactTypeListInfo[]>(
    []
  );
  const contactTypeDataFields: Object = {
    groupBy: "major",
    value: "id",
    text: "sub",
  };
  useEffect(() => {
    ListApi.getContactTypeList()
      .then((res) => {
        setContactTypeData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 영업단계
  const [saleStageData, setSaleStageData] = useState<DropdownListInfo[]>([]);
  useEffect(() => {
    if (searchType === "my" || searchType === "myGroup") {
      ListApi.getDropdownList("영업단계")
        .then((res) => {
          setSaleStageData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    } else {
      ListApi.getDropdownList("영업단계")
        .then((res) => {
          setSaleStageData(
            res.data.filter(
              (item: DropdownListInfo) =>
                item.name === "1개월이내" ||
                item.name === "3개월이내" ||
                item.name === "6개월이내" ||
                item.name === "12개월이내" ||
                item.name === "장기F/U"
            )
          );
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [searchType]);

  // 제품군
  const [productFamilyList, setProductFamilyList] = useState<string[]>([]);
  const productFamilyListFields: Object = {
    dataSource: productFamilyList,
    value: "name",
    text: "name",
  };
  useEffect(() => {
    ProductApi.searchProductFamily()
      .then((res) => {
        setProductFamilyList(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 모델명
  const [productList, setProductList] = useState([]);
  const productListFields: Object = {
    dataSource: productList,
    value: "name",
    text: "name",
  };
  useEffect(() => {
    ProductApi.searchProduct(searchDt.productFamily)
      .then((res) => {
        setProductList(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [searchDt.productFamily]);

  // 지사

  let fields: Object = {
    dataSource: searchType === "lead" ? groupData : managedGroupData,
    value: "id",
    text: "name",
    child: "children",
  };

  const onChangeGroup = (args: any) => {
    setSearchDt({
      ...searchDt,
      groupId:
        args.value && args.value.length > 0 ? Number(args.value[0]) : null,
    });
  };

  // 영업담당자
  const [employeeList, setEmployeeList] = useState<EmployeeTableInfo[]>([]);
  const onFiltering = (args: FilteringEventArgs, queryData: any) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("name", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };
  const onFilteringType = (args: FilteringEventArgs, queryData: any) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("sub", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };

  return (
    <TableBorderBox container margin="10px 0px">
      <Grid container item xs={12} sm={6} md={3}>
        <TableTitleBox
          container
          alignItems="center"
          justifyContent="center"
          item
          xs={4}
        >
          상호
        </TableTitleBox>
        <TableContentBox
          container
          alignItems="center"
          justifyContent="space-between"
          item
          xs={8}
        >
          <Grid item xs={10}>
            <InputSmallBox
              type="text"
              name="placeName"
              onChange={onChangePlaceName}
              value={searchWord}
              placeholder="검색하실 상호를 입력해 주세요."
              onKeyDown={handleOnKeyDownPassword}
              autoComplete="new-password"
            />
          </Grid>
          <ButtonBox item xs={2} onClick={onClickPlaceName}>
            <Tooltip title="검색">
              <MagnifyingGlass size={20} />
            </Tooltip>
          </ButtonBox>
        </TableContentBox>
      </Grid>
      <Grid container item xs={12} sm={6} md={3}>
        <TableTitleBox
          item
          xs={4}
          container
          alignItems="center"
          justifyContent="center"
        >
          주소
        </TableTitleBox>
        <TableContentDropdownBox
          container
          alignItems="center"
          justifyContent="center"
          item
          xs={8}
        >
          <Grid item xs={6}>
            <DropDownListComponentBox
              popupWidth={200}
              showClearButton={true}
              dataSource={siDoData}
              fields={dataFields}
              onChange={(e: any) => {
                onChangeDistrictAddress(e, "시도");
              }}
              placeholder="시/도"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={6}>
            <DropDownListComponentBox
              popupWidth={200}
              showClearButton={true}
              dataSource={gunGuData}
              fields={dataFields}
              onChange={(e: any) => {
                onChangeDistrictAddress(e, "구군");
              }}
              placeholder="군/구"
              autoComplete="new-password"
            />
          </Grid>
        </TableContentDropdownBox>
      </Grid>
      <Grid container item xs={12} sm={6} md={3}>
        <TableTitleBox
          container
          alignItems="center"
          justifyContent="center"
          item
          xs={4}
        >
          구매 예상 시기
        </TableTitleBox>
        <TableContentDropdownBox
          container
          alignItems="center"
          justifyContent="center"
          item
          xs={8}
        >
          <Grid item xs={5.5}>
            <DatePickerComponent
              name="startEstimatedDate"
              placeholder="시작 날짜"
              onChange={(e: any) => onChange(e, "date")}
              format="yyyy-MM-dd"
              locale="ko"
              value={
                searchDt?.startEstimatedDate
                  ? new Date(searchDt.startEstimatedDate)
                  : undefined
              }
            />
          </Grid>
          <Grid container justifyContent="center" item xs={1}>
            ~
          </Grid>
          <Grid item xs={5.5}>
            <DatePickerComponent
              name="endEstimatedDate"
              placeholder="종료 날짜"
              onChange={(e: any) => onChange(e, "date")}
              format="yyyy-MM-dd"
              locale="ko"
              value={
                searchDt?.endEstimatedDate
                  ? new Date(searchDt.endEstimatedDate)
                  : undefined
              }
            />
          </Grid>
        </TableContentDropdownBox>
      </Grid>
      <Grid container item xs={12} sm={6} md={3}>
        <TableTitleBox
          container
          alignItems="center"
          justifyContent="center"
          item
          xs={4}
        >
          {searchType === "lead" ||
          searchType === "new" ||
          searchType === "long-term" ? (
            <CustomText type="subtitle" color={GRAYPALEDISABLED} bold300>
              상태
            </CustomText>
          ) : (
            "상태"
          )}
        </TableTitleBox>
        {searchType === "lead" ||
        searchType === "new" ||
        searchType === "long-term" ? (
          <TableContentDropdownBox
            container
            justifyContent="space-evenly"
            alignItems="center"
            item
            xs={8}
          >
            <CircleIcon style={{ color: GRAYPALEDISABLED }} />
            <CircleIcon style={{ color: GRAYPALEDISABLED }} />
            <CircleIcon style={{ color: GRAYPALEDISABLED }} />
          </TableContentDropdownBox>
        ) : (
          <TableContentDropdownBox
            container
            justifyContent="space-evenly"
            alignItems="center"
            item
            xs={8}
          >
            <CircleIcon
              style={{ color: GREENTITLE, cursor: "pointer" }}
              onClick={() => onChangeStatus("green")}
            />
            <CircleIcon
              style={{ color: ORANGEDISABLED, cursor: "pointer" }}
              onClick={() => onChangeStatus("yellow")}
            />
            <CircleIcon
              style={{ color: REDTITLE, cursor: "pointer" }}
              onClick={() => onChangeStatus("red")}
            />
            {searchDt?.status && (
              <CloseIcon
                fontSize="small"
                style={{ cursor: "pointer" }}
                onClick={() => onChangeStatus(null)}
              />
            )}
          </TableContentDropdownBox>
        )}
      </Grid>
      <Grid container item xs={12} sm={6} md={3}>
        <TableTitleBox
          item
          xs={4}
          container
          alignItems="center"
          justifyContent="center"
        >
          업종
        </TableTitleBox>
        <TableContentDropdownBox
          container
          alignItems="center"
          justifyContent="center"
          item
          xs={8}
        >
          <DropDownListComponentBox
            locale="ko"
            showClearButton={true}
            dataSource={IndustryClassificationData}
            filtering={(args: any) =>
              onFiltering(args, IndustryClassificationData)
            }
            allowFiltering={true}
            fields={dataFields}
            name="businessTypeId"
            value={searchDt.businessTypeId}
            onChange={(e: any) => {
              onChange(e, "dropdownStage");
            }}
            placeholder="업종을 선택해주세요."
            autoComplete="new-password"
          />
        </TableContentDropdownBox>
      </Grid>{" "}
      <Grid container item xs={12} sm={6} md={3}>
        <TableTitleBox
          item
          xs={4}
          container
          alignItems="center"
          justifyContent="center"
        >
          과 / 팀 / 센터
        </TableTitleBox>
        <TableContentDropdownBox
          item
          container
          alignItems="center"
          justifyContent="center"
          xs={8}
        >
          <DropDownListComponentBox
            locale="ko"
            showClearButton={true}
            dataSource={classTeamCenterData}
            filtering={(args: any) => onFiltering(args, classTeamCenterData)}
            allowFiltering={true}
            fields={dataFields}
            name="departmentId"
            value={searchDt.departmentId}
            onChange={(e: any) => {
              onChange(e, "dropdownStage");
            }}
            placeholder="과/팀/센터를 선택해주세요."
            autoComplete="new-password"
          />
        </TableContentDropdownBox>
      </Grid>
      <Grid container item xs={12} sm={6} md={3}>
        <TableTitleBox
          item
          xs={4}
          container
          alignItems="center"
          justifyContent="center"
        >
          제품군
        </TableTitleBox>
        <TableContentDropdownBox
          item
          container
          alignItems="center"
          justifyContent="center"
          xs={8}
        >
          <DropDownListComponentBox
            locale="ko"
            showClearButton={true}
            dataSource={productFamilyList}
            fields={productFamilyListFields}
            name="productFamily"
            value={searchDt.productFamily}
            onChange={(e: any) => {
              onChange(e, "dropdown");
            }}
            placeholder="제품군을 선택해주세요."
            autoComplete="new-password"
          />
        </TableContentDropdownBox>
      </Grid>
      <Grid container item xs={12} sm={6} md={3}>
        <TableTitleBox
          item
          xs={4}
          container
          alignItems="center"
          justifyContent="center"
        >
          모델명
        </TableTitleBox>
        <TableContentDropdownBox
          item
          xs={8}
          container
          alignItems="center"
          justifyContent="center"
        >
          <DropDownListComponentBox
            locale="ko"
            showClearButton={true}
            popupWidth={350}
            filtering={(args: any) => onFiltering(args, productList)}
            allowFiltering={true}
            dataSource={productList}
            fields={productListFields}
            name="productName"
            value={searchDt.productName}
            onChange={(e: any) => {
              onChange(e, "dropdown");
            }}
            placeholder="모델명을 선택해주세요."
            autoComplete="new-password"
          />
        </TableContentDropdownBox>
      </Grid>
      <Grid container item xs={12} sm={6} md={3}>
        <TableTitleBox
          item
          xs={4}
          container
          alignItems="center"
          justifyContent="center"
        >
          {searchType === "lead" || (adminValue && searchType !== "my") ? (
            "지사"
          ) : (
            <CustomText type="subtitle" color={GRAYPALEDISABLED} bold300>
              지사
            </CustomText>
          )}
        </TableTitleBox>
        <TableContentDropdownBox
          item
          xs={8}
          container
          alignItems="center"
          justifyContent="center"
        >
          {(searchType === "lead" || (searchType !== "my" && adminValue)) && (
            <DropDownTreeComponentBox
              fields={fields}
              changeOnBlur={false}
              value={[String(searchDt.groupId)]}
              showClearButton={true}
              change={onChangeGroup}
              placeholder="지사를 선택해주세요."
              autoComplete="new-password"
            />
          )}
        </TableContentDropdownBox>
      </Grid>
      <Grid container item xs={12} sm={6} md={3}>
        <TableTitleBox
          item
          xs={4}
          container
          alignItems="center"
          justifyContent="center"
        >
          {searchType === "my" || searchType === "lead" ? (
            <CustomText type="subtitle" color={GRAYPALEDISABLED} bold300>
              영업 담당자
            </CustomText>
          ) : (
            "영업 담당자"
          )}
        </TableTitleBox>
        <TableContentDropdownBox
          item
          xs={8}
          container
          alignItems="center"
          justifyContent="center"
        >
          {searchType !== "my" && searchType !== "lead" && (
            <DropDownListComponentBox
              locale="ko"
              showClearButton={true}
              dataSource={employeeList}
              fields={dataFields}
              name="sellerId"
              value={searchDt.sellerId}
              onChange={(e: any) => {
                onChange(e, "dropdownStageSeller");
              }}
              placeholder={
                searchType === "lead"
                  ? "지사 먼저 선택해주세요."
                  : "영업 담당자를 선택해주세요."
              }
              autoComplete="new-password"
            />
          )}
        </TableContentDropdownBox>
      </Grid>
      <Grid container item xs={12} sm={6} md={3}>
        <TableTitleBox
          item
          xs={4}
          container
          alignItems="center"
          justifyContent="center"
        >
          {searchType === "new" ||
          searchType === "long-term" ||
          listType === "splitter" ? (
            <CustomText type="subtitle" color={GRAYPALEDISABLED} bold300>
              영업단계
            </CustomText>
          ) : (
            "영업단계"
          )}
        </TableTitleBox>
        <TableContentDropdownBox
          item
          xs={8}
          container
          alignItems="center"
          justifyContent="center"
        >
          {(searchType === "lead" ||
            searchType === "my" ||
            searchType === "myGroup") &&
            listType === "list" && (
              <DropDownListComponentBox
                locale="ko"
                showClearButton={true}
                dataSource={saleStageData}
                filtering={(args: any) => onFiltering(args, saleStageData)}
                allowFiltering={true}
                fields={dataFields}
                name="stepId"
                value={searchDt.stepId}
                onChange={(e: any) => {
                  onChange(e, "dropdownStage");
                }}
                placeholder="영업단계를 선택해주세요."
                autoComplete="new-password"
              />
            )}
        </TableContentDropdownBox>
      </Grid>
      <Grid container item xs={12} sm={6} md={3}>
        <TableTitleBox
          item
          xs={4}
          container
          alignItems="center"
          justifyContent="center"
        >
          영업방식
        </TableTitleBox>
        <TableContentDropdownBox
          item
          xs={8}
          container
          alignItems="center"
          justifyContent="center"
        >
          <DropDownListComponentBox
            locale="ko"
            showClearButton={true}
            dataSource={contactTypeData}
            filtering={(args: any) => onFilteringType(args, contactTypeData)}
            allowFiltering={true}
            fields={contactTypeDataFields}
            name="contactTypeId"
            value={searchDt.contactTypeId}
            onChange={(e: any) => {
              onChange(e, "dropdownStage");
            }}
            placeholder="영업방식을 선택해주세요."
            autoComplete="new-password"
          />
        </TableContentDropdownBox>
      </Grid>
    </TableBorderBox>
  );
}

export default ListSearchBar;

export const ButtonBox = styled(Grid)({
  cursor: "pointer",
  height: "35px",
  borderRadius: "8px",
  paddingTop: "3px",
  "&:hover": {
    backgroundColor: GRAYPALEBACKGROUND1,
    color: "#4B4F5A",
  },
});

export const TableContentBox = styled(Grid)({
  borderRight: `1px solid ${GRAYPALEBORDER}`,
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  textAlign: "center",
  minHeight: "40px",
  overflow: "auto",
  overflowWrap: "anywhere",
});
