import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import {
  BLUEBACKGROUND,
  BLUEBOX,
  GRAYPALEBACKGROUND1,
  GRAYPALEBACKGROUND2,
} from "./Color";

export const TableMainTitleBox = styled(Grid)({
  border: "1px solid #DCDFE3",
  backgroundColor: GRAYPALEBACKGROUND2,
  padding: "5px 0px",
});

export const TableMainContentBox = styled(Grid)({
  borderBottom: "1px solid #DCDFE3",
  borderLeft: "1px solid #DCDFE3",
  borderRight: "1px solid #DCDFE3",
  backgroundColor: GRAYPALEBACKGROUND1,
  padding: "5px 0px",

  "&:hover": { backgroundColor: BLUEBOX },
});

export const TableMainNumberContentBox = styled(Grid)({
  paddingRight: "10px",
  textAlign: "right",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});
export const TableLastContentBox = styled(Grid)({
  borderBottom: "1px solid #DCDFE3",
});

export const TableSubContentBox = styled(Grid)({
  borderBottom: "1px solid #DCDFE3",
  borderLeft: "1px solid #DCDFE3",
  borderRight: "1px solid #DCDFE3",
  padding: "5px 0px",
  "&:hover": { backgroundColor: BLUEBACKGROUND },
});

export const TableOverflow = styled(Grid)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});
