import { useEffect, useState } from "react";
import {
  ContactTypeStatisticsDataInfo,
  MajorContactTypeInfo,
} from "../../../system/types/Statistics";
import { StatisticsApi } from "../../../system/api/StatisticsApi";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../../components/Common/AlertModal";
import { Grid, styled } from "@mui/material";
import { CustomText } from "../../../styles/CustomText";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  LineSeries,
  Legend,
  Tooltip,
  Category,
  AxisModel,
  ColumnSeries,
  DataLabel,
  Highlight,
} from "@syncfusion/ej2-react-charts";
import { DropDownListComponentBox } from "../../../styles/theme";
import { MONTH_LIST } from "../../../system/Constants";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import StatusByContactTypeTable from "./StatusByContactTypeTable";
import TimelineIcon from "@mui/icons-material/Timeline";
import EqualizerIcon from "@mui/icons-material/Equalizer";

interface StatusByContactTypeProps {
  yearList: string[];
}

export default function StatusByContactType(
  this: any,
  { yearList }: StatusByContactTypeProps
) {
  const [contactTypeMajorData, setContactTypeMajorData] = useState<any>(null);
  const [allContactTypeData, setAllContactTypeData] = useState<any>(null);
  const [contactTypeData, setContactTypeData] = useState<
    ContactTypeStatisticsDataInfo[]
  >([]);
  const [contactTypeName, setContactTypeName] = useState<string[]>([]);
  const [searchYear, setSearchYear] = useState(
    new Date().getFullYear().toString()
  );
  const [searchMonth, setSearchMonth] = useState((1).toString());
  const [searchGap, setSearchGap] = useState("12");
  const [contactTypeList, setContactTypeList] = useState<string[]>([]);
  const [selectedContactType, setSelectedContactType] = useState("");
  const [tableOpen, setTableOpen] = useState(false);
  const [chartType, setChartType] = useState("line");

  const onChangeChartType = (type: string) => {
    setChartType(type);
  };

  const onChangeDate = (args: any, type: string) => {
    if (type === "year") {
      setSearchYear(args.target.value.toString());
    } else if (type === "month") {
      setSearchMonth(args.target.value.toString());
    } else if (type === "gap") {
      setSearchGap(args.target.value.toString());
    } else if (type === "contactTypeMajor") {
      setSelectedContactType(args.target.value);
    }
  };
  const onChangeTableOpen = () => {
    setTableOpen(!tableOpen);
  };

  useEffect(() => {
    StatisticsApi.contactTypeMajorStatistics(
      Number(searchYear),
      Number(searchMonth),
      Number(searchGap)
    )
      .then((res) => {
        const transformedData = Object.keys(res.data).map(
          (contactTypeName) => ({
            contactTypeName,
            data: res.data[contactTypeName].map(
              (item: MajorContactTypeInfo) => ({
                x: item.groupName,
                y: item.total,
              })
            ),
          })
        );
        setContactTypeMajorData(transformedData);
        const ContactTypeNameData = Object.keys(res.data);
        setContactTypeList(ContactTypeNameData);
        if (ContactTypeNameData.length > 0) {
          setSelectedContactType(ContactTypeNameData[0]);
          setContactTypeName(
            res.data[ContactTypeNameData[0]].map(
              (item: MajorContactTypeInfo) => item.groupName
            )
          );
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });

    StatisticsApi.contactTypeSubStatistics(
      Number(searchYear),
      Number(searchMonth),
      Number(searchGap)
    )
      .then((res) => {
        setAllContactTypeData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [searchYear, searchMonth, searchGap]);

  useEffect(() => {
    if (
      selectedContactType &&
      allContactTypeData &&
      allContactTypeData[selectedContactType]
    ) {
      const transformedData = Object.entries(
        allContactTypeData[selectedContactType]
      ).map(([contactTypeName, products]: [string, any]) => ({
        contactTypeName: contactTypeName,
        data: products.map((item: MajorContactTypeInfo) => ({
          x: item.groupName,
          y: item.total,
        })),
      }));
      setContactTypeData(transformedData);
    }
  }, [selectedContactType, allContactTypeData]);

  const primaryxAxis: AxisModel = {
    valueType: "Category",
  };

  const margin = { left: 25, right: 25, top: 0, bottom: 0 };

  const combineValues = (obj: any) => {
    return Object.values(obj).join("");
  };

  function yearValueTemplate(item: any) {
    return (
      <Grid container justifyContent="center">
        <CustomText type="subtitle" bold400>
          {combineValues(item)}년
        </CustomText>
      </Grid>
    );
  }

  function monthValueTemplate(item: any) {
    return (
      <Grid container justifyContent="center">
        <CustomText type="subtitle" bold400>
          {combineValues(item)}월 부터
        </CustomText>
      </Grid>
    );
  }

  function gapValueTemplate(item: any) {
    return (
      <Grid container justifyContent="center">
        <CustomText type="subtitle" bold400>
          {combineValues(item)}개월
        </CustomText>
      </Grid>
    );
  }
  function contactTypeValueTemplate(item: any) {
    return (
      <Grid container justifyContent="center">
        <CustomText type="subtitle" bold400>
          {combineValues(item)}
        </CustomText>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" mt="50px">
      <Grid container justifyContent="space-between" alignItems="center">
        {chartType === "line" ? (
          <Grid container alignItems="center" xs={12} sm={6}>
            <TimelineIcon fontSize="large" />
            <EqualizerIcon
              fontSize="large"
              color="disabled"
              onClick={() => {
                onChangeChartType("column");
              }}
              style={{ cursor: "pointer" }}
            />
          </Grid>
        ) : (
          <Grid container alignItems="center" xs={12} sm={6}>
            <TimelineIcon
              fontSize="large"
              color="disabled"
              onClick={() => {
                onChangeChartType("line");
              }}
              style={{ cursor: "pointer" }}
            />
            <EqualizerIcon fontSize="large" />
          </Grid>
        )}

        <Grid container justifyContent="right" item xs={12} sm={6} mb="10px">
          <DropdownBox mr="5px">
            <DropDownListComponentBox
              popupWidth={120}
              showClearButton={false}
              dataSource={yearList}
              placeholder="년도"
              onChange={(e: any) => {
                onChangeDate(e, "year");
              }}
              value={searchYear}
              valueTemplate={yearValueTemplate}
            />
          </DropdownBox>
          <DropdownBox mr="5px">
            <DropDownListComponentBox
              popupWidth={120}
              showClearButton={false}
              dataSource={MONTH_LIST}
              placeholder="월"
              onChange={(e: any) => {
                onChangeDate(e, "month");
              }}
              value={searchMonth}
              valueTemplate={monthValueTemplate}
            />
          </DropdownBox>
          <DropdownBox>
            <DropDownListComponentBox
              popupWidth={120}
              showClearButton={false}
              dataSource={MONTH_LIST}
              placeholder="기간"
              onChange={(e: any) => {
                onChangeDate(e, "gap");
              }}
              value={searchGap}
              valueTemplate={gapValueTemplate}
            />
          </DropdownBox>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {chartType === "line" && (
          <ChartComponent
            id="contactTypeMajorChart"
            primaryXAxis={primaryxAxis}
            tooltip={{ enable: true }}
            legendSettings={{
              enableHighlight: true,
              alignment: "Center",
              position: "Right",
            }}
            title="지사별 가망고객 유입현황_영업방식 대분류"
            width="100%"
            margin={margin}
          >
            <Inject
              services={[
                ColumnSeries,
                DataLabel,
                Tooltip,
                LineSeries,
                Category,
                Legend,
                Highlight,
              ]}
            />
            <SeriesCollectionDirective>
              {contactTypeMajorData &&
                contactTypeMajorData.map(
                  (child: ContactTypeStatisticsDataInfo, index: number) => (
                    <SeriesDirective
                      key={index}
                      dataSource={child.data}
                      xName="x"
                      yName="y"
                      name={child.contactTypeName}
                      width={2}
                      marker={{
                        visible: true,
                        width: 5,
                        height: 5,
                        shape: "Circle",
                        isFilled: true,
                      }}
                      type="Line"
                    ></SeriesDirective>
                  )
                )}
            </SeriesCollectionDirective>
          </ChartComponent>
        )}
        {chartType === "column" && (
          <ChartComponent
            id="contactTypeMajorChartColumn"
            primaryXAxis={primaryxAxis}
            tooltip={{ enable: true }}
            legendSettings={{
              enableHighlight: true,
              alignment: "Center",
              position: "Right",
            }}
            title="지사별 가망고객 유입현황_영업방식 대분류"
            width="100%"
            margin={margin}
          >
            <Inject
              services={[
                ColumnSeries,
                DataLabel,
                Tooltip,
                Category,
                Legend,
                Highlight,
              ]}
            />
            <SeriesCollectionDirective>
              {contactTypeMajorData &&
                contactTypeMajorData.map(
                  (child: ContactTypeStatisticsDataInfo, index: number) => (
                    <SeriesDirective
                      key={index}
                      dataSource={child.data}
                      xName="x"
                      yName="y"
                      name={child.contactTypeName}
                      width={2}
                      marker={{
                        visible: true,
                        width: 5,
                        height: 5,
                        shape: "Circle",
                        isFilled: true,
                      }}
                      type="Column"
                    ></SeriesDirective>
                  )
                )}
            </SeriesCollectionDirective>
          </ChartComponent>
        )}
      </Grid>
      <Grid item xs={12} mt="50px">
        <Grid container justifyContent="right" item xs={12} mb="10px">
          <DropdownBox mr="5px">
            <DropDownListComponentBox
              popupWidth={150}
              showClearButton={false}
              dataSource={contactTypeList}
              placeholder="지사"
              onChange={(e: any) => {
                onChangeDate(e, "contactTypeMajor");
              }}
              value={selectedContactType}
              valueTemplate={contactTypeValueTemplate}
            />
          </DropdownBox>
        </Grid>
        <Grid item xs={12}>
          {chartType === "line" && (
            <ChartComponent
              id="contactTypeSubChart"
              primaryXAxis={primaryxAxis}
              tooltip={{ enable: true }}
              legendSettings={{
                enableHighlight: true,
                alignment: "Center",
                position: "Right",
              }}
              title="지사별 가망고객 유입현황_영업방식 소분류"
              width="100%"
              margin={margin}
            >
              <Inject
                services={[
                  ColumnSeries,
                  DataLabel,
                  Tooltip,
                  LineSeries,
                  Category,
                  Legend,
                  Highlight,
                ]}
              />
              <SeriesCollectionDirective>
                {contactTypeData &&
                  selectedContactType &&
                  contactTypeData.map(
                    (child: ContactTypeStatisticsDataInfo, index: number) => (
                      <SeriesDirective
                        key={index}
                        dataSource={child.data}
                        xName="x"
                        yName="y"
                        name={child.contactTypeName}
                        width={2}
                        marker={{
                          visible: true,
                          width: 5,
                          height: 5,
                          shape: "Circle",
                          isFilled: true,
                        }}
                        type="Line"
                      ></SeriesDirective>
                    )
                  )}
              </SeriesCollectionDirective>
            </ChartComponent>
          )}
          {chartType === "column" && (
            <ChartComponent
              id="contactTypeSubChart_Column"
              primaryXAxis={primaryxAxis}
              tooltip={{ enable: true }}
              legendSettings={{
                enableHighlight: true,
                alignment: "Center",
                position: "Right",
              }}
              title="지사별 가망고객 유입현황_영업방식 소분류"
              width="100%"
              margin={margin}
            >
              <Inject
                services={[
                  ColumnSeries,
                  DataLabel,
                  Tooltip,
                  ColumnSeries,
                  Category,
                  Legend,
                  Highlight,
                ]}
              />
              <SeriesCollectionDirective>
                {contactTypeData &&
                  selectedContactType &&
                  contactTypeData.map(
                    (child: ContactTypeStatisticsDataInfo, index: number) => (
                      <SeriesDirective
                        key={index}
                        dataSource={child.data}
                        xName="x"
                        yName="y"
                        name={child.contactTypeName}
                        width={2}
                        marker={{
                          visible: true,
                          width: 5,
                          height: 5,
                          shape: "Circle",
                          isFilled: true,
                        }}
                        type="Column"
                      ></SeriesDirective>
                    )
                  )}
              </SeriesCollectionDirective>
            </ChartComponent>
          )}
        </Grid>
      </Grid>
      <TableOpenBox container onClick={onChangeTableOpen}>
        {tableOpen ? (
          <Grid style={{ cursor: "pointer" }}>
            <ArrowDropUpIcon />
            지사별 가망고객 유입현황-영업방식 표 닫기
          </Grid>
        ) : (
          <Grid style={{ cursor: "pointer" }}>
            <ArrowDropDownIcon />
            지사별 가망고객 유입현황-영업방식 표 펼쳐보기
          </Grid>
        )}
      </TableOpenBox>
      {tableOpen && (
        <Grid container mt="20px">
          <StatusByContactTypeTable
            allContactTypeData={allContactTypeData}
            contactTypeName={contactTypeName}
          />
        </Grid>
      )}
    </Grid>
  );
}

const DropdownBox = styled(Grid)({
  width: "120px",
});

const TableOpenBox = styled(Grid)({
  marginTop: "50px",
  textDecoration: "underline",
});
