import { ExpandLess, ExpandMore } from "@mui/icons-material";
import styled from "@emotion/styled";
import { Collapse, List, ListItemText, ListItemButton } from "@mui/material";

import { Link, useLocation } from "react-router-dom";

import { Urls } from "../../../route/Urls";
import { CustomText } from "../../../styles/CustomText";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  AdminState,
  BoardPageNumber,
  ListTypeState,
  ManagerState,
  SearchDataAtom,
  leftDrawerOpenState,
  registerLeadStateAtom,
} from "../../../system/atoms";
import { InitListSearchInfo } from "../../../system/types/initObject";

interface ListItemButtonWrapper {
  label: string;
  toUrl: string;
}

function DrawerMenuMobileList() {
  const location = useLocation();
  const [list, setList] = useState<boolean>(true);
  const [register, setRegister] = useState<boolean>(true);
  const [statusGraph, setStatusGraph] = useState<boolean>(true);
  const [admin, setAdmin] = useState<boolean>(true);
  const [board, setBoard] = useState<boolean>(true);
  const [sitelink, setSiteLink] = useState<boolean>(true);
  const adminValue = useRecoilValue(AdminState);
  const managerValue = useRecoilState(ManagerState);
  const [, setOpen] = useRecoilState(leftDrawerOpenState);
  const [, setSearchDt] = useRecoilState(SearchDataAtom);
  const [, setPageNumber] = useRecoilState(BoardPageNumber);
  const [, setListType] = useRecoilState(ListTypeState);
  function CloseDrawer() {
    setOpen(false);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setPageNumber(1);
    setSearchDt(InitListSearchInfo);
    setListType("list");
  }

  const ListItemButtonWrapper = ({ label, toUrl }: ListItemButtonWrapper) => (
    <ListItemButton
      selected={location.pathname === toUrl}
      component={Link}
      to={toUrl}
      sx={{ paddingLeft: "35px", ...collapseItemStyle }}
      onClick={CloseDrawer}
    >
      <ListItemText>
        <CustomText type="body" cursorPointer>
          {label}
        </CustomText>
      </ListItemText>
    </ListItemButton>
  );

  const ListItemLinkWrapper = ({ label, toUrl }: ListItemButtonWrapper) => (
    <ListItemButton
      sx={{ paddingLeft: "35px", ...collapseItemStyle }}
      onClick={() => {
        window.open(toUrl);
      }}
    >
      <ListItemText>
        <CustomText type="body" cursorPointer>
          {label}
        </CustomText>
      </ListItemText>
    </ListItemButton>
  );

  const [, setRegisterLeadState] = useRecoilState(registerLeadStateAtom);

  const onChangeRegisterLeadState = () => {
    setRegisterLeadState("");
  };

  useEffect(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <ListItemButton
        sx={{
          marginBottom: !list ? "5px" : null,
          ...mainItemStyle,
        }}
        onClick={() => setBoard((pre) => !pre)}
      >
        <ItemTextWrapper>
          <CustomText type="subtitle" cursorPointer bold400 paddingLeft={"6px"}>
            게시판
          </CustomText>
        </ItemTextWrapper>
        {board ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        sx={{ ...collapseStyle }}
        in={register}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <ListItemButtonWrapper label="공지사항" toUrl={Urls.notice} />
          <ListItemButtonWrapper label="질의응답" toUrl={Urls.qna} />
          <ListItemButtonWrapper label="조달검사검수" toUrl={Urls.qaqc} />
        </List>
      </Collapse>
      <ListItemButton
        sx={{
          marginBottom: !list ? "5px" : null,
          ...mainItemStyle,
        }}
        onClick={() => setList((pre) => !pre)}
      >
        <ItemTextWrapper>
          <CustomText type="subtitle" cursorPointer bold400 paddingLeft={"6px"}>
            조회
          </CustomText>
        </ItemTextWrapper>
        {list ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        sx={{ ...collapseStyle }}
        in={list}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <ListItemButtonWrapper label="Pipeline 목록" toUrl={Urls.leadList} />
          <ListItemButtonWrapper label="내 거래" toUrl={Urls.my} />
          <ListItemButtonWrapper label="내 지사 거래" toUrl={Urls.myBranch} />
          <ListItemButtonWrapper label="신규 거래" toUrl={Urls.new} />
          <ListItemButtonWrapper label="장기 F/U" toUrl={Urls.followUp} />
          <ListItemButtonWrapper label="중개업체" toUrl={Urls.intermediary} />

          {/* <ListItemButtonWrapper label="신규 답변 내역" toUrl={Urls.answer} />
          <ListItemButtonWrapper
            label="신규 미팅 노트"
            toUrl={Urls.meetingNote}
          /> */}
        </List>
      </Collapse>
      <ListItemButton
        sx={{
          marginBottom: !register ? "5px" : null,
          ...mainItemStyle,
        }}
        onClick={() => setRegister((pre) => !pre)}
      >
        <ItemTextWrapper>
          <CustomText type="subtitle" cursorPointer bold400 paddingLeft={"6px"}>
            등록
          </CustomText>
        </ItemTextWrapper>
        {register ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse
        sx={{ ...collapseStyle }}
        in={register}
        timeout="auto"
        unmountOnExit
      >
        <List
          component="div"
          disablePadding
          onClick={onChangeRegisterLeadState}
        >
          <ListItemButtonWrapper label="건별 등록" toUrl="/register/edit/" />
          <ListItemButtonWrapper
            label="엑셀 등록"
            toUrl={Urls.registerWithExcel}
          />
        </List>
      </Collapse>

      {(managerValue || adminValue) && (
        <>
          <ListItemButton
            sx={{
              marginBottom: !statusGraph ? "5px" : null,
              ...mainItemStyle,
            }}
            onClick={() => setStatusGraph((pre) => !pre)}
          >
            <ItemTextWrapper>
              <CustomText
                type="subtitle"
                cursorPointer
                bold400
                paddingLeft={"6px"}
              >
                현황
              </CustomText>
            </ItemTextWrapper>
            {statusGraph ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse
            sx={{ ...collapseStyle }}
            in={statusGraph}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItemButtonWrapper label="레포트" toUrl={Urls.statusGraph} />
            </List>
          </Collapse>
        </>
      )}
      <ListItemButton
        sx={{
          marginBottom: !sitelink ? "5px" : null,
          ...mainItemStyle,
        }}
        onClick={() => setSiteLink((pre) => !pre)}
      >
        <ItemTextWrapper>
          <CustomText type="subtitle" cursorPointer bold400 paddingLeft={"6px"}>
            업무 사이트
          </CustomText>
        </ItemTextWrapper>
        {sitelink ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        sx={{ ...collapseStyle }}
        in={sitelink}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <ListItemLinkWrapper
            label="그룹웨어"
            toUrl="https://groupware.weareinbody.com/"
          />
          <ListItemLinkWrapper
            label="메일"
            toUrl="https://outlook.office.com/mail/"
          />
          <ListItemLinkWrapper
            label="ERP"
            toUrl="https://inbody.operations.dynamics.com/"
          />
          <ListItemLinkWrapper
            label="제품발주"
            toUrl="https://order.weareinbody.com/"
          />
          <ListItemLinkWrapper
            label="고객관리(CRM)"
            toUrl="https://crm.weareinbody.com/"
          />
          <ListItemLinkWrapper
            label="요청접수(MAR)"
            toUrl="https://mar.weareinbody.com/"
          />
          <ListItemLinkWrapper
            label="이미지풀(Inbody Library)"
            toUrl="https://lib.weareinbody.com/"
          />
          <ListItemLinkWrapper
            label="인증"
            toUrl="https://inbodykr.sharepoint.com/sites/certification2"
          />
          <ListItemLinkWrapper
            label="인바디 홈페이지"
            toUrl="https://www.inbody.co.kr/"
          />
          <ListItemLinkWrapper
            label="인바디 자사몰"
            toUrl="https://store.inbody.com/"
          />
          <ListItemLinkWrapper
            label="인바디 GM"
            toUrl="https://gm.weareinbody.com/"
          />
        </List>
      </Collapse>
      <ListItemButton
        sx={{
          marginBottom: !admin ? "5px" : null,
          ...mainItemStyle,
        }}
        onClick={() => setAdmin((pre) => !pre)}
      >
        <ItemTextWrapper>
          <CustomText type="subtitle" cursorPointer bold400 paddingLeft={"6px"}>
            설정
          </CustomText>
        </ItemTextWrapper>
        {admin ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        sx={{ ...collapseStyle, marginBottom: "64px" }}
        in={admin}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <ListItemButtonWrapper label="내 정보" toUrl={Urls.myInfo} />
          {adminValue && (
            <>
              <ListItemButtonWrapper
                label="사용자 관리"
                toUrl={Urls.adminUser}
              />
              <ListItemButtonWrapper
                label="그룹 및 권한 관리"
                toUrl={Urls.adminGroupPermission}
              />
              <ListItemButtonWrapper
                label="정보 관리"
                toUrl={Urls.adminInformation}
              />
              <ListItemButtonWrapper
                label="제품 관리"
                toUrl={Urls.adminProductRegister}
              />
              <ListItemButtonWrapper
                label="영업 보호권 관리"
                toUrl={Urls.adminSalesProtection}
              />
            </>
          )}
        </List>
      </Collapse>
    </>
  );
}

export default DrawerMenuMobileList;

const ItemTextWrapper = styled.div`
  width: 100%;
`;
const collapseStyle = {
  color: "rgb(221, 221, 221)",
  backgroundColor: "rgb(84, 84, 83)",
};

const collapseItemStyle = {
  "&&:hover": {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(84, 84, 83)",
    filter: "brightness(1.2)",
  },
  "&&.Mui-selected": {
    backgroundColor: "rgb(84, 84, 83)",
    filter: "brightness(1.2)",
  },
};

const mainItemStyle = {
  height: "45px",
  "&&.Mui-selected": {
    backgroundColor: "rgb(84, 84, 83, 0.05)",
  },
  "&&.Mui-selected:hover": {
    backgroundColor: "rgb(84, 84, 83, 0.05)",
  },
};
