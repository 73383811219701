import { Grid } from "@mui/material";
import {
  AttachmentFileDefaultBox,
  ByCaseMainTitleBox,
} from "../../../../styles/BycaseStyle";
import {
  CustomFileUpload,
  DisabledImgBox,
  TableBorderBox,
  TableContentDetailBox,
} from "../../../../styles/theme";
import CloseIcon from "@mui/icons-material/Close";
import { Dispatch } from "react";
import { red } from "@mui/material/colors";
import AlertModal from "../../../../components/Common/AlertModal";
import { AttachmentFilesInfo } from "../../../../system/types/MeetingNote";
import uuid from "react-uuid";
import { LeadCustomerInfo } from "../../../../system/types/Customer";

interface ByCaseAttachmentProps {
  fileList: File[];
  setFileList: Dispatch<React.SetStateAction<File[]>>;
  data: LeadCustomerInfo;
  setData: Dispatch<React.SetStateAction<LeadCustomerInfo>>;
}

function ByCaseAttachment({
  fileList,
  setFileList,
  data,
  setData,
}: ByCaseAttachmentProps) {
  const AttachmentKeyId = uuid();
  const onSaveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      let preFileList: File[] = [];
      let preDataFileList: AttachmentFilesInfo[] = [];
      let hasDuplicate = false;
      const attachmentId = AttachmentKeyId;
      for (var i = 0; i < event.target.files.length; i++) {
        const oldFile = event.target.files[i];

        let newFile = null;
        let newDataFile = null;

        newFile = new File(
          [oldFile],
          `${attachmentId + "_" + event.target.files[i].name}`,
          {
            type: oldFile.type,
          }
        );

        newDataFile = {
          filename: event.target.files[i].name,
          changedName: `${attachmentId + "_" + event.target.files[i].name}`,
          filePath:
            "https://inbodyinfra.blob.core.windows.net/inbodylead/" +
            process.env.REACT_APP_ACTIVE_MODE +
            "/" +
            attachmentId +
            "_" +
            event.target.files[i].name,
        };

        if (newFile !== null && newDataFile !== null) {
          const fileName = newFile.name;
          if (fileList.some((file) => file.name === fileName)) {
            hasDuplicate = true;
            AlertModal("msg", `동일한 이름의 파일이 존재합니다: ${fileName}`);
            break;
          } else {
            preFileList = preFileList.concat([newFile]);
            preDataFileList = preDataFileList.concat([newDataFile]);
          }
        }
      }
      if (!hasDuplicate) {
        const completedFileList = fileList.concat(preFileList);
        const completedFileDataList =
          data.attachmentFiles.concat(preDataFileList);
        setFileList(completedFileList);
        setData({ ...data, attachmentFiles: completedFileDataList });
      }
    }
  };

  const deleteFile = (name: string) => {
    setFileList(fileList.filter((dt: File) => dt.name !== name));
    setData({
      ...data,
      attachmentFiles: data.attachmentFiles.filter(
        (dt: AttachmentFilesInfo) => dt.changedName !== name
      ),
    });
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={0} md={0.2}></Grid>
      <Grid container item xs={12} md={11.8}>
        <Grid container justifyContent="space-between">
          <ByCaseMainTitleBox container justifyContent="center" item xs={4.065}>
            첨부 파일
          </ByCaseMainTitleBox>
          <Grid>
            <CustomFileUpload htmlFor={"file"}>
              <Grid container justifyContent="center">
                파일 업로드
              </Grid>
            </CustomFileUpload>
            <DisabledImgBox
              id={"file"}
              type="file"
              onChange={(e) => onSaveFile(e)}
              multiple
            ></DisabledImgBox>
          </Grid>
        </Grid>

        <TableBorderBox container>
          <Grid container justifyContent="right">
            <TableContentDetailBox item xs={12} container pb="5px">
              {data.attachmentFiles.length === 0 ? (
                <Grid container item xs={12} justifyContent="center">
                  첨부 파일 없음
                </Grid>
              ) : (
                <>
                  {data.attachmentFiles.map(
                    (dt: AttachmentFilesInfo, index: number) => (
                      <AttachmentFileDefaultBox
                        key={index}
                        container
                        item
                        xs={12}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid ml="5px"> {dt.filename}</Grid>
                        <CloseIcon
                          onClick={() => deleteFile(dt.changedName)}
                          sx={{ color: red[700], cursor: "pointer" }}
                        />
                      </AttachmentFileDefaultBox>
                    )
                  )}
                </>
              )}
            </TableContentDetailBox>
          </Grid>
        </TableBorderBox>
      </Grid>
    </Grid>
  );
}

export default ByCaseAttachment;
