//react

import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Routes from "./route/Routes";
import UserRoute from "./route/UserRoute";
import { Global, css } from "@emotion/react";

// recoil
import { RecoilRoot } from "recoil";
import { BasicFontKR } from "./styles/font";

function App() {
  return (
    <>
      <RecoilRoot>
        <Global
          styles={css`
            * {
              font-family: ${BasicFontKR};
            }
            &::-webkit-scrollbar {
              display: block;
              width: 8px;
              height: 8px;
              border-radius: 6px;
              background-color: rgba(255, 255, 255, 0.4);
            }
            &::-webkit-scrollbar-thumb {
              background-color: rgba(0, 0, 0, 0.3);
              border-radius: 6px;
            }
          `}
        />
        <Router>
          <UserRoute Component={Routes}></UserRoute>
        </Router>
      </RecoilRoot>
    </>
  );
}

export default App;
