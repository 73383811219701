import { Divider, Grid, styled } from "@mui/material";
import { CustomText } from "../../../../styles/CustomText";
import {
  CustomButtonDisabled2,
  CustomButtonGreen,
} from "../../../../styles/ButtonStyle";
import {
  TableMainContentBox,
  TableMainNumberContentBox,
  TableMainTitleBox,
} from "../../../../styles/StatisticsTableStyle";
import uuid from "react-uuid";
import { useEffect, useState } from "react";
import { SalesProtectionStatisticsInfo } from "../../../../system/types/Group";
import { GroupApi } from "../../../../system/api/GroupApi";
import { ErrorHandler } from "../../../../system/ApiService";
import AlertModal from "../../../../components/Common/AlertModal";
import { InitSalesProtectionSettingInfo } from "../../../../system/types/initObject";
import { SalesProtectionSettingInfo } from "../../../../system/types/Setting";
import AdminApi from "../../../../system/api/AdminApi";
import { MessageComponent } from "@syncfusion/ej2-react-notifications";

function QAQCSetting() {
  const [salesProtectionData, setSalesProtectionData] = useState<
    SalesProtectionStatisticsInfo[]
  >([]);
  const [salesProtectionSettingData, setSalesProtectionSettingData] =
    useState<SalesProtectionSettingInfo>(InitSalesProtectionSettingInfo);
  const [protectAlert, setProtectAlert] = useState(false);
  useEffect(() => {
    GroupApi.getSalesProtectionStatistics()
      .then((res) => {
        setSalesProtectionData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
    AdminApi.GetSalesProtection()
      .then((res) => {
        setSalesProtectionSettingData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const onChange = (args: any, type: string) => {
    setSalesProtectionSettingData({
      ...salesProtectionSettingData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });

    if (type === "salesEmployeeProtectionCount") {
      const updatedSalesData = salesProtectionData.map((data) => {
        const totalProtectionCount =
          data.salesEmployeeCount * args.target.value +
          data.nonSalesEmployeeCount *
            salesProtectionSettingData.nonSalesEmployeeProtectionCount;
        const rate =
          (data.protectionCount /
            (data.salesEmployeeCount * args.target.value +
              data.nonSalesEmployeeCount *
                salesProtectionSettingData.nonSalesEmployeeProtectionCount)) *
          100;
        return { ...data, totalProtectionCount, rate };
      });
      setSalesProtectionData(updatedSalesData);
    } else {
      const updatedSalesData = salesProtectionData.map((data) => {
        const totalProtectionCount =
          data.salesEmployeeCount *
            salesProtectionSettingData.salesEmployeeProtectionCount +
          data.nonSalesEmployeeCount * args.target.value;
        const rate =
          (data.protectionCount /
            (data.salesEmployeeCount *
              salesProtectionSettingData.salesEmployeeProtectionCount +
              data.nonSalesEmployeeCount * args.target.value)) *
          100;

        return { ...data, totalProtectionCount, rate };
      });
      setSalesProtectionData(updatedSalesData);
    }
  };

  useEffect(() => {
    const checkedData = salesProtectionData.map((data) => {
      const isProtect = data.totalProtectionCount < data.protectionCount;
      return { ...data, isProtect };
    });

    const alertCheck = checkedData.some((data) => data.isProtect);
    setProtectAlert(alertCheck);
  }, [
    salesProtectionSettingData.salesEmployeeProtectionCount,
    salesProtectionSettingData.nonSalesEmployeeProtectionCount,
  ]);

  const displayRate = (rate: any) => {
    return typeof rate === "number" && !isNaN(rate) && rate !== Infinity
      ? rate.toFixed() + " %"
      : "- %";
  };

  const onSave = () => {
    AdminApi.UpdateSalesProtection(salesProtectionSettingData)
      .then((res) => {
        AlertModal("success", "영업 보호권 개수가 변경되었습니다.");
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };
  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <CustomText type="subtitle2">영업 보호권 개수 설정</CustomText>
      </Grid>
      <Grid item xs={12} mt="5px">
        {protectAlert ? (
          <MessageComponent
            severity="Error"
            content="사용 개수는 총 영업 보호권 개수를 초과할 수 없습니다."
          />
        ) : (
          <MessageComponent
            severity="Info"
            content="영업 보호권 수정 후 변경 사항을 저장하려면 저장 버튼을 클릭하셔야 합니다."
          />
        )}
      </Grid>
      <Grid container item xs={12} mt="10px" alignItems="center">
        <CustomText type="subtitle">영업사원 인당 영업 보호권 개수</CustomText>
      </Grid>
      <Grid container item mt="10px" alignItems="center">
        <Grid item mr="10px">
          <InputBox
            type="text"
            name="salesEmployeeProtectionCount"
            onChange={(args) => onChange(args, "salesEmployeeProtectionCount")}
            value={salesProtectionSettingData.salesEmployeeProtectionCount}
            placeholder="영업인원 영업 보호권"
            autoComplete="new-password"
          />
        </Grid>{" "}
        개
      </Grid>
      <Grid container item xs={12} mt="10px" alignItems="center">
        <CustomText type="subtitle">
          영업사원 외 인당 영업 보호권 개수
        </CustomText>
      </Grid>
      <Grid container item mt="10px" alignItems="center" mb="10px">
        <Grid item mr="10px">
          <InputBox
            type="text"
            name="nonSalesEmployeeProtectionCount"
            onChange={(args) =>
              onChange(args, "nonSalesEmployeeProtectionCount")
            }
            value={salesProtectionSettingData.nonSalesEmployeeProtectionCount}
            placeholder="영업인원 외 영업 보호권"
            autoComplete="new-password"
          />
        </Grid>
        개
      </Grid>
      {protectAlert ? (
        <CustomButtonDisabled2
          container
          alignItems="center"
          justifyContent="center"
        >
          저장 불가
        </CustomButtonDisabled2>
      ) : (
        <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
      )}
      <Grid item xs={12} mt="20px">
        <Divider />
      </Grid>
      <Grid item xs={12} mt="20px">
        <CustomText type="subtitle2">지사별 현황</CustomText>
      </Grid>
      <Grid item xs={8} mt="10px">
        <TableMainTitleBox container item xs={12} alignItems="center">
          <Grid container item xs={2} justifyContent="center">
            지사
          </Grid>
          <Grid container item xs={2} justifyContent="right">
            영업사원 인원 수
          </Grid>
          <Grid container item xs={2} justifyContent="right">
            영업사원 외 인원 수
          </Grid>
          <Grid container item xs={2} justifyContent="right">
            총 영업 보호권 개수
          </Grid>
          <Grid container item xs={2} justifyContent="right">
            사용 개수
          </Grid>
          <Grid container item xs={2} justifyContent="right" pr="5px">
            사용률
          </Grid>
        </TableMainTitleBox>
        {salesProtectionData.map((item) => (
          <TableMainContentBox container item xs={12} key={uuid()}>
            <TableMainNumberContentBox
              container
              item
              xs={2}
              justifyContent="center"
            >
              {item.groupName}
            </TableMainNumberContentBox>
            <TableMainNumberContentBox
              container
              item
              xs={2}
              justifyContent="right"
            >
              {item.salesEmployeeCount} 명
            </TableMainNumberContentBox>
            <TableMainNumberContentBox
              container
              item
              xs={2}
              justifyContent="right"
            >
              {item.nonSalesEmployeeCount} 명
            </TableMainNumberContentBox>
            <TableMainNumberContentBox
              container
              item
              xs={2}
              justifyContent="right"
            >
              {item.totalProtectionCount}개
            </TableMainNumberContentBox>
            <TableMainNumberContentBox
              container
              item
              xs={2}
              justifyContent="right"
            >
              {item.protectionCount} 개
            </TableMainNumberContentBox>
            <TableMainNumberContentBox
              container
              item
              xs={2}
              justifyContent="right"
            >
              {displayRate(item.rate)}
            </TableMainNumberContentBox>
          </TableMainContentBox>
        ))}
      </Grid>
    </Grid>
  );
}

export default QAQCSetting;

const InputBox = styled("input")({
  width: "100%",
  height: "2.5rem",
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  paddingLeft: "10px",
  margin: "5px 0px",
  "&::placeholder": { color: "#B2B4B8", fontWeight: "300", fontSize: "16px" },
  "&:hover": { border: "1px solid #81848B" },
  "&:focus": { border: "1px solid #81848B", outline: "none" },
  "&:active": { border: "1px solid #81848B" },
});
