import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { CustomText } from "../../../styles/CustomText";
import ByCaseCustomerInfo from "./Components/ByCaseCustomerInfo";
import { CustomButtonGreen } from "../../../styles/ButtonStyle";
import ByCaseBranchInfo from "./Components/ByCaseBranchInfo";
import ByCaseProductInfo from "./Components/ByCaseProductInfo";
import { useCallback, useEffect, useState } from "react";
import {
  LeadCustomerInfo,
  LeadOrdersInfo,
} from "../../../system/types/Customer";
import {
  InitContactTypeListInfo,
  InitLeadCustomerInfo,
  InitLeadOrdersInfo,
} from "../../../system/types/initObject";
import { CustomerApi } from "../../../system/api/CustomerApi";
import AlertModal from "../../../components/Common/AlertModal";
import { ErrorHandler } from "../../../system/ApiService";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  CSState,
  ManagedGroupDataState,
  MyInfoState,
  registerLeadStateAtom,
} from "../../../system/atoms";
import { EmployeeTableInfo, MyInfo } from "../../../system/types/Employee";
import { GroupInfo } from "../../../system/types/Group";
import { GroupApi } from "../../../system/api/GroupApi";
import ByCaseAttachment from "./Components/ByCaseAttachment";
import { TokenApi } from "../../../system/api/TokenApi";
import { BlobServiceClient } from "@azure/storage-blob";
interface Params {
  id: string;
}

function Validation(
  data: LeadCustomerInfo,
  orderData: LeadOrdersInfo[],
  directInput: boolean,
  directWritePlaceName: string,
  csValue: boolean
) {
  // eslint-disable-next-line
  const exptext = /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}$/;
  // eslint-disable-next-line
  const exptextMail = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
  const priceState = orderData.some(
    (order) => order.product?.id && order.price === 0
  );
  if (data.customerType === "일반") {
    if (
      (!directInput && !data.address.placeName) ||
      (directInput && directWritePlaceName === "")
    ) {
      AlertModal("check", "상호명을 입력해 주세요.");
      return false;
    } else if (!csValue && !data.businessType?.id) {
      AlertModal("check", "업종을 선택해주세요.");
      return false;
    } else if (
      data.businessType &&
      (data.businessType.name === "양방종합병원" ||
        data.businessType.name === "한방병원") &&
      !data.department?.id
    ) {
      AlertModal("check", "과/팀/센터를 선택해주세요.");
      return false;
    } else if (!data.saleType?.id && !csValue) {
      AlertModal("check", "판매방식을 선택해주세요.");
      return false;
    } else if (!data.contactType?.id) {
      AlertModal("check", "영업방식을 선택해주세요.");
      return false;
    } else if (!data.step?.id) {
      AlertModal("check", "영업단계를 선택해주세요.");
      return false;
    } else if (data.email && exptextMail.test(data.email) === false) {
      AlertModal("check", "이메일을 올바른 형식으로 작성해 주세요.");
      return false;
    } else if (data.phone && exptext.test(data.phone) === false) {
      AlertModal(
        "check",
        "연락처를 올바른 형식으로 작성해 주세요. \n ex. 010-0000-0000"
      );
      return false;
    } else if (orderData.length > 0 && priceState) {
      AlertModal("check", "제품 단가를 작성해 주세요.");
      return false;
    }
  } else {
    if (
      (!directInput && !data.address.placeName) ||
      (directInput && directWritePlaceName === "")
    ) {
      AlertModal("check", "상호명을 입력해 주세요.");
      return false;
    } else if (!data.contactType.id) {
      AlertModal("check", "영업방식을 선택해주세요.");
      return false;
    } else if (data.email && exptextMail.test(data.email) === false) {
      AlertModal("check", "이메일을 올바른 형식으로 작성해 주세요.");
      return false;
    } else if (data.phone && exptext.test(data.phone) === false) {
      AlertModal(
        "check",
        "연락처를 올바른 형식으로 작성해 주세요. \n ex. 010-0000-0000"
      );
      return false;
    } else if (orderData.length > 0 && priceState) {
      AlertModal("check", "제품 단가를 작성해 주세요.");
      return false;
    }
  }

  return true;
}

function ByCaseRegisterPage() {
  const { id } = useParams<Params>();
  const leadId = parseInt(id);
  const history = useHistory();
  const [data, setData] = useState<LeadCustomerInfo>(InitLeadCustomerInfo);
  const [orderData, setOrderData] = useState<LeadOrdersInfo[]>([
    InitLeadOrdersInfo,
  ]);
  const groupData = useRecoilValue<GroupInfo[]>(ManagedGroupDataState);
  const [employeeList, setEmployeeList] = useState<EmployeeTableInfo[]>([]);
  const myInfoData = useRecoilValue(MyInfoState);
  const [RegisterLeadState, setRegisterLeadState] = useRecoilState(
    registerLeadStateAtom
  );
  const [directWritePlaceName, setDirectWritePlaceName] = useState("");
  const [directInput, setDirectInput] = useState(false);
  const [addressId, setAddressId] = useState(null);
  const CSValue = useRecoilValue(CSState);

  const getCustomerInfo = useCallback(() => {
    if (leadId) {
      CustomerApi.getCustomerDetailInfo(leadId)
        .then((res) => {
          setData(res.data);
          setAddressId(res.data?.address?.id);
          setOrderData(res.data.orders);
          if (res.data?.group?.id) {
            GroupApi.findGroupEmployee(res.data.group.id)
              .then((res) => {
                setEmployeeList(res.data);
              })
              .catch((err) => {
                let msg = ErrorHandler(err);
                AlertModal("msg", msg);
              });
          }
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [leadId]);

  useEffect(() => {
    if (RegisterLeadState === "edit") {
      getCustomerInfo();
    } else {
      const findObjectByID = (
        id: number,
        tree: GroupInfo[]
      ): GroupInfo | undefined => {
        for (const node of tree) {
          if (node.id === id) {
            return node;
          }
          if (node.children && node.children.length > 0) {
            const foundInChildren = findObjectByID(id, node.children);
            if (foundInChildren) {
              return foundInChildren;
            }
          }
        }
      };
      if (groupData.length > 0 && groupData[0] && groupData[0]?.id) {
        GroupApi.getManagedGroupInfo()
          .then((res) => {
            if (
              myInfoData.groups &&
              myInfoData.groups.length > 0 &&
              myInfoData.groups[0]?.id
            ) {
              const selectedGroupDt = findObjectByID(
                myInfoData.groups[0].id,
                res.data
              );
              if (selectedGroupDt && selectedGroupDt?.id) {
                GroupApi.findGroupEmployee(selectedGroupDt.id)
                  .then((resEmp) => {
                    setEmployeeList(resEmp.data);
                    if (res.data.length > 0) {
                      setData((prev: LeadCustomerInfo) => ({
                        ...prev,
                        group: selectedGroupDt,
                        seller: resEmp.data.find(
                          (item: MyInfo) => item.id === myInfoData.id
                        ),
                      }));
                    } else {
                      setData((prev: LeadCustomerInfo) => ({
                        ...prev,
                        group: selectedGroupDt,
                      }));
                    }
                  })
                  .catch((err) => {
                    let msg = ErrorHandler(err);
                    AlertModal("msg", msg);
                  });
              }
            }
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      }
    }
  }, [
    leadId,
    //getCustomerInfo,
    RegisterLeadState,
    groupData,
    myInfoData.groups,
    myInfoData.id,
  ]);

  // token
  const [sasToken, setSasToken] = useState("");

  useEffect(() => {
    TokenApi.getSasToken()
      .then((res) => {
        setSasToken(res.data.sasUrl);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const [fileList, setFileList] = useState<File[]>([]);

  const UploadFileToBlob = async (file: File[]) => {
    // upload file
    const blobService = new BlobServiceClient(sasToken);
    const containerClient = blobService.getContainerClient("");

    const uploadPromises = file.map((document) => {
      if (document.name === null) {
        return Promise.resolve();
      }

      const blockBlobClient = containerClient.getBlockBlobClient(
        process.env.REACT_APP_ACTIVE_MODE + "/" + document.name
      );

      return blockBlobClient.uploadData(document!);
    });
    return Promise.all(uploadPromises);
  };

  var submitcheck = false;
  const onSave = () => {
    if (
      Validation(data, orderData, directInput, directWritePlaceName, CSValue)
    ) {
      const preOrderData = [...orderData];
      for (var i = 0; i < orderData.length; i++) {
        preOrderData[i] = { ...preOrderData[i], seller: data.seller };
      }
      submitcheck = true;
      if (submitcheck === true) {
        UploadFileToBlob(fileList);
        const placeNameInfo =
          directInput === true
            ? directWritePlaceName
            : data?.address?.placeName;
        if (data.id) {
          CustomerApi.updateCustomerInfo({
            ...data,
            orders: preOrderData.filter(
              (item) => item.product?.family && item.product?.name
            ),
            address: {
              ...data.address,
              id: addressId,
              placeName: placeNameInfo,
            },
          })
            .then(() => {
              setRegisterLeadState("");
              AlertModal("success", "Pipeline이 수정되었습니다.");
              history.push(`/list/detail/${id}`);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
              submitcheck = false;
            });
        } else {
          CustomerApi.createCustoemrInfo({
            ...data,
            orders: preOrderData.filter(
              (item) => item.product?.family && item.product?.name
            ),
            address: { ...data.address, placeName: placeNameInfo },
          })
            .then((res) => {
              setRegisterLeadState("");
              AlertModal(
                "success",
                data.customerType === "일반"
                  ? "Pipeline이 등록되었습니다."
                  : "중개업체가 등록되었습니다."
              );
              setData(InitLeadCustomerInfo);
              history.push(`/list/detail/${res.data.id}`);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
              submitcheck = false;
            });
        }
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).value === "중개업체") {
      setData({
        ...data,
        customerType: (event.target as HTMLInputElement).value,
        intermediaryCompany: null,
        businessType: null,
        department: null,
        saleType: null,
        contactType: InitContactTypeListInfo,
        contractType: null,
        supplyType: null,
        step: null,
        estimatedDate: "",
      });
    } else {
      setData({
        ...data,
        customerType: (event.target as HTMLInputElement).value,
      });
    }
  };

  return (
    <>
      <CustomText type="title">건별 등록</CustomText>
      <Grid container justifyContent="right" margin="5px 0px">
        <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
      </Grid>
      {!data.id && (
        <Grid>
          <RadioGroup row onChange={handleChange} value={data.customerType}>
            <FormControlLabel
              value="일반"
              control={<Radio color="default" />}
              label="앤드유저"
            />
            <FormControlLabel
              value="중개업체"
              control={<Radio color="default" />}
              label="중개업체"
            />
          </RadioGroup>
        </Grid>
      )}

      <Grid container mt="10px">
        <Grid item xs={12} md={6}>
          <ByCaseCustomerInfo
            data={data}
            leadId={leadId}
            setData={setData}
            onSave={onSave}
            directWritePlaceName={directWritePlaceName}
            setDirectWritePlaceName={setDirectWritePlaceName}
            directInput={directInput}
            setDirectInput={setDirectInput}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ByCaseBranchInfo
            data={data}
            setData={setData}
            employeeList={employeeList}
            setEmployeeList={setEmployeeList}
          />
          <ByCaseProductInfo
            orderData={orderData}
            setOrderData={setOrderData}
            data={data}
          />
          <ByCaseAttachment
            fileList={fileList}
            setFileList={setFileList}
            data={data}
            setData={setData}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ByCaseRegisterPage;
