import client from "../ApiService";
import HttpClient from "../http-client";
import { LoginInfo, MyInfo, PasswordInfo } from "../types/Employee";

const baseUri: string = "/employee";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _EmployeeApi extends _BasicApi {
  GetEmployee(searchText?: string) {
    return this.client.get(`${baseUri}/list`, {
      params: { searchText: searchText },
    });
  }
  employeeLogin(loginInfo: LoginInfo) {
    return this.client.post(`${baseUri}/login`, loginInfo);
  }
  ssoLogin() {
    return this.client.get(`/oauth2/authorization/azure`);
  }
  getMyInfo() {
    return this.client.get(`${baseUri}/me`);
  }
  updateMyInfo(myData: MyInfo) {
    return this.client.put(`${baseUri}/me`, myData);
  }
  updatePassword(passwordInfo: PasswordInfo) {
    return this.client.post(`${baseUri}/password`, passwordInfo);
  }
  checkToken(accessToken: string) {
    return this.client.get(`${baseUri}/check/token`, {
      params: { accessToken: accessToken },
    });
  }
  updateFcmToken(fcmToken: string) {
    return this.client.post(`/notification/token?fcmToken=${fcmToken}`)
  }
}

export const EmployeeApi = new _EmployeeApi(client);
