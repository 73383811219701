import { Typography, TypographyProps } from "@mui/material";
import { ReactNode } from "react";

export interface TextCustomProps {
  overflowTooltip?: boolean;
  bold300?: boolean;
  bold400?: boolean;
  bold500?: boolean;
  bold600?: boolean;
  blur?: boolean;
  children: ReactNode;
  mobile?: boolean;
  fullWidth?: boolean;
  inline?: boolean;
  lineSpace?: boolean;
  cursorPointer?: boolean;
}

export interface TextType {
  type?:
    | "small"
    | "body"
    | "subtitle"
    | "subtitle2"
    | "title"
    | "bigTitle"
    | "superTitle"
    | "button"
    | "buttonLarge";
}
export type CustomTextProps = TextCustomProps & TextType & TypographyProps;

export function CustomText({
  overflowTooltip,
  bold300,
  bold400,
  bold500,
  bold600,
  blur,
  children,
  mobile,
  fullWidth,
  type: textType = "body",
  inline,
  lineSpace,
  sx,
  cursorPointer,
  ...props
}: CustomTextProps) {
  const fontSizeMap: Record<string, string> = {
    small: mobile ? "10px" : "12px",
    body: mobile ? "12px" : "14px",
    subtitle: mobile ? "14px" : "16px",
    subtitle2: mobile ? "16px" : "18px",
    title: mobile ? "18px" : "20px",
    bigTitle: mobile ? "20px" : "24px",
    superTitle: mobile ? "28px" : "32px",
    button: mobile ? "14px" : "16px",
    buttonLarge: mobile ? "16px" : "18px",
  };

  const fontWeightMap: Record<string, number> = {
    small: 400,
    body: 400,
    subtitle: 500,
    subtitle2: 500,
    title: 600,
    bigTitle: 600,
    superTitle: 600,
    button: 500,
    buttonLarge: 600,
  };

  const fontSize = fontSizeMap[textType];
  const baseFontWeight = fontWeightMap[textType];

  return (
    <Typography
      sx={{
        ...sx,
        fontSize: fontSize,
        fontWeight: baseFontWeight,
        fontFamily: "Noto sans CJK KR",
        cursor: cursorPointer ? "pointer" : "default",
        ...(fullWidth
          ? {
              width: "100%",
            }
          : {}),
        ...(overflowTooltip
          ? {
              display: "block",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }
          : {}),
        ...(bold300
          ? {
              fontWeight: 300,
            }
          : {}),
        ...(bold400
          ? {
              fontWeight: 400,
            }
          : {}),
        ...(bold500
          ? {
              fontWeight: 500,
            }
          : {}),
        ...(bold600
          ? {
              fontWeight: 600,
            }
          : {}),
        ...(blur
          ? {
              opacity: 0.5,
            }
          : {}),
        ...(lineSpace ? {} : { whiteSpace: "nowrap" }),
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}
