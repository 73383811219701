import {
  EmployeeInfo,
  EmployeeTableInfo,
} from "../../../../system/types/Employee";
import {
  ColumnDirective,
  ColumnsDirective,
  EditSettingsModel,
  GridComponent,
  Inject,
  Page,
  PageSettingsModel,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { Grid } from "@mui/material";
import { CustomButtonYellow } from "../../../../styles/ButtonStyle";
interface AdminUserTableMobileProps {
  data: EmployeeTableInfo[];
  moveToUpdateEmployee: (props: EmployeeInfo) => void;
}

function AdminUserTableMobile({
  data,
  moveToUpdateEmployee,
}: AdminUserTableMobileProps) {
  const renderingMode = "Vertical";
  const pageSettings: PageSettingsModel = { pageSize: 3 };
  let grid: any;
  const editOptions: EditSettingsModel = { allowDeleting: true };

  const groupTemplate = (props: EmployeeInfo) => {
    return <>{props.group ? props.group : "-"}</>;
  };

  const activeTemplate = (props: EmployeeInfo) => {
    return (
      <CustomButtonYellow onClick={() => moveToUpdateEmployee(props)}>
        수정
      </CustomButtonYellow>
    );
  };

  const jobTemplate = (props: EmployeeInfo) => {
    return (
      <>
        {props.jobTitle ? (
          <>
            {props.jobPosition ? (
              <>
                {props.jobTitle?.name} / {props.jobPosition?.name}
              </>
            ) : (
              <>{props.jobTitle?.name}</>
            )}
          </>
        ) : (
          <>{props.jobPosition ? <>{props.jobPosition?.name}</> : <>-</>}</>
        )}
      </>
    );
  };
  return (
    <Grid
      container
      justifyContent="center"
      className="col-md-9 e-bigger e-adaptive-demo"
    >
      <GridComponent
        width="100%"
        dataSource={data}
        enableAdaptiveUI={true}
        allowPaging={true}
        allowSorting={true}
        editSettings={editOptions}
        pageSettings={pageSettings}
        rowRenderingMode={renderingMode}
        locale="ko"
        ref={(g) => {
          grid = g;
        }}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="name"
            headerText="이름"
            width="300"
            textAlign="Center"
          />
          <ColumnDirective
            field="group"
            headerText="소속 그룹"
            template={groupTemplate}
            width="300"
            textAlign="Center"
          />
          <ColumnDirective
            field="email"
            headerText="계정"
            width="300"
            textAlign="Center"
          />

          <ColumnDirective
            field="phone"
            headerText="연락처"
            width="300"
            textAlign="Center"
          />

          <ColumnDirective
            field="jobTitle"
            headerText="직책 / 직위"
            width="300"
            template={jobTemplate}
            textAlign="Center"
          />
          <ColumnDirective
            field="id"
            headerText=""
            width="300"
            template={activeTemplate}
            textAlign="Center"
          />
        </ColumnsDirective>
        <Inject services={[Page, Sort]} />
      </GridComponent>
    </Grid>
  );
}

export default AdminUserTableMobile;
