import { ReactElement, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Content from "../pages/Layout/Content";
import Header from "../pages/Layout/Header";
import { Urls } from "./Urls";
import MyPage from "../pages/List/My/MyPage";
import MyBranchPage from "../pages/List/My/MyBranchPage";
import NewPage from "../pages/List/New/NewPage";
import FollowUpPage from "../pages/List/FollowUp/FollowUpPage";
import base64 from "base-64";
import WithExcelPage from "../pages/Register/WithExcel/WithExcelPage";
import AdminUserPage from "../pages/Admin/User/AdminUserPage";
import AdminGroupPermissionPage from "../pages/Admin/GroupPermission/AdminGroupPermissionPage";
import AdminInformationPage from "../pages/Admin/Information/AdminInformationPage";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  AdminState,
  CSState,
  ManagedGroupDataState,
  ManagerState,
  MyInfoState,
  NotiDataState,
  NotiIdState,
  loginState,
  passwordState,
} from "../system/atoms";
import LandingMain from "../pages/Main/LandingMain";
import NotFoundPage from "../pages/Layout/NotFoundPage";
import PasswordPage from "../pages/Main/PasswordPage";
import AdminGroupDetailPage from "../pages/Admin/GroupPermission/AdminGroupDetailPage";
import AnswerPage from "../pages/List/Answer/AnswerPage";
import NoticePage from "../pages/Board/NoticePage";
import QnaPage from "../pages/Board/QnaPage";
import MyInfoPage from "../pages/Setting/MyInfoPage";
import { EmployeeApi } from "../system/api/EmployeeApi";
import { ErrorHandler } from "../system/ApiService";
import AlertModal from "../components/Common/AlertModal";
import LeadPage from "../pages/List/Lead/LeadPage";
import ProductRegisterPage from "../pages/Admin/ProductRegister/ProductRegisterPage";
import ByCaseRegisterPage from "../pages/Register/ByCase/ByCasePage";
import LeadDetailPage from "../pages/Register/ByCase/LeadDetailPage";
import NoticeDetailPage from "../pages/Board/NoticeDetailPage";
import NoticeEditPage from "../pages/Board/NoticeEditPage";
import IntermediaryPage from "../pages/List/Intermediary/IntermediaryPage";
import NotiMainPage from "../pages/List/Noti/NotiMain";
import { GroupApi } from "../system/api/GroupApi";
import { onMessageListener, requestPermission } from "../firebase";
import {
  NotificationInfo,
  RecievedNotiInfo,
} from "../system/types/Notification";
import QnaDetailPage from "../pages/Board/QnaDetailPage";
import QnaEditPage from "../pages/Board/QnaEditPage";
import LoadingSpinnerModal from "../components/Common/Loading";
import StatusGraphPage from "../pages/StatusGraph/StatusGraphPage";
import QAQCMain from "../pages/QAQC/QAQCMain";
import AdminSalesProtectionMain from "../pages/Admin/BusinessProtection/AdminSalesProtectionMain";

function Routes(): ReactElement {
  const loginPageState = useRecoilValue(loginState);
  const passwordPageState = useRecoilValue(passwordState);
  const [adminValue, setAdmin] = useRecoilState(AdminState);
  const [, setCS] = useRecoilState(CSState);
  const [, setManager] = useRecoilState(ManagerState);
  const [myInfoData, setMyInfoData] = useRecoilState(MyInfoState);
  const [LoginStatus, setLoginStatus] = useRecoilState(loginState);
  const [ManagedGroupData, setManagedGroupData] = useRecoilState(
    ManagedGroupDataState
  );
  const [, setNotificationData] = useRecoilState(NotiDataState);
  const [, setNotifiIdData] = useRecoilState(NotiIdState);
  const history = useHistory();

  // token 있으면
  const existedToken = localStorage.getItem("accessToken");
  const existedRefreshToken = localStorage.getItem("refreshToken");
  useEffect(() => {
    if (existedToken) {
      if (LoginStatus && !myInfoData.email) {
        EmployeeApi.getMyInfo()
          .then((res) => {
            setMyInfoData(res.data);
            if (res.data.role === "ADMIN") {
              setAdmin(true);
            } else if (res.data.role === "CS") {
              setCS(true);
            } else if (res.data.role === "MANAGER") {
              setManager(true);
            }
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      } else if (
        LoginStatus &&
        myInfoData.email &&
        (!myInfoData.phone ||
          !myInfoData.jobPosition?.id ||
          !myInfoData.jobTitle?.id)
      ) {
        history.push(`/myInfo`);
      }
    } else {
      setLoginStatus(false);
      history.push(`/login`);
    }
  }, [
    setCS,
    existedToken,
    LoginStatus,
    setAdmin,
    setMyInfoData,
    setManager,
    myInfoData.email,
    myInfoData.phone,
    myInfoData.jobPosition,
    myInfoData.jobTitle,
    history,
    setLoginStatus,
  ]);

  useEffect(() => {
    if (existedToken) {
      if (ManagedGroupData.length === 0) {
        GroupApi.getManagedGroupInfo()
          .then((res) => {
            setManagedGroupData(res.data);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      }
    }
  }, [existedToken, ManagedGroupData.length, setManagedGroupData]);

  useEffect(() => {
    if (existedToken) {
      const fetchData = async () => {
        await requestPermission();
        onMessageListener((payload: RecievedNotiInfo) => {
          const newItem: NotificationInfo = {
            messageId: payload?.data?.messageId,
            type: payload?.data?.type,
            title: payload?.notification?.title,
            body: payload?.notification?.body,
            path: payload?.data?.path,
            createdAt: payload?.data?.createdAt,
          };
          setNotificationData((prevData) => [...prevData, newItem]);
          setNotifiIdData((prevData) => [
            ...prevData,
            payload?.data?.messageId,
          ]);
        });
      };

      fetchData();
    }
  }, [setNotificationData, setNotifiIdData, existedToken]);

  useEffect(() => {
    const currentTime = new Date().getTime() / 1000;
    if (existedRefreshToken) {
      const decodeRefreshToken = base64.decode(
        existedRefreshToken.substring(
          existedRefreshToken.indexOf(".") + 1,
          existedRefreshToken.lastIndexOf(".")
        )
      );
      const parsedJson = JSON.parse(decodeRefreshToken);
      const exp = parsedJson.exp;
      if (exp < currentTime) {
        AlertModal("msg", "인증이 만료되어 재로그인이 필요합니다.");
        localStorage.clear();
      }
    }
  }, []);

  return (
    <Switch>
      <Route path={Urls.login} component={LandingMain} exact></Route>

      {passwordPageState && (
        <Route path={Urls.password} component={PasswordPage} exact></Route>
      )}

      {passwordPageState === false && loginPageState && existedToken && (
        <>
          <LoadingSpinnerModal />,{/* Header - Navigation Menu */}
          <Header />
          {/* Body - Content */}
          <Content>
            <Switch>
              {/* 조회 */}
              <Route path={Urls.leadList} component={LeadPage} exact></Route>
              <Route path={Urls.my} component={MyPage} exact></Route>
              <Route
                path={Urls.myBranch}
                component={MyBranchPage}
                exact
              ></Route>
              <Route path={Urls.new} component={NewPage} exact></Route>
              <Route
                path={Urls.followUp}
                component={FollowUpPage}
                exact
              ></Route>
              <Route path={Urls.answer} component={AnswerPage} exact></Route>
              <Route path={Urls.noti} component={NotiMainPage} exact></Route>
              <Route
                path={Urls.leadDetail}
                component={LeadDetailPage}
                exact
              ></Route>
              <Route
                path={Urls.intermediary}
                component={IntermediaryPage}
                exact
              ></Route>
              {/* 등록 */}
              <Route
                path={Urls.registerByCase}
                component={ByCaseRegisterPage}
                exact
              ></Route>
              <Route
                path={Urls.registerWithExcel}
                component={WithExcelPage}
                exact
              ></Route>
              {/* 게시판 */}
              <Route path={Urls.notice} component={NoticePage} exact></Route>
              <Route
                path={Urls.noticeDetail}
                component={NoticeDetailPage}
                exact
              ></Route>
              <Route
                path={Urls.noticeEdit}
                component={NoticeEditPage}
                exact
              ></Route>
              <Route path={Urls.qna} component={QnaPage} exact></Route>
              <Route
                path={Urls.qnaDetail}
                component={QnaDetailPage}
                exact
              ></Route>
              <Route path={Urls.qnaEdit} component={QnaEditPage} exact></Route>
              {/* 현황 */}
              <Route
                path={Urls.statusGraph}
                component={StatusGraphPage}
                exact
              ></Route>
              {/* 조달검사검수 */}
              <Route path={Urls.qaqc} component={QAQCMain} exact></Route>
              {/* 설정 */}
              <Route path={Urls.myInfo} component={MyInfoPage} exact></Route>

              {/* 관리자 페이지 */}
              {adminValue && [
                <Route
                  path={Urls.adminUser}
                  key={Urls.adminUser}
                  component={AdminUserPage}
                  exact
                ></Route>,
                <Route
                  path={Urls.adminGroupPermission}
                  key={Urls.adminGroupPermission}
                  component={AdminGroupPermissionPage}
                  exact
                ></Route>,
                <Route
                  path={Urls.adminGroupPermissionDetail}
                  key={Urls.adminGroupPermissionDetail}
                  component={AdminGroupDetailPage}
                  exact
                ></Route>,
                <Route
                  path={Urls.adminInformation}
                  key={Urls.adminGroupPermissionDetail}
                  component={AdminInformationPage}
                  exact
                ></Route>,
                <Route
                  path={Urls.adminProductRegister}
                  key={Urls.adminProductRegister}
                  component={ProductRegisterPage}
                  exact
                ></Route>,
                <Route
                  path={Urls.adminSalesProtection}
                  key={Urls.adminSalesProtection}
                  component={AdminSalesProtectionMain}
                  exact
                ></Route>,
              ]}
              {/* 404 Not Found */}
              <Route path="/*" component={NotFoundPage}></Route>
            </Switch>
          </Content>
        </>
      )}
    </Switch>
  );
}

export default Routes;
