import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { EmployeeApi } from "../src/system/api/EmployeeApi";

// Initialize the Firebase app in the service worker by passing in
// your app's Firebase config object.
// https://firebase.google.com/docs/web/setup#config-object
const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_API_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

// Retrieve an instance of Firebase Messaging so that it can handle background
// messages.
const messaging = getMessaging(firebaseApp);
export function requestPermission() {
  void Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      })
        .then((token) => {
          EmployeeApi.updateFcmToken(token);
          console.log("토큰 발급 완료: ", token);
        })
        .catch((error) => {
          console.log("토큰 발급 애러", error);
        });
    } else if (permission === "denied") {
      console.log("푸시 권한 차단 에러");
    }
  });
}

export function onMessageListener(callback) {
  return onMessage(getMessaging(firebaseApp), (payload) => {
    console.log("message received: ", payload);
    callback(payload);
  });
}
