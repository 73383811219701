import client from "../ApiService";
import HttpClient from "../http-client";
import { NoticeInfo } from "../types/Board";

const listUri: string = "/notice";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _NoticeApi extends _BasicApi {
  searchNotice(page: number, size: number, searchText?: string) {
    return this.client.get(
      `${listUri}?page=${page}&size=${size}&sort=isMainNotice,desc&sort=createdAt,desc`,
      {
        params: { searchText: searchText },
      }
    );
  }
  updateNotice(data: NoticeInfo) {
    return this.client.put(`${listUri}`, data);
  }
  createNotice(data: NoticeInfo) {
    return this.client.post(`${listUri}`, data);
  }
  deleteNotice(id: number) {
    return this.client.delete(`${listUri}`, { params: { id: id } });
  }
  getNoticeDetail(id: number) {
    return this.client.get(`${listUri}/detail`, {
      params: { id: id },
    });
  }
}

export const NoticeApi = new _NoticeApi(client);
