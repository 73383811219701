import { Autocomplete, Box, Chip, Grid } from "@mui/material";
import { CustomTextField } from "../../styles/theme";

import { TagListInfo } from "../../system/types/List";

interface SelectMultiEmployeeProps {
  list?: TagListInfo[];
  onChange: (data: TagListInfo[]) => void;
  tagList: TagListInfo[];
}

function SelectTag({
  list,
  onChange: _onChange,
  tagList,
}: SelectMultiEmployeeProps) {
  return (
    <>
      <Autocomplete
        sx={{
          "& .MuiInputBase-root": {
            fontFamily: "Noto sans CJK KR",

            "&.MuiInput-root:after": {
              borderBottom: "2px solid #000000",
            },
          },
        }}
        fullWidth
        multiple
        options={tagList}
        value={list}
        defaultValue={list}
        defaultChecked={true}
        disableCloseOnSelect
        onChange={(event: any, newValue: TagListInfo[]) => {
          _onChange(newValue);
        }}
        isOptionEqualToValue={(option: TagListInfo, value: TagListInfo) =>
          option.id === value.id
        }
        getOptionLabel={(option: TagListInfo) => option.name}
        renderOption={(props, option: TagListInfo) => (
          <Box component="li" {...props}>
            <Grid item xs={12}>
              {option.name}
            </Grid>
          </Box>
        )}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            style={{}}
            variant="standard"
            placeholder=" 태그를 검색해주세요."
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              size="small"
              label={option.name}
              {...getTagProps({ index })}
              variant="outlined"
            />
          ))
        }
      />
    </>
  );
}

export default SelectTag;
