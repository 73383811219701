import { L10n } from "@syncfusion/ej2-base";
import { useCallback, useEffect, useState } from "react";
import { CustomButtonGreen } from "../../../../styles/ButtonStyle";
import { Box, Grid, Modal } from "@mui/material";
import { ModalStyle, SearchBoxComponent } from "../../../../styles/theme";
import AdminUserModal from "./AdminUserModal";
import {
  EmployeeInfo,
  EmployeeTableInfo,
} from "../../../../system/types/Employee";
import { InitEmployeeInfo } from "../../../../system/types/initObject";
import { ErrorHandler } from "../../../../system/ApiService";
import AlertModal from "../../../../components/Common/AlertModal";
import { GroupApi } from "../../../../system/api/GroupApi";
import { GroupInfo } from "../../../../system/types/Group";
import { EmployeeApi } from "../../../../system/api/EmployeeApi";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {
  Pc,
  TabletMobile,
} from "../../../../components/Common/Responseive/ResponsiveScreen";
import AdminUserTablePc from "./AdminUserTablePc";
import AdminUserTableMobile from "./AdminUserTableMobile";

L10n.load({
  ko: {
    grid: {
      EmptyRecord: "조회된 데이터가 없습니다.",
      EmptyDataSourceError: "조회된 데이터가 없습니다.",
      OKButton: "확인",
      Sort: "정렬",
      Clear: "초기화",
      SortAscending: "오름차순 정렬",
      SortDescending: "내림차순 정렬",
      None: "없음",
      Ascending: "오름차순",
      Descending: "내림차순",
    },
    pager: {
      currentPageInfo: "{1} 페이지 중 {0}",
      totalItemsInfo: "(총 {0} 명)",
    },
  },
});

function AdminUserTable() {
  const [modalType, setModalType] = useState("");
  const [data, setData] = useState<EmployeeTableInfo[]>([]);
  const [employeeData, setEmployeeData] =
    useState<EmployeeInfo>(InitEmployeeInfo);
  const [preSearchWord, setPreSearchWord] = useState("");
  const [searchWord, setSearchWord] = useState("");

  // 모달
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setEmployeeData(InitEmployeeInfo);
    setOpen(false);
  };

  const [groupData, setGroupData] = useState<GroupInfo[]>([]);
  const moveToAddEmployee = () => {
    setModalType("추가");
    setOpen(true);
  };

  const onChange = (e: any) => {
    setPreSearchWord(e.target.value);
  };

  const moveToUpdateEmployee = (props: EmployeeInfo) => {
    setEmployeeData({
      ...InitEmployeeInfo,
      id: props.id,
      email: props.email,
      name: props.name,
      phone: props.phone,
      picture: props.picture,
      jobTitle: props.jobTitle,
      jobPosition: props.jobPosition,
      group: props.group,
      role: props.role,
      isResignation: props.isResignation,
      enabled: props.enabled,
      isSales: props.isSales,
    });
    setModalType("수정");
    setOpen(true);
  };

  const refreshEmployeeData = useCallback(() => {
    // 데이터 조회
    EmployeeApi.GetEmployee(searchWord)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [searchWord]);

  const ClickSearch = () => {
    setSearchWord(preSearchWord);
  };

  useEffect(() => {
    refreshEmployeeData();
    GroupApi.getAllGroupInfo()
      .then((res) => {
        setGroupData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [refreshEmployeeData]);

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      ClickSearch();
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent="right">
          <SearchBoxComponent
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="검색어를 입력하세요."
              inputProps={{ "aria-label": "검색어를 입력하세요." }}
              onChange={onChange}
              onKeyDown={handleOnKeyDown}
              autoComplete="new-password"
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={ClickSearch}
            >
              <SearchIcon />
            </IconButton>
          </SearchBoxComponent>
          <CustomButtonGreen
            onClick={moveToAddEmployee}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            계정 추가
          </CustomButtonGreen>
        </Grid>

        <Pc>
          <AdminUserTablePc
            data={data}
            moveToUpdateEmployee={moveToUpdateEmployee}
          />
        </Pc>
        <TabletMobile>
          <AdminUserTableMobile
            data={data}
            moveToUpdateEmployee={moveToUpdateEmployee}
          />
        </TabletMobile>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle("51.5rem")}>
            <AdminUserModal
              employeeData={employeeData}
              setEmployeeData={setEmployeeData}
              modalType={modalType}
              handleClose={handleClose}
              refreshEmployeeData={refreshEmployeeData}
              groupData={groupData}
            />
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
}

export default AdminUserTable;
